const ColorDataConfig = [
  { name: 'Blue', value: '#0D69D4' },
  { name: 'Black', value: '#344054' },
  { name: 'Green', value: '#209940' },
  { name: 'Purple', value: '#520FE0' },
  { name: 'Orange', value: '#bc7110' },
  { name: 'Yellow', value: '#FFEB3B' },
  { name: 'Brown', value: '#563f09' }
];

export default ColorDataConfig;
