// @ts-nocheck
export const getLighterShade = (color, shadeFactor = 0.8) => {
  const colorValue = color.substring(1);
  const rgb = parseInt(colorValue, 16);
  let r = Math.min(255, Math.floor(((rgb >> 16) & 0xff) * (1 + shadeFactor)));
  let g = Math.min(255, Math.floor(((rgb >> 8) & 0xff) * (1 + shadeFactor)));
  let b = Math.min(255, Math.floor((rgb & 0xff) * (1 + shadeFactor)));

  const minBrightness = 200;
  r = Math.max(minBrightness, r);
  g = Math.max(minBrightness, g);
  b = Math.max(minBrightness, b);

  return `rgb(${r}, ${g}, ${b}, 0.5)`;
};

export const getDarkerShade = (color, shadeFactor = 0.2) => {
  const colorValue = color.substring(1);
  const rgb = parseInt(colorValue, 16);
  let r = Math.max(0, Math.floor(((rgb >> 16) & 0xff) * shadeFactor));
  let g = Math.max(0, Math.floor(((rgb >> 8) & 0xff) * shadeFactor));
  let b = Math.max(0, Math.floor((rgb & 0xff) * shadeFactor));

  return `rgb(${r}, ${g}, ${b})`;
};

export const getTextColor = (backgroundColor) => {
  const color = backgroundColor.substring(1);
  const rgb = parseInt(color, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance > 128 ? 'var(--base-black)' : 'var(--base-white)';
};

export const getIndicatorShade = (color, shadeFactor = 0.5) => {
  const colorValue = color.substring(1);
  const rgb = parseInt(colorValue, 16);
  let r = Math.min(255, Math.floor(((rgb >> 16) & 0xff) * (1 + shadeFactor)));
  let g = Math.min(255, Math.floor(((rgb >> 8) & 0xff) * (1 + shadeFactor)));
  let b = Math.min(255, Math.floor((rgb & 0xff) * (1 + shadeFactor)));

  return `rgb(${r}, ${g}, ${b})`;
};
