//@ts-nocheck
import { getStatusByCode } from '../../../utils/tripStatusesHelper';
import { XGChip } from '@xg-cl/xg-cl';

export const tripsColumns = [
  { field: 'id', headerClassName: 'tripsList__column-headers' },
  {
    field: 'title',
    sortable: false,
    headerName: 'Title',
    width: 400,
    headerClassName: 'trips-list__data-grid-header',
    cellClassName: 'trips-list__data-grid-header-title',
    flex: 1
  },
  {
    field: 'status',
    sortable: false,
    headerName: 'Status',
    width: 150,
    headerClassName: 'trips-list__data-grid-header',
    flex: 1,
    renderCell: (params) => {
      const statusData = getStatusByCode(params.value);
      return (
        <XGChip
          className={`${statusData?.customClass}`}
          id={`${params.value}-chip`}
          text={statusData?.tripstatusname}
          type={statusData.type}
          variant='outlined'
          size={statusData?.size}
          style={{ borderRadius: '6px', height: '18px' }}
        />
      );
    }
  },
  {
    field: 'destination',
    sortable: false,
    headerName: 'Destination',
    width: 170,
    headerClassName: 'trips-list__data-grid-header',
    flex: 1
  },
  {
    field: 'startDate',
    sortable: false,
    headerName: 'Start date',
    width: 210,
    headerClassName: 'trips-list__data-grid-header',
    flex: 1
  },
  {
    field: 'lastModified',
    sortable: false,
    headerName: 'Last modified',
    width: 210,
    headerClassName: 'trips-list__data-grid-header',
    flex: 1
  }
];
