import request from './../request';

export const signIn = (data = {}) =>
  request.post('sessions', data).then((res) => res);

export const resetPassword = (data = {}) =>
  request.post('password-reset', data).then((res) => res);

export const resendPassword = (data = {}) =>
  request.post('password-reset', data).then((res) => res);

export const updatePassword = (resetToken = '', data = {}) =>
  request.post(`password/${resetToken}`, data).then((res) => res);

export const getAccessToken = (resetToken = '') => {
  return request.get(`password-reset/${resetToken}`).then((res) => res);
};
