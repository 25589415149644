// @ts-nocheck
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InlineEditor from '../../InlineEditor/InlineEditor';
import inputRules from '../../../constants/inputRules';
import { XGChip } from '@xg-cl/xg-cl';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { getStatusByCode } from '../../../utils/tripStatusesHelper';
import { useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';
import {
  updateTripStatus,
  updateTripHeader
} from '../../../services/trip/trip';
import { updateTrip } from '../../../redux/slices/trip/trip';
import { preventBubbling } from '../../../utils/eventHelper';
import { ErrorToast } from '../../../utils/alerts';
import { useTranslation } from 'react-i18next';
import './TripHeader.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom trip header.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip header component.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the trip header.
 */

const TripHeader = ({ id, ...rest }) => {
  const tripStatuses = useSelector((state) => state.tripstatuses);
  const trip = useSelector((state) => state.trip);
  const { tripname, tripstatus, tripid } = trip;
  const statusData = getStatusByCode(tripstatus?.tripstatuscode);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { t } = useTranslation(['trip', 'common']);

  const handleOnClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnClose = async (event) => {
    preventBubbling(event);
    setAnchorEl(null);
  };

  const updateTripTitle = async (newTripName) => {
    try {
      if (newTripName && newTripName.trim() !== tripname) {
        const { data } = await updateTripHeader(tripid, {
          tripName: newTripName.trim()
        });

        if (data) {
          dispatch(updateTrip({ tripname: data.tripname }));
        }
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
    }
  };

  const handleOnClickOption = async (event, status) => {
    try {
      preventBubbling(event);
      const { tripstatusid, tripstatuscode } = status;

      const { data } = await updateTripStatus(tripid, {
        statusId: tripstatusid,
        statusCode: tripstatuscode
      });

      if (data) {
        const newstatus = { tripstatus: status };
        dispatch(updateTrip(newstatus));
      } else {
        ErrorToast(t('errors:tripStatusCouldNotBeUpdated'));
      }
    } catch (err) {
      ErrorToast(t('errors:tripStatusCouldNotBeUpdated'));
    } finally {
      setAnchorEl(null);
    }
  };

  return (
    <Box id={id} data-testid='trip-header' {...rest}>
      <Stack direction='row' alignItems='center' gap='8px'>
        <InlineEditor
          action={updateTripTitle}
          className='h1-semibold'
          id='tripname-inline-editor'
          initialValue={tripname}
          isValueRequired={true}
          maxTextLength={inputRules.maxTitleLength}
          style={{ maxWidth: '750px' }}
        />

        {statusData && (
          <XGChip
            className={`${statusData?.customClass}`}
            id={`${id}-chip`}
            data-testid='trip-header-chip'
            text={tripstatus?.tripstatusname}
            type={statusData.type}
            variant='outlined'
            size={statusData?.size}
            style={{ borderRadius: '6px' }}
          />
        )}

        <div>
          <Button
            id='trip-header-dropdowm-button'
            data-testid='trip-header-dropdowm-button'
            aria-controls={open ? 'trip-header-dropdowm' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOnClickAnchor}
            size='small'
            sx={{ minWidth: '20px' }}
          >
            <CaretDown
              size={17}
              style={{ cursor: 'pointer' }}
              color='#667085'
              fill='#667085'
            />
          </Button>

          <Menu
            data-testid='trip-header-dropdown'
            id='trip-header-dropdown'
            aria-labelledby='trip-header-dropdowm-button'
            anchorEl={anchorEl}
            open={open}
            onClose={handleOnClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{ marginTop: '0.7rem', width: '189px' }}
            style={{ width: '189px' }}
          >
            {tripStatuses &&
              tripStatuses.map((status) => {
                const { tripstatusid, tripstatusname, tripstatuscode } = status;
                return (
                  <MenuItem
                    className={`dropdown__item ${
                      tripstatuscode.toLowerCase() == 'cancelled'
                        ? 'dropdown__item--danger'
                        : ''
                    }`}
                    key={tripstatusid}
                    onClick={(event) => {
                      handleOnClickOption(event, status);
                    }}
                    data-id={tripstatusid}
                    selected={tripstatuscode === tripstatus?.tripstatuscode}
                  >
                    {tripstatusname}
                  </MenuItem>
                );
              })}
          </Menu>
        </div>
      </Stack>
    </Box>
  );
};

TripHeader.propTypes = {
  id: PropTypes.string.isRequired
};

export default TripHeader;
