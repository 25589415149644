import request from '../request';

export const createBooking = (data = {}) =>
  request.post('bookings', data).then((res) => res);

export const getBookingById = (bookingId = '', bookingKey = '') =>
  request
    .get(`bookings/${bookingId}?bookingkey=${bookingKey}`)
    .then((res) => res);

export const createPassenger = (data = {}) =>
  request.post('bookingpassengers', data).then((res) => res);

export const updatedPassenger = async (bookingpassengerid = '', data = {}) => {
  return request
    .put(`bookingpassengers/${bookingpassengerid}`, data)
    .then((res) => res);
};

export const generatePassengersPdf = async (data = {}) => {
  const resp = await request
    .post('bookingpassengers/pdf', data)
    .then((res) => res);
  return resp;
};
