// @ts-nocheck
import { Box, Container } from '@mui/material';
import * as PropTypes from 'prop-types';
import './AuthLayout.css';

/**
 * This is a reusable layout for auth pages such as login, reset, and forgot password.
 *
 * @namespace Layouts
 */
const AuthLayout = ({ id, children }) => {
  return (
    <Container
      id={id}
      className='auth-layout-container'
      data-testid='auth-layout'
      sx={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '440px',
          display: 'flex',
          flexDirection: 'column'
        }}
        className='auth-layout-box'
      >
        {children}
      </Box>
    </Container>
  );
};

/**
 * Prop type definitions for AuthLayout.
 */
AuthLayout.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired
};

export default AuthLayout;
