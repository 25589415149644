// @ts-nocheck

import { Box, Switch } from '@mui/material';
import { XGTextarea } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import {
  createTripAmenity,
  deleteTripAmenity,
  getTripAmenity,
  updateTripAmenity
} from '../../../services/trip/trip';
import * as PropTypes from 'prop-types';
import './Amenities.css';

/**
 * Functional React component for rendering a trip additional content component
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip amenities component
 * @param {...any} props.rest - Additional props to be spread on the element
 *
 * @returns {JSX.Element} React element representing the trip additional content component
 */

const Amenities = ({ tripid }) => {
  const debounceTime = 1000; // 1 second
  const timeoutIdRef = useRef(null);
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const handleSwitchOnChange = async (event, amenityid, tripamenityid) => {
    const { name, checked } = event.target;

    const currentAmenities = [...amenitiesList];

    const amenity = currentAmenities.find((item) => {
      if (!item?.amenityid) {
        return item.tripamenityid === tripamenityid;
      } else {
        return item.amenityid === amenityid;
      }
    });

    let amenityUpdated;

    if (checked) {
      if (name === 'include' && amenity.exclude === false) {
        const result = await createTripAmenity({
          tripid,
          amenityid,
          name: amenity.name,
          description: amenity.description,
          isincluded: true
        });
        if (result) {
          amenityUpdated = {
            ...amenity,
            tripamenityid: result.data.tripamenityid,
            include: true
          };
        }
      } else if (name === 'exclude' && amenity.include === false) {
        const result = await createTripAmenity({
          tripid,
          amenityid,
          name: amenity.name,
          description: amenity.description,
          isincluded: false
        });

        if (result) {
          amenityUpdated = {
            ...amenity,
            tripamenityid: result.data.tripamenityid,
            exclude: true
          };
        }
      } else if (name === 'include') {
        await updateTripAmenity(tripamenityid, { isincluded: true });
        amenityUpdated = { ...amenity, include: true, exclude: false };
      } else {
        await updateTripAmenity(tripamenityid, { isincluded: false });
        amenityUpdated = { ...amenity, include: false, exclude: true };
      }
    } else {
      if (name === 'include' && amenity.exclude === false) {
        amenityUpdated = { ...amenity, include: false };
      } else if (name === 'exclude' && amenity.include === false) {
        amenityUpdated = { ...amenity, exclude: false };
      }
      await deleteTripAmenity(tripamenityid);

      amenityUpdated = {
        ...amenity,
        tripamenityid: null,
        include: false,
        exclude: false,
        description: amenity?.description ?? null,
        tripAmenityDescription: null
      };
    }

    const indexToUpdate = amenitiesList.findIndex((item) => {
      if (!item?.amenityid) {
        return item.tripamenityid === tripamenityid;
      } else {
        return item.amenityid === amenityid;
      }
    });

    if (indexToUpdate !== -1) {
      const updatedAmenitiesList = [
        ...currentAmenities.slice(0, indexToUpdate),
        amenityUpdated,
        ...currentAmenities.slice(indexToUpdate + 1)
      ];

      setAmenitiesList(updatedAmenitiesList);
    }
  };

  const onChangeTripAmenityDescription = (event, tripamenityid) => {
    const { value } = event.target;

    setAmenitiesList((prevAmenitiesList) =>
      prevAmenitiesList.map((amenity) =>
        amenity.tripamenityid === tripamenityid
          ? { ...amenity, tripAmenityDescription: value }
          : amenity
      )
    );

    setShouldUpdate(true);

    // Clear any previous timeout if the user is still typing
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      updateTripAmenity(tripamenityid, { description: value });
      setShouldUpdate(false);

      timeoutIdRef.current = null;
    }, debounceTime);
  };

  const onBlurTripAmenityDescription = (event, tripamenityid) => {
    const { value } = event.target;

    if (shouldUpdate) {
      updateTripAmenity(tripamenityid, { description: value });
      setShouldUpdate(false);
    }
  };

  useEffect(() => {
    const fetchTripAmenities = async () => {
      const tripAmenities = await getTripAmenity(tripid);
      setAmenitiesList(tripAmenities?.data ?? []);
    };

    fetchTripAmenities();
  }, [tripid]);

  return (
    <Box className='amenities-box-container'>
      <table className='amenities-table'>
        <thead>
          <tr>
            <th style={{ width: '250px' }}>{t('amenity')}</th>
            <th style={{ width: '500px' }}>{t('description')}</th>
            <th style={{ width: '105px' }}>{t('included')}</th>
            <th style={{ width: '105px' }}>{t('excluded')}</th>
          </tr>
        </thead>
        <tbody>
          {amenitiesList &&
            amenitiesList.map((amenity, index) => (
              <tr key={index}>
                <td>{amenity.name}</td>
                <td>
                  <div className='amenities-description__row '>
                    <XGTextarea
                      placeholder={`${t('common:enter')} description `}
                      id={`amenity-description-${index}`}
                      type='text'
                      name='description'
                      value={
                        amenity.tripAmenityDescription ||
                        amenity.description ||
                        ''
                      }
                      onChange={(evt) =>
                        onChangeTripAmenityDescription(
                          evt,
                          amenity.tripamenityid
                        )
                      }
                      onBlur={(evt) =>
                        onBlurTripAmenityDescription(evt, amenity.tripamenityid)
                      }
                      inputProps={{
                        maxLength: 200
                      }}
                      minRows={2}
                      maxRows={2}
                      disabled={!amenity.exclude && !amenity.include}
                    />
                    <span className='text-sm-medium amenity-text__count '>
                      {amenity.tripAmenityDescription?.length ||
                        amenity.description?.length ||
                        0}
                      /200
                    </span>
                  </div>
                </td>
                <td>
                  <Switch
                    checked={amenity.include}
                    onChange={(event) =>
                      handleSwitchOnChange(
                        event,
                        amenity.amenityid,
                        amenity.tripamenityid
                      )
                    }
                    color='primary'
                    name='include'
                  />
                </td>
                <td>
                  <Switch
                    checked={amenity.exclude}
                    onChange={(event) =>
                      handleSwitchOnChange(
                        event,
                        amenity.amenityid,
                        amenity.tripamenityid
                      )
                    }
                    color='primary'
                    name='exclude'
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Box>
  );
};

Amenities.propTypes = {
  tripid: PropTypes.number
};

export default Amenities;
