import { useEffect } from 'react';
import PropTypes from 'prop-types';
import confetti from 'canvas-confetti';

const DEFAULT_DURATION = 15 * 1000;
/**
 * Generates a random number within a specified range.
 *
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @returns {number} A random number between `min` (inclusive) and `max` (exclusive).
 */
const randomInRange = (min, max) => {
  return Math.random() * (max - min) + min;
};

/**
 * ConfettiAnimation component that triggers a confetti animation across the entire screen
 * for a specified duration. This animation creates two confetti bursts at random positions
 * on the left and right sides of the screen every 250ms until the duration expires.
 *
 * @param {Object} props - The properties object.
 * @param {number} [props.duration=DEFAULT_DURATION] - The duration of the confetti animation in milliseconds. Defaults to `defaultDuration` if not provided.
 *
 * @returns {null} This component does not render any visible elements.
 *
 * @example
 * <ConfettiAnimation duration={15000} />
 */
const ConfettiAnimation = ({ duration = DEFAULT_DURATION }) => {
  useEffect(() => {
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      // Two confetti bursts, one on the left and one on the right
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        zIndex: 100000
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        zIndex: 100000
      });
    }, 250);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [duration]);

  return null; // This component doesn't render any visible content
};

ConfettiAnimation.propTypes = {
  duration: PropTypes.number
};

export default ConfettiAnimation;
