// @ts-nocheck
import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import globalConfig from './config/globalConfig';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(globalConfig.muiLicense);

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      iconVariant={{
        error: <InfoOutlinedIcon />
      }}
    >
      <RouterProvider router={router} />
    </SnackbarProvider>
  );
}

export default App;
