// @ts-nocheck
import { useState, useRef, useEffect } from 'react';
import { preventBubbling } from '../../utils/eventHelper';
import './InlineEditor.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom inline editor.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.action] - The callback function to be called when the editor is submitted.
 * @param {string} [props.id] - The ID for the inline editor component.
 * @param {string} [props.isValueRequired] - The boolean value indicating whether the value is required.
 * @param {string} [props.initialValue] - The initial value of the editor.
 * @param {string} [props.maxTextLength] - The maximum length of the text content.
 * @param {string} [props.placeholder] - The placeholder text for the editor.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the inline editor.
 */

const InlineEditor = ({
  action,
  id,
  isValueRequired,
  initialValue,
  maxTextLength,
  placeholder,
  ...rest
}) => {
  const [contentError, setContentError] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    editorRef.current?.blur();
  }, [initialValue]);

  useEffect(() => {
    if (editorRef.current && isEditable) {
      editorRef.current.focus();
    }
  }, [isEditable]);

  const setValue = (newValue = '') => {
    if (editorRef && editorRef.current) {
      editorRef.current.textContent = newValue;
    }
  };

  const onCancel = async () => {
    setValue(initialValue);
    editorRef.current.blur();
  };

  const onBlurEditable = async (event) => {
    preventBubbling(event);
    const textContent = editorRef?.current?.textContent;
    let curatedTextContent = textContent.trim();

    if (curatedTextContent.length === 0 && isValueRequired) {
      curatedTextContent = initialValue;
    }

    setValue(curatedTextContent);

    if (textContent.length <= maxTextLength) {
      action(curatedTextContent);
      setIsEditable(false);
    } else {
      setContentError(true);
    }
  };

  const onKeyDownEditable = async (event) => {
    const newTextContent = event?.target?.textContent;
    const { keyCode, code, ctrlKey, altKey, shiftKey } = event;
    const isEnter = keyCode === 13 || code === 'Enter';
    const isBackspace = keyCode === 8 || code === 'Backspace';
    const isEscape = keyCode === 27 || code === 'Escape';

    if (isEscape) {
      onCancel();
      return;
    }

    if (newTextContent.trim().length < maxTextLength) {
      if (isEnter) {
        event.target.blur();
        preventBubbling(event);
        return;
      }
      setContentError(false);
    } else if (newTextContent.trim().length === maxTextLength) {
      if (!isBackspace && !ctrlKey && !altKey && !shiftKey) {
        preventBubbling(event);
        return;
      }
    } else {
      setContentError(true);

      if (!isBackspace && !ctrlKey && !altKey && !shiftKey) {
        preventBubbling(event);
        return;
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');

    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    // Insert the pasted text at the current cursor position
    range.deleteContents();
    const textNode = document.createTextNode(pastedText);
    range.insertNode(textNode);

    // Move the cursor to the end of the pasted text
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const onClickEditable = () => {
    setIsEditable(true);
  };

  return (
    <div
      {...rest}
      data-testid={'inline-editor'}
      onBlur={onBlurEditable}
      onKeyDown={onKeyDownEditable}
      onPaste={handlePaste}
      onClick={onClickEditable}
    >
      {placeholder && !initialValue && !isEditable ? (
        <span className='inline-editor__placeholder'>{placeholder}</span>
      ) : (
        <div
          id={id}
          ref={editorRef}
          contentEditable={isEditable}
          className={`${rest?.className} inline-editor ${
            contentError ? 'inline-editor--invalid' : ''
          }`}
          spellCheck='false'
          suppressContentEditableWarning={true}
          tabIndex='0'
        >
          {initialValue}
        </div>
      )}
    </div>
  );
};

InlineEditor.propTypes = {
  action: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  isValueRequired: PropTypes.bool,
  maxTextLength: PropTypes.number,
  placeholder: PropTypes.string
};

export default InlineEditor;
