//TODO: This is a temporal implementation of custom alerts using notistack
//TODO: This should be refactored in the next sprint
//TODO: Evaluate if we're reinventing the wheel in here
//TODO: Add unit tests if this solution is accepted
//TODO: This is a jsx file, evaluate if it should be placed inside components folder
//TODO: The left-hand icons do not match the design; they should be fixed.

import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { X } from '@phosphor-icons/react';
import './style.css';

// Custom action for alerts (it closes its parent alert)
const action = (snackbarId = undefined, variant = 'error') => {
  if (variant === 'error') {
    return (
      <X
        size={20}
        className='alert-close-icon'
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      />
    );
  }

  return null; // Return null for non-error variants (success, info, etc.)
};

//Alert definition for success messages
export const SuccessToast = (message = '', options = {}) => {
  const snackbarId = enqueueSnackbar(message, {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    persist: false,
    variant: 'success',
    ...options
  });

  // Set timeout to auto-close the notification after 3 seconds
  setTimeout(() => {
    closeSnackbar(snackbarId);
  }, 3000);
};

//Alert definition for error messages
export const ErrorToast = (message = '', options = {}) =>
  enqueueSnackbar(message, {
    action: action(undefined, 'error'),
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    persist: false,
    preventDuplicate: true,
    variant: 'error',
    ...options
  });
