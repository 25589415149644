// @ts-nocheck
import NavMenuList from '../NavMenuList/NavMenuList';
import NavMenuHeader from '../NavMenuHeader/NavMenuHeader';
import NavMenuFooter from '../NavMenuFooter/NavMenuFooter';
import NavMenuToggle from '../NavMenuToggle/NavMenuToggle';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateNavigation } from '../../../redux/slices/navigation/navigation';
import * as PropTypes from 'prop-types';
import './NavMenuBar.css';

/**
 * Functional React component for rendering a custom nav menu bar.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {Object} props.data - Data containing information for the nav menu bar.
 * @param {string} props.id - The unique identifier for the component.
 *
 * @returns {JSX.Element} - The rendered NavMenuBar component.
 */

const NavMenuBar = ({ data, id, navItems }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = `/${location.pathname.split('/')[1] || ''}`;
  const navigation = useSelector((state) => state.navigation);

  const handleToggle = () => {
    dispatch(updateNavigation({ open: !navigation.open }));
  };

  return (
    <Box
      data-testid='nav-menu-bar'
      className={`nav-menu-bar ${navigation.open ? 'expanded' : 'collapsed'}`}
      id={id}
      sx={{ justifyContent: 'center' }}
    >
      {navigation.open && (
        <NavMenuHeader id='nav-menu-bar-header' data={data} />
      )}

      <NavMenuToggle
        id='nav-menu-bar-toggle'
        open={navigation.open}
        onToggle={handleToggle}
      />
      <div className='nav-menu-bar-divider' />

      <Box sx={{ marginTop: '4px' }}>
        {navItems && (
          <NavMenuList
            id='nav-menu-bar-list'
            currentPath={currentPath}
            items={navItems}
            open={navigation.open}
          />
        )}
      </Box>

      <NavMenuFooter
        id='nav-menu-bar-footer'
        open={navigation.open}
        data={data}
      />
    </Box>
  );
};

/**
 * Prop type definitions for NavMenuBar.
 */
NavMenuBar.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  navItems: PropTypes.array
};

export default NavMenuBar;
