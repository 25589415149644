import { MagnifyingGlass } from '@phosphor-icons/react';
import './NoTripsFound.css';

const NoTripsFound = () => {
  return (
    <div className='no-trips-found'>
      <div className='no-trips-found__search-container'>
        <MagnifyingGlass size={32} />
      </div>
      <h2 className='h2-semibold no-trips-found__title'>No results found</h2>
      <p className='text-sm-regular'>Please try another search</p>
    </div>
  );
};

export default NoTripsFound;
