//@ts-nocheck

//Components
import ShellLayout from '../../layouts/ShellLayout/ShellLayout';
import shellHeaderBuilder from '../../utils/shellHeaderBuilder';
import PassengerFieldsPanel from '../../components/GlobalSettings/PassengerFieldsPanel/PassengerFieldsPanel';

//Other dependencies
import { useTranslation } from 'react-i18next';
import { XGTabs } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import './GlobalSettings.css';
import TemplatesPanel from '../../components/GlobalSettings/Templates/TemplatesPanel';

/**
 * This is the global settings page component.
 *
 * @namespace Pages
 */

const GlobalSettings = ({ id }) => {
  const { t } = useTranslation(['settings', 'common']);

  const mainHeader = shellHeaderBuilder({
    title: {
      title: t('globalSettings')
    },
    cta: <></>
  });

  const settingsTabs = [
    {
      title: t('passengerFields'),
      content: <PassengerFieldsPanel id='passenger-fields-panel' />
    },
    {
      title: t('templates'),
      content: <TemplatesPanel id='templates-panel' />
    }
  ];

  return (
    <div id={id} data-testid='global-settings-page'>
      <ShellLayout
        id='global-settings-shell'
        data-testid='global-settings-shell'
        headerData={mainHeader}
        isLoadingContent={false}
      >
        <div className='global-settings__wrapper'>
          <XGTabs
            id='global-settings-tabs'
            tabs={settingsTabs}
            className='global-settings-tab-panel'
          />
        </div>
      </ShellLayout>
    </div>
  );
};

GlobalSettings.propTypes = {
  id: PropTypes.string.isRequired
};

export default GlobalSettings;
