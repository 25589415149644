// @ts-nocheck
import actionTypes from '../../../constants/actionTypes';
import { DotsSixVertical, Trash } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import './AdditionalContentNavItem.css';

/**
 * Functional React component for rendering a custom trip content item.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {boolean} [props.active] - A boolean indicating whether the item is active.
 * @param {string} [props.id] - The ID for the trip content item component.
 * @param {Object} [props.item] - Object containing the data to build the trip content item.
 * @param {string} [props.onAction] - Callback function to handle actions.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the trip content item component.
 */

const AdditionalContentNavItem = ({ active, id, item, onAction, ...rest }) => {
  const { t } = useTranslation(['common']);

  return (
    <li
      data-testid='additional-content__item'
      id={id}
      className={'additional-content__item ' + (active ? 'active' : '')}
      onClick={(evt) => onAction(evt, actionTypes.SELECT, item.tripcontentid)}
      {...rest}
    >
      <div className='additional-content__item__draggable'>
        <DotsSixVertical color='#344054' size={24} />
      </div>
      <span className='text-md-semibold text-ellipsis'>
        {`${item.title ?? t('noTitle')}`}
      </span>
      <div
        className='additional-content__item__delete'
        onClick={(evt) => onAction(evt, actionTypes.DELETE, item.tripcontentid)}
      >
        <Trash size={20} color='#667085' />
      </div>
    </li>
  );
};

AdditionalContentNavItem.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string.isRequired,
  item: PropTypes.object,
  onAction: PropTypes.func
};

export default AdditionalContentNavItem;
