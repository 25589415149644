//@ts-ignore

export const validateNumber = (value, type = 'integer') => {
  if (type === 'integer') {
    if (isNaN(parseInt(value)) || parseInt(value) < 0) {
      return false;
    }
  }

  if (type === 'float') {
    if (isNaN(parseFloat(value)) || parseFloat(value) < 0) {
      return false;
    }
  }
  return true;
};
