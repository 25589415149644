// @ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import { Box, InputLabel, Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Eyedropper } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import { setHighlightColor } from '../../../../redux/slices/color/color';
import { getTextColor } from '../../../../utils/colors/colors'; // Update the import path
import ColorDataConfig from '../ColorDataConfig/ColorDataConfig';
import PropTypes from 'prop-types';
import './ColorConfig.css';

/**
 * Functional React component for rendering color selection configuration.
 *
 * @namespace Components
 *
 * @param {object} props - The properties of the component.
 * @param {string} props.colorValue - The initial color value.
 * @param {function} props.event - The event handler for color changes.
 *
 * @returns {JSX.Element} - The rendered component.
 */
const ColorConfig = ({ event }) => {
  const dispatch = useDispatch();
  const highlightColor = useSelector((state) => state.color.highlightColor);

  const [customColor, setCustomColor] = useState(highlightColor);

  const handlePrimaryColorChange = useCallback(
    (colorValue) => {
      dispatch(setHighlightColor(colorValue));
      setCustomColor(colorValue);
      if (event) event(colorValue);
    },
    [dispatch, event]
  );

  const handleCustomColorChange = (event) => {
    const color = event.target.value;
    setCustomColor(color);
    handlePrimaryColorChange(color);
  };

  const { t } = useTranslation(['settings']);

  useEffect(() => {
    if (!highlightColor) {
      const defaultColor = ColorDataConfig[0]?.value;
      handlePrimaryColorChange(defaultColor);
    }
  }, [highlightColor, handlePrimaryColorChange]);

  useEffect(() => {
    setCustomColor(highlightColor);
  }, [highlightColor]);

  return (
    <Box className='color-config-container'>
      <InputLabel className='text-lg-semibold color-config-label'>
        {t('highlightcolor')}
      </InputLabel>
      <Box className='color-config-box'>
        <Box className='color-config-palette'>
          {ColorDataConfig.map((color) => (
            <Box
              key={color.value}
              className={`color-config-color-box ${highlightColor === color.value ? 'color-config-selected' : 'color-config-unselected'}`}
              style={{
                backgroundColor: color.value,
                color: getTextColor(color.value)
              }}
              onClick={() => handlePrimaryColorChange(color.value)}
            />
          ))}
        </Box>
        <Box className='color-config-custom'>
          <Input
            type='color'
            value={customColor}
            onChange={handleCustomColorChange}
            className='color-config-input'
          />
          <Eyedropper
            size={24}
            color={getTextColor(customColor)}
            className='color-config-eyedropper'
          />
        </Box>
      </Box>
    </Box>
  );
};

ColorConfig.propTypes = {
  colorValue: PropTypes.string,
  event: PropTypes.func
};

export default ColorConfig;
