// @ts-nocheck
import request from '../request';

export const getGenAIDescription = (data = {}) => {
  return request.post(`genai/description`, data).then((res) => res);
};

export const getTripDescription = (tripId = '') => {
  return request.get(`trips/${tripId}/aidescription`).then((res) => res);
};
