// @ts-nocheck
import { Box } from '@mui/material';
import * as PropTypes from 'prop-types';
import './Header.css';

/**
 * Functional React component for rendering a custom main header.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the main header component.
 * @param {Object} [props.data] - Object containing the data to build the main header content.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} React element representing the main header.
 */

const Header = ({ data, id, ...rest }) => {
  const { header } = data;

  return (
    <Box id={id} className='main-header' data-testid='main-header' {...rest}>
      {header && <div>{header.title && header.title}</div>}
      {header.cta && <div>{header.cta && header.cta}</div>}
    </Box>
  );
};

Header.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string.isRequired
};

export default Header;
