//@ts-nocheck
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import './AuthHeader.css';

/**
 * Functional React component for rendering a custom auth header section.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.className] - The custom CSS class name for the auth header.
 * @param {JSX.Element} [props.icon] - The icon element to display in the auth header.
 * @param {string} [props.id] - The ID for the login header element.
 * @param {JSX.Element} [props.logo] - The logo element to display in the auth header.
 * @param {string} [props.tenantName] - The tenant name to display on the auth header when icon is empty.
 * @param {string} [props.text] - The text to display on the auth header.
 * @param {string} [props.title] - The title to display on the auth header.
 * @param {Object} [props.rest] - Additional props to be spread onto the auth header element.
 *
 * @returns {JSX.Element} React element representing the auth header.
 */

const AuthHeader = ({
  children,
  className,
  icon,
  id,
  logo,
  tenantName,
  text,
  title,
  ...rest
}) => {
  return (
    <>
      <Box
        className={className}
        data-testid='auth-header'
        id={id}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        {...rest}
      >
        {tenantName && <h2 className='tenant-name'>{tenantName}</h2>}
        {logo && logo}
        {icon && icon}
        {title && <h3 className='auth-title'>{title}</h3>}
        {text && (
          <p className='typography-body1 auth-typography-body1'>{text}</p>
        )}
        {children}
      </Box>
    </>
  );
};

/**
 * Prop type definitions for AuthHeader.
 */
AuthHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  id: PropTypes.string.isRequired,
  logo: PropTypes.node,
  tenantName: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string
};

export default AuthHeader;
