// @ts-nocheck
import DraggableList from '../../../Draggable/DraggableList/DraggableList';
import LibraryItem from '../LibraryItem/LibraryItem';
import NotAllowedToDrop from '../NotAllowedToDrop/NotAllowedToDrop';
import LibraryPreview from '../LibraryPreview/LibraryPreview';
import { CONTENT_LIBRARY_DROPPABLE_ID } from '../../../../constants/dragAndDrop';
import {
  addLibraryItems,
  addShouldLibraryReload
} from '../../../../redux/slices/library/library';
import { getContent } from '../../../../services/library/content';
import { Droppable } from 'react-beautiful-dnd';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Slide, Box } from '@mui/material';
import { XGInputSearch } from '@xg-cl/xg-cl';
import { X } from '@phosphor-icons/react';
import { t } from 'i18next';
import * as PropTypes from 'prop-types';
import './LibrarySlide.css';

const getDraggableItems = (
  prefix = 'draggable',
  list = [],
  Component,
  onAction
) => {
  const mappedItems = list?.map((item) => {
    const custom = {
      id: `${prefix}.${item?.entityId}`,
      entityId: item?.entityId,
      content: (
        <>
          <Component
            key={`${item?.contentid}`}
            id={`${item?.contentid}`}
            item={{ ...item }}
            onClick={() => {
              onAction(item);
            }}
          />
        </>
      )
    };

    return custom;
  });

  return mappedItems;
};

/**
 * Functional React component for rendering a custom trip detailed day content.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {boolean} [props.openSlide] - Open slide state.
 * @param {function} [props.handleSlideClose] - Function to handle open slide state.
 * @param {number} [props.openedTab] - Selected tab.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed day.
 */

const LibrarySlide = ({ openSlide, handleSlideClose, openedTab }) => {
  const [timestamp, setTimestamp] = useState(0);
  const [libraryContent, setLibraryContent] = useState([]);
  const [contentItems, setContentItems] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [inputText, setInputText] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const contentTypes = useSelector((state) => state.contenttypes);
  const type = openedTab === 0 ? 'day' : 'additional';
  const isDragging = useSelector((state) => state.library?.isDragging ?? false);
  const shouldLibraryReload = useSelector(
    (state) => state.library?.shouldLibraryReload ?? false
  );

  const isLibraryDraggableDisabled = useSelector(
    (state) => state.library?.itinerary?.isLibraryDraggableDisabled ?? false
  );

  const contentType = contentTypes?.find(
    (contentType) => contentType?.code === type
  );
  const typeId = contentType?.contenttypeid ?? 0;

  const mappedItems = contentItems?.map((item) => {
    return {
      ...item,
      code: type,
      entityId: item?.contentid ?? 0
    };
  });

  const handlePreviewOpen = (item) => {
    setIsPreviewOpen(true);
    setSelectedItem(item);
  };

  const draggableLibElements = getDraggableItems(
    `library${type}`,
    mappedItems ?? [],
    LibraryItem ?? <></>,
    handlePreviewOpen
  );

  const handleChangeInput = (event) => {
    const { value } = event.target;
    setInputText(value);
  };

  const handleSearch = useCallback(
    (value) => {
      if (value.trim() !== '') {
        const contentFiltered = libraryContent?.filter((content) => {
          return content?.title?.toLowerCase().includes(value.toLowerCase());
        });
        setContentItems(contentFiltered);
      } else {
        setContentItems(libraryContent);
      }
    },
    [libraryContent]
  );

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const libraryContent = await getContent(typeId, timestamp);
        setLibraryContent(libraryContent.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [typeId, timestamp]);

  useEffect(() => {
    if (libraryContent) {
      dispatch(addLibraryItems({ items: libraryContent }));
      setContentItems(libraryContent);
      setInputText('');
    }
  }, [libraryContent, dispatch]);

  useEffect(() => {
    handleSearch(inputText);
  }, [inputText, handleSearch]);

  useEffect(() => {
    if (shouldLibraryReload) {
      setTimestamp((timestamp) => timestamp + 1);
      dispatch(addShouldLibraryReload({ shouldLibraryReload: false }));
    }
  }, [shouldLibraryReload, dispatch]);

  //On react 18 we need to load Droppable element after the component is mounted otherwise it won't work
  //See: https://github.com/atlassian/react-beautiful-dnd/issues/2399
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <Slide
        className='library_slide_itinerary'
        direction='left'
        in={openSlide}
      >
        <Box id='content-slide__container'>
          <div className='library_slide_header_wrapper'>
            <div className='library_slide_header'>
              <h2 className='h2-semibold'>{t('trip:library')}</h2>
              <X
                size={35}
                onClick={() => {
                  setContentItems(libraryContent);
                  setInputText('');
                  handleSlideClose();
                }}
                className='library_slide_close_button'
              />
            </div>
            <div className='library_slide_input_search_container'>
              <XGInputSearch
                name='content-title'
                placeholder={t('trip:search')}
                id='content-title-input'
                type='text'
                value={inputText}
                onChange={handleChangeInput}
                onClick={() => handleSearch(inputText)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch(event.target?.value);
                  }
                }}
              />
            </div>
          </div>

          <div
            className='library_slide_body'
            style={{
              overflow: `${isLibraryDraggableDisabled ? 'hidden' : 'scroll'}`
            }}
          >
            <div className='library_slide_content_list'>
              {contentItems && contentItems.length > 0 && isMounted ? (
                <Droppable
                  droppableId={CONTENT_LIBRARY_DROPPABLE_ID}
                  isDropDisabled={true}
                  //isDropDisabled={isLibraryDraggableDisabled}
                >
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`droppable-container ${snapshot.isDraggingOver || (isDragging && !isLibraryDraggableDisabled) ? '_droppable-container--dragging-over' : ''}`}
                    >
                      {isLibraryDraggableDisabled && isDragging && (
                        <NotAllowedToDrop id={'library-not-allowed-to-drop'} />
                      )}
                      <DraggableList items={draggableLibElements} />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ) : inputText.trim() !== '' ? (
                <div className='no-content-found'>
                  <span className='text-muted--secondary'>
                    {t('library:noResultsFor')} {`"${inputText}"`}
                  </span>
                </div>
              ) : (
                <div className='no-content-found'>
                  <span className='text-muted--secondary'>
                    {t('library:noResultsFound')} {`"${inputText}"`}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Slide>
      <LibraryPreview
        item={selectedItem}
        isOpenModal={isPreviewOpen}
        onCancel={handlePreviewClose}
      />
    </>
  );
};

LibrarySlide.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  handleSlideClose: PropTypes.func,
  openedTab: PropTypes.number.isRequired
};

export default LibrarySlide;
