// @ts-nocheck
import { Box, Grid } from '@mui/material';
import { XGInput, XGRadio } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

//TODO: This component has hardcoded values, it should be dynamic.

/**
 * Functional React component for rendering a custom gender options component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.gender] - The selected gender.
 * @param {Function} [props.handleChange] - The function to handle the change event.
 * @param {string} [props.inputError] - The error for the input field.
 *
 * @returns {JSX.Element} React element representing the gender options component.
 */
const PassengersGenderOptions = ({ gender, handleChange, inputError = '' }) => {
  const { t } = useTranslation(['passengers', 'common']);

  const handleRadioChange = (event) => {
    const { value } = event.target;
    handleChange(value);
  };

  const handleChangeInput = (event) => {
    const { value } = event.target;

    handleChange(value);
  };

  return (
    <Grid item xs={12} padding='0 16px'>
      <Box
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column'
        }}
      >
        <XGRadio
          id='passenger-gender-option-female'
          name='gender'
          label={t('female')}
          size='small'
          value='Female'
          checked={gender === 'Female'}
          onChange={handleRadioChange}
        />
        <XGRadio
          id='passenger-gender-option-male'
          name='gender'
          label={t('male')}
          size='small'
          value='Male'
          checked={gender === 'Male'}
          onChange={handleRadioChange}
        />
        <XGRadio
          id='passenger-gender-option-selfdescribe'
          name='gender'
          label={t('selfDescribe')}
          size='small'
          value=''
          checked={gender !== 'Female' && gender !== 'Male'}
          onChange={handleRadioChange}
        />
        {gender !== 'Female' && gender !== 'Male' && gender !== null && (
          <>
            <XGInput
              id='passenger-gender-options-selfdescribe-input'
              placeholder={t('selfDescribe')}
              name='gender'
              type='text'
              value={gender}
              onInput={handleChangeInput}
              onBlur={(event) => {
                const { value } = event.target;
                handleChange(value);
              }}
              style={{ borderRadius: '8px' }}
              error={inputError && !gender ? true : false}
              hint={inputError && !gender ? inputError : ''}
            />
          </>
        )}
      </Box>
    </Grid>
  );
};

PassengersGenderOptions.propTypes = {
  gender: PropTypes.string,
  handleChange: PropTypes.func,
  inputError: PropTypes.string
};

export default PassengersGenderOptions;
