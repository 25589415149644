//@ts-nocheck

import { useEffect } from 'react';

const ImportScript = async (resourceUrl) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = true;
    script.defer = true;
    script.type = 'module';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);
};

export default ImportScript;
