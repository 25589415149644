// @ts-nocheck
import PropTypes from 'prop-types';
import { Box, FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Outputs.css';

/**
 * Functional React component for rendering a dropdown to select the output.
 *
 * @namespace Components
 *
 * @param {object} props - The properties of the component.
 * @param {string} props.selectedOutput - The currently selected output.
 * @param {function} props.setSelectedOutput - The callback function to set the selected output.
 *
 * @returns {JSX.Element} - The rendered component.
 */

const Outputs = ({ selectedOutput, setSelectedOutput }) => {
  const handleChange = (event) => {
    setSelectedOutput(event.target.value);
  };

  const { t } = useTranslation(['settings']);

  return (
    <Box>
      <InputLabel className='text-lg-semibold' mb='16px'>
        {t('templates')}
      </InputLabel>
      <FormControl variant='outlined' className='template-outputs-dropdown'>
        <Select
          value={selectedOutput}
          onChange={handleChange}
          defaultValue='Crios'
          className='xg-select-input-component'
        >
          <MenuItem value='Crios'>Crios</MenuItem>
          <MenuItem value='Nereus'>Nereus</MenuItem>
          <MenuItem value='Alekos'>Alekos</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

Outputs.propTypes = {
  selectedOutput: PropTypes.string.isRequired,
  setSelectedOutput: PropTypes.func.isRequired
};

export default Outputs;
