import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  domain: '',
  logo: '',
  name: '',
  tenantid: 0,
  basecurrency: null,
  sellingcurrency: null
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    updateTenant: (state, action) => {
      const { basecurrency, sellingcurrency, domain, logo, name, tenantid } =
        action.payload;
      state.domain = domain;
      state.logo = logo;
      state.name = name;
      state.tenantid = tenantid;
      state.basecurrency = basecurrency;
      state.sellingcurrency = sellingcurrency;
    },
    clearTenant: (state) => {
      state.domain = '';
      state.logo = '';
      state.name = '';
      state.tenantid = 0;
      state.basecurrency = null;
      state.sellingcurrency = null;
    }
  }
});

const { actions, reducer } = tenantSlice;

export const { updateTenant, clearTenant } = actions;

export default reducer;
