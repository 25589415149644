// @ts-nocheck
import Box from '@mui/material/Box';
import { SmileySad } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import './NotFoundPage.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom not found page.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 *
 * @returns {JSX.Element} - The rendered not found page component.
 */

const NotFoundPage = ({ id }) => {
  const { t } = useTranslation(['common']);
  return (
    <Box
      id={id}
      className='not-found-page__container'
      data-testid='not-found-page-component'
    >
      <div className='not-found-page__container__content'>
        <div className='not-found-page__header-icon'>
          <SmileySad size={30} />
        </div>
        <h1 className='lx2-semibold'>404</h1>
        <span className='text-lg-semibold not-found-page__title'>
          {t('pageNotFound')}
        </span>
        <p className='text-md-regular not-found-page__description'>
          {t('PageDoesNotExist')}
        </p>
      </div>
    </Box>
  );
};

/**
 * Prop type definitions for NotFoundOutput.
 */
NotFoundPage.propTypes = {
  id: PropTypes.string.isRequired
};

export default NotFoundPage;
