import { configureStore } from '@reduxjs/toolkit';

import destinationsReducer from './redux/slices/destinations/destinations';
import navigationReducer from './redux/slices/navigation/navigation';
import servicesReducer from './redux/slices/services/services';
import tenantReducer from './redux/slices/tenant/tenant';
import tripReducer from './redux/slices/trip/trip';
import tripStatusesReducer from './redux/slices/tripstatuses/tripstatuses';
import tripPlanReducer from './redux/slices/trippricing/trippricing';
import userReducer from './redux/slices/user/user';
import bookingReducer from './redux/slices/booking/booking';
import colorReducer from './redux/slices/color/color';
import fontReducer from './redux/slices/font/font';
import passengersReducer from './redux/slices/passengers/passengers';
import contentTypesReducer from './redux/slices/contenttypes/contenttypes';
import libraryReducer from './redux/slices/library/library';

export const store = configureStore({
  reducer: {
    contenttypes: contentTypesReducer,
    booking: bookingReducer,
    destinations: destinationsReducer,
    navigation: navigationReducer,
    passengers: passengersReducer,
    services: servicesReducer,
    tenant: tenantReducer,
    trip: tripReducer,
    tripstatuses: tripStatusesReducer,
    trippricing: tripPlanReducer,
    user: userReducer,
    color: colorReducer,
    font: fontReducer,
    library: libraryReducer
  }
});
