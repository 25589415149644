// @ts-nocheck
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom step connector.
 *
 * @namespace Components
 *
 * @returns {JSX.Element} React element representing the custom step connector component.
 */

const CustomStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1361D8'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1361D8'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#EAECF0',
    borderTopWidth: 2,
    borderRadius: 1
  }
}));

CustomStepConnector.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

export default CustomStepConnector;
