// @ts-nocheck
import * as PropTypes from 'prop-types';
import './DayHeaderServiceType.css';

/**
 * Functional React component for rendering a custom day header service type.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the day header service type.
 * @param {Object} [props.data] - Object containing the data to build day header service type element.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the day header service type.
 */

const DayHeaderServiceType = ({ id, data, ...rest }) => {
  const { icon, title, count: bagdeNumber } = data;

  return (
    <div
      id={id}
      className='day-header-service-type'
      data-testid='day-header-service-type'
      {...rest}
    >
      {icon && icon}
      <span className='day-header-service-type-title'>{title}</span>
      {bagdeNumber && (
        <span className='day-header-service-type-badge'>{bagdeNumber}</span>
      )}
    </div>
  );
};

DayHeaderServiceType.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object
};

export default DayHeaderServiceType;
