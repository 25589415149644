// @ts-nocheck
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TemplateErrorMessage from '../TemplateErrorMessage/TemplateErrorMessage';
import PropTypes from 'prop-types';
import tripDataJson from './tripData.json';
import { getTenant } from '../../../../services/tenant/tenant';
import { useState, useEffect, lazy, Suspense } from 'react';
import './OutputDataConfig.css';

// Dynamic import of components
const Crios = lazy(() =>
  import(
    '../../../Itineraries/templates/Trips/DefaultOutput/DefaultTripOutput/DefaultTripOutput'
  ).catch(() => <TemplateErrorMessage error={'couldNotLoadTemplate'} />)
);

const Alekos = lazy(() =>
  import(
    '../../../Itineraries/templates/Trips/SecondOutput/SecondTripOutput/SecondTripOutput'
  ).catch(() => <TemplateErrorMessage error={'couldNotLoadTemplate'} />)
);

const Nereus = lazy(() =>
  import(
    '../../../Itineraries/templates/Trips/ThirdOutput/ThirdTripOutput/ThirdTripOutput'
  ).catch(() => <TemplateErrorMessage error={'couldNotLoadTemplate'} />)
);

/**
 * Functional React component for rendering a trip output configuration.
 *
 * @namespace Components
 *
 * @param {object} props - The properties of the component.
 * @param {string} props.id - The ID for the OutputDataConfig element.
 * @param {string} props.selectedOutput - The currently selected output component.
 * @param {object} props.rest - Additional props to be spread onto parent element.
 *
 * @returns {JSX.Element} React element representing the OutputDataConfig component.
 */
const OutputDataConfig = ({ id, selectedOutput = 'Crios', ...rest }) => {
  const [tripData, setTripData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const componentMap = {
    Crios,
    Alekos,
    Nereus
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await getTenant();

        if (data && Object.keys(data).length > 0) {
          const { tenantlogo } = data || {};

          const updatedTripData = {
            ...tripDataJson,
            tenant: {
              ...tripDataJson.tenant,
              tenantlogo
            }
          };

          setTripData(updatedTripData);
        } else {
          setError('errorLoadingTripData');
        }
      } catch (err) {
        setError('errorLoadingTripData');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!tripData) return;
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [selectedOutput, tripData]);

  const SelectedComponent = componentMap[selectedOutput] ?? null;

  return (
    <Box
      data-testid='template-trip-output'
      id={id}
      {...rest}
      className='template-output__container'
    >
      {loading ? (
        <Box className='template-loading-container'>
          <CircularProgress />
        </Box>
      ) : error ? (
        <TemplateErrorMessage error={error} />
      ) : (
        SelectedComponent &&
        tripData && (
          <Suspense fallback={<CircularProgress />}>
            <div className='template-output-component-container'>
              <SelectedComponent
                id={`${selectedOutput.toLowerCase()}-template`}
                data={tripData}
              />
            </div>
          </Suspense>
        )
      )}
    </Box>
  );
};

OutputDataConfig.propTypes = {
  id: PropTypes.string.isRequired,
  selectedOutput: PropTypes.string
};

export default OutputDataConfig;
