// @ts-nocheck
import { Link, Box } from '@mui/material';
import { XGInput, XGButton, XGCheckBox } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import { ErrorToast } from '../../../utils/alerts';
import { isEmailValid } from '../../../utils/emailValidator';
import { preventBubbling } from '../../../utils/eventHelper';
import { updatePaymentIntent } from '../../../services/stripe/stripe';
import { createTransaction } from '../../../services/transaction/transaction';
import {
  paymentElementOptions,
  successfulStatuses
} from '../../../constants/stripe';
import { useNavigate } from 'react-router';
import {
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import { format, add } from 'date-fns';
import { parseISODateWithoutOffset } from '../../../utils/dateUtility';
import { CalendarBlank } from '@phosphor-icons/react';
import { getFeesBreakdown } from '../../../utils/feesHelper';
import * as PropTypes from 'prop-types';
import TenantLogo from '../../TenantLogo/TenantLogo';

/**
 * Functional React component for rendering a custom payment form.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the payment form.
 * @param {string} [props.data] - The data for the payment form.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the custom payment form component.
 */
const PaymentForm = ({
  id,
  data,
  paymentIntentId,
  setPaymentMethod,
  paymentMethodOrder,
  ...rest
}) => {
  const submitRef = useRef(null);
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const { bookingid, tenant, tripname, startdate, duration, baseTotal, fees } =
    data;
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [customForm, setCustomForm] = useState({
    email: '',
    firstName: '',
    lastName: ''
  });

  const [customFormError, setCustomFormError] = useState({
    email: '',
    firstName: '',
    lastName: ''
  });

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [previousPaymentMethod, setPreviousPaymentMethod] = useState(null);
  //Trip Data
  const tripName = tripname || null;
  const tripDate = startdate || null;
  const tripDuration = duration || null;
  const startDate = parseISODateWithoutOffset(tripDate);
  const endDate =
    startDate && add(new Date(startDate), { days: tripDuration - 1 });
  const dateFormat = t('common:dateFormat');

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const { type } = previousPaymentMethod;

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsProcessing(true);

      const { error, paymentIntent = {} } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.href}#`,
          payment_method_data: {
            billing_details: {
              address: {
                country: 'US' //Default Country(if we disable country on the form we should set a default value for Stripe to proccess the payment)
              },
              email: customForm?.email.trim(),
              name: `${customForm?.firstName.trim()} ${customForm?.firstName.trim()}`
            }
          }
        },
        redirect: 'if_required' //This instruction avoid Stripe to redirect when getting the transaction result
      });

      const { status } = paymentIntent;

      if (paymentIntent && successfulStatuses.includes(status)) {
        const transactionPayload = {
          bookingid,
          amount: baseTotal,
          stripetransactionid: paymentIntent?.id,
          paymentMethod: type,
          status
        };

        //Create transaction
        const { data } = await createTransaction(transactionPayload);

        if (data) {
          const { transactionid } = data;

          //Update payment intent
          await updatePaymentIntent(paymentIntent?.id, {
            metadata: {
              'Payment type': t('finalPayment'),
              'Booking number': bookingid,
              'Transaction ID': transactionid
            }
          });

          navigate(`/bookings/${bookingid}/payment/success?status=${status}`);
        }
      } else {
        if (error) {
          if (error?.type !== 'validation_error') {
            ErrorToast(t('errors:bookingPaymentFailed'));
          }
        }
      }

      setIsProcessing(false);
    } catch (error) {
      ErrorToast(t('errors:bookingUnexpectedError'));
      setIsProcessing(false);
    }
  };

  const onChangeCustomInput = (event) => {
    const { name, value } = event.target;

    setCustomForm({ ...customForm, [name]: value });
    setCustomFormError({ ...customFormError, [name]: '' });
  };

  const validateCustomForm = () => {
    setCustomFormError({
      email: '',
      firstName: '',
      lastName: ''
    });

    let errors = { ...customFormError };

    if (customForm.email.trim() === '') {
      errors = { ...errors, email: t('trip:yourEmailIsRequired') };
    } else {
      if (!isEmailValid(customForm.email)) {
        errors = { ...errors, email: t('trip:pleaseEnterValidEmail') };
      }
    }

    if (customForm.firstName.trim() === '') {
      errors = { ...errors, firstName: t('trip:yourFirstNameIsRequired') };
    }

    if (customForm.lastName.trim() === '') {
      errors = { ...errors, lastName: t('trip:yourLastNameIsRequired') };
    }

    const isValidForm =
      errors.email === '' && errors.firstName === '' && errors.lastName === '';
    if (!isValidForm) {
      setCustomFormError(errors);
    }

    return isValidForm;
  };

  const onCompletePayment = (evt) => {
    preventBubbling(evt);
    const isValid = validateCustomForm();
    setTermsError('');

    if (isValid) {
      if ((tenant?.tcrequired && isTermsChecked) || !tenant?.tcrequired) {
        if (submitRef.current) {
          submitRef.current.click();
        }
      } else {
        setTermsError(t('trip:mustAcceptTermsAndConditions'));
      }
    }
  };

  const openInNewTab = (evt, url) => {
    evt.preventDefault();
    window.open(url, '_blank');
  };

  const handlePaymentMethodChange = (event) => {
    if (event?.elementType === 'payment') {
      const currentPaymentMethod = event?.value;
      const { type } = currentPaymentMethod;

      const {
        totalWithFees,
        applicationFeeNotIncluded,
        applicationFeeIncluded
      } = getFeesBreakdown(
        baseTotal ?? 0,
        fees ?? [],
        event?.value?.type ?? ''
      );

      updatePaymentIntent(paymentIntentId, {
        amount: totalWithFees,
        applicationFees: applicationFeeNotIncluded + applicationFeeIncluded
      });

      if (type && type !== previousPaymentMethod?.type) {
        setPaymentMethod(type);
        setPreviousPaymentMethod(currentPaymentMethod);
      }
    }
  };

  return (
    <div id={id} {...rest} data-testid='payment-form'>
      <form
        id='payment-form'
        className='payment-form__container'
        onSubmit={handleSubmit}
      >
        <div
          style={{
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'
          }}
        >
          <div>
            <TenantLogo
              tenantLogo={tenant?.tenantlogo}
              tenantName={tenant?.tenantname}
            />
          </div>
          <span className='h1-semibold'>{tripName}</span>
          {startDate && (
            <Box
              className='booking-modal-output-header__label'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <CalendarBlank size={20} />
              <p
                className='text-sm-semibold booking-modal-output-header__label-text'
                style={{ marginLeft: '8px' }}
              >
                {`${format(new Date(startDate), dateFormat)} - ${
                  endDate && format(new Date(endDate), dateFormat)
                }`}
              </p>
            </Box>
          )}
        </div>

        <XGInput
          autoFocus
          error={customFormError.email !== ''}
          hint={customFormError.email}
          label={`${t('common:formLabels.email')}*`}
          name='email'
          placeholder={t('common:formLabels.emailPlaceholder')}
          id='add-day-title-input'
          type='text'
          value={customForm?.email || ''}
          onChange={(evt) => {
            onChangeCustomInput(evt);
          }}
          inputProps={{
            maxLength: 250
          }}
        />
        <div style={{ display: 'flex', gap: '12px', margin: '20px 0' }}>
          <XGInput
            error={customFormError.firstName !== ''}
            hint={customFormError.firstName}
            label={`${t('common:formLabels.firstName')}*`}
            name='firstName'
            placeholder={t('common:formLabels.firstNamePlaceholder')}
            id='add-day-title-input'
            type='text'
            value={customForm?.firstName || ''}
            onChange={(evt) => {
              onChangeCustomInput(evt);
            }}
            inputProps={{
              maxLength: 250
            }}
            style={{ with: '50%' }}
          />
          <XGInput
            error={customFormError.lastName !== ''}
            hint={customFormError.lastName}
            label={`${t('common:formLabels.lastName')}*`}
            name='lastName'
            placeholder={t('common:formLabels.lastNamePlaceholder')}
            id='add-day-title-input'
            type='text'
            value={customForm?.lastName || ''}
            onChange={(evt) => {
              onChangeCustomInput(evt);
            }}
            inputProps={{
              maxLength: 250
            }}
            style={{ with: '50%' }}
          />
        </div>
        <PaymentElement
          options={{ ...paymentElementOptions, paymentMethodOrder }}
          onChange={handlePaymentMethodChange}
        />

        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '24px',
            alignItems: 'center'
          }}
        >
          <div className='terms-and-conditions-payment__container'>
            <XGCheckBox
              id='check-terms-and-conditions'
              name='terms'
              size='medium'
              checked={isTermsChecked}
              onChange={() => {
                setIsTermsChecked(!isTermsChecked);
              }}
            />
            <span className='terms-and-conditions__link text-md-medium'>
              {t('trip:IHaveReadThe')}{' '}
              <Link
                id='button-49'
                href={tenant?.tclink || ''}
                onClick={(evt) => {
                  openInNewTab(evt, tenant?.tclink);
                }}
                underline='none'
              >
                {t('trip:termsAndConditions')}
              </Link>
            </span>
          </div>
        </div>
        {termsError !== '' && (
          <span
            style={{
              color: '#FF6E6E',
              marginLeft: '15px',
              fontSize: '13px',
              position: 'absolute',
              'marginTop': '-22px'
            }}
          >
            {termsError}
          </span>
        )}

        <div className='payment-form--button__container'>
          <XGButton
            disabled={isProcessing}
            id='cancel'
            size='medium'
            variant='contained'
            type='primary'
            text={t('trip:completePayment')}
            onClick={onCompletePayment}
          />
          <input type='submit' style={{ display: 'none' }} ref={submitRef} />
        </div>
      </form>
    </div>
  );
};

PaymentForm.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  paymentIntentId: PropTypes.string,
  setPaymentMethod: PropTypes.func,
  onCompletePayment: PropTypes.func,
  paymentMethodOrder: PropTypes.array
};

export default PaymentForm;
