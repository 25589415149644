//Hardcoded options for passenger form

export const hotelRequests = [
  'High Floor',
  'Low Floor',
  'King Bed',
  'Two Beds',
  'Handicap Accessible',
  'Special Occasion'
];

export const dietaryRestrictions = [
  'Vegetarian',
  'Vegan',
  'Pescatarian',
  'Peanut Allergy',
  'Gluten-Free',
  'Gluten Intolerant',
  'Dairy-Free'
];
