// @ts-nocheck
import { Box } from '@mui/material';
import { ArrowSquareLeft, ArrowSquareRight } from '@phosphor-icons/react';
import * as PropTypes from 'prop-types';
import './NavMenuToggle.css';

/**
 * Functional React component for rendering a custom element to toggle the nav menu.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 * @param {boolean} props.open - A boolean indicating whether the nav menu is open.
 * @param {function} props.onToggle - The callback function to handle the toggle action.
 * @param {...any} props.rest - Additional props to be spread on the img element.
 *
 * @returns {JSX.Element} - The rendered NavMenuToggle component.
 */

const NavMenuToggle = ({ id, onToggle, open, ...rest }) => (
  <Box
    data-testid='nav-menu-toggle'
    id={id}
    className='toggle-button-container'
    {...rest}
  >
    {open ? (
      <button className='nav-menu-toggle-button' onClick={onToggle}>
        <ArrowSquareLeft size={24} />
      </button>
    ) : (
      <button className='nav-menu-closed-toggle-button' onClick={onToggle}>
        <ArrowSquareRight size={24} />
      </button>
    )}
  </Box>
);

/**
 * Prop type definitions for NavMenuToggle.
 */
NavMenuToggle.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onToggle: PropTypes.func
};

export default NavMenuToggle;
