//@ts-nocheck
import { serviceTypes } from './serviceTypes/serviceTypes';

const defaultCode = 'MISC';

/**
 * Retrieves the icon for the given service type code.
 *
 * @param {string} code - The service type code
 * @return {JSX.Element} The icon for the service type
 */
export const getServiceTypeIcon = (code) => {
  const serviceType =
    serviceTypes.find((st) => st.code === code) ||
    serviceTypes.find((st) => st.code === defaultCode);

  return serviceType?.icon;
};

/**
 * Returns the count of each service type in the given data array.
 *
 * @param {Array} data - The array of objects containing service type information.
 * @return {Array} An array of objects with service type codes, ids, and counts.
 */
export const countServiceTypes = (data = []) => {
  const counts = data.reduce((acc, item) => {
    const {
      servicetypecode: code,
      servicetypeid: id,
      servicetypename: description
    } = item.servicetype || {};
    acc[code] = { id, code, description, count: (acc[code]?.count || 0) + 1 };
    return acc;
  }, {});

  return Object.values(counts);
};
