// @ts-nocheck
import UpdateActions from '../UpdateActions/UpdateActions';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XGModal } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom update day component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the update day component.
 * @param {string} [props.isOpenModal] - Boolean value to indicate whether modal is open.
 * @param {boolean} [props.isSubmitting] - Boolean value to indicate whether form is submitting.
 * @param {Function} [props.updateIsOpen] - Function to update the open state.
 * @param {Function} [props.onSubmit] - Function to handle form submission.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the update day component.
 */

const UpdateDayContent = ({
  id,
  isOpenModal,
  isSubmitting,
  updateIsOpen,
  onSubmit,
  ...rest
}) => {
  const { t } = useTranslation(['trip', 'common', 'errors']);

  return (
    <Box id={id} data-testid='update-trip-day-component' {...rest}>
      <XGModal
        allowClosing={false}
        data-testid='update-trip-day-modal-component'
        actions={
          <UpdateActions
            onCancel={() => {
              updateIsOpen(false);
            }}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        }
        dismissable={false}
        id='update-trip-day-modal'
        open={isOpenModal}
        title={t('updateDayContent')}
        labelledby={t('updateDayContent')}
        maxWidth='lg'
        scroll='body'
        onClose={() => {
          updateIsOpen(false);
        }}
      >
        <Box className='create-trip-header' sx={{ maxWidth: '400px' }}>
          <p className='text-md-regular'>
            {`${t('dayContentUpdateConfirmation')} ${t(
              'common:thisActionCannotBeUndone'
            )}`}
          </p>
        </Box>
      </XGModal>
    </Box>
  );
};

UpdateDayContent.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  isOpenModal: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  updateIsOpen: PropTypes.func,
  onSubmit: PropTypes.func
};

export default UpdateDayContent;
