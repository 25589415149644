//@ts-nocheck

/**
 * Returns an array of options derived from the given destinations array.
 *
 * @param {Array} destinations - The array of destinations to derive options from.
 * @return {Array} An array of options with countryId, cityId, title, and code properties.
 */
const getOptions = (destinations) => {
  if (Array.isArray(destinations)) {
    return destinations.map((dest) => ({
      countryId: dest.countryId,
      cityId: dest.cityId,
      title: dest.description,
      code: dest.countryCode || dest.cityCode
    }));
  }

  return [];
};

/**
 * Returns the selected destination based on the provided options, country, and city.
 *
 * @param {Array} options - The list of available destination options.
 * @param {Object} country - The selected country object.
 * @param {Object} city - The selected city object.
 * @return {Object} The selected destination object, or null if not found.
 */
const getSelectedDestination = (options, country, city) => {
  if (Array.isArray(options)) {
    return options.find((option) =>
      country && city
        ? option.countryId === country?.countryid &&
          option.cityId === city?.cityid
        : option.countryId === country?.countryid ||
          option.cityId === city?.cityid
    );
  }

  return null;
};

const destinationHelper = { getOptions, getSelectedDestination };

export default destinationHelper;
