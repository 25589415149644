//@ts-nocheck
import App from '../App';
import globalConfig from '../config/globalConfig';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

const LDProvider = withLDProvider({
  clientSideID: globalConfig.launchDarklyKey || '',
  reactOptions: {
    useCamelCaseFlagKeys: false
  }
})(App);

export default LDProvider;
