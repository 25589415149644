// @ts-nocheck
import { Box } from '@mui/material';
import { formatCurrency } from '../../../../utils/currencyHelper';
import * as PropTypes from 'prop-types';
import './PaymentSummaryItem.css';

/**
 * Functional React component for rendering a custom payment summary item.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Object} [props.data] - The data for payment summary item.
 * @param {string} [props.id] - The ID for the payment summary item.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the payment summary item component.
 */

const PaymentSummaryItem = ({ data, id, ...rest }) => {
  const { title, amount } = data;

  return (
    <Box
      className='payment-summary__item'
      data-testid='payment-summary-item'
      id={id}
      {...rest}
    >
      <div className='payment-summary__item-column'>
        <span className='text-md-medium payment-summary__item-column__title'>
          {title}
        </span>
      </div>
      <div className='payment-summary__item-column text-right'>
        <span className='h3-semibold'>{formatCurrency(amount)}</span>
      </div>
    </Box>
  );
};

PaymentSummaryItem.propTypes = {
  data: PropTypes.shape({
    amount: PropTypes.number,
    title: PropTypes.string
  }),
  id: PropTypes.string.isRequired
};

export default PaymentSummaryItem;
