// @ts-nocheck
import NavMenuItem from '../NavMenuItem/NavMenuItem';
import { XGAvatar } from '@xg-cl/xg-cl';
import { Box } from '@mui/material';
import { Gear } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import './NavMenuFooter.css';

/**
 * Functional React component for rendering a custom nav menu footer.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 * @param {boolean} props.open - A boolean indicating whether the nav menu is open.
 * @param {Object} props.data - Data containing information for the nav menu footer.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} - The rendered NavMenuFooter component.
 */

const NavMenuFooter = ({ id, open, data, ...rest }) => {
  const { t } = useTranslation(['settings', 'common']);
  const currentPath = `/${location.pathname.split('/')[1] || ''}`;
  const menuItem = {
    id: 1000,
    title: t('globalSettings'),
    route: '/global-settings',
    icon: <Gear />
  };

  return (
    <Box
      data-testid='nav-menu-footer'
      id={id}
      className='nav-menu-footer'
      {...rest}
    >
      <NavMenuItem
        active={currentPath === menuItem?.route}
        id={`nav-menu-item-global-settings`}
        item={menuItem}
        key={menuItem?.id}
        open={open}
        style={{ marginBottom: '24px' }}
      />
      <div className={'nav-menu-bar-divider ' + (open ? 'open' : 'closed')} />
      <div className={'nav-menu-footer-content ' + (!open ? 'closed' : '')}>
        <XGAvatar id='nav-menu-user-avatar' alt={data.username} src='' />
        {open && <p className='text-sm-semibold '>{data.username}</p>}
      </div>
    </Box>
  );
};

/**
 * Prop type definitions for NavMenuFooter.
 */
NavMenuFooter.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool
};

export default NavMenuFooter;
