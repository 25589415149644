// @ts-nocheck
import { useState, useEffect, lazy } from 'react';
import Box from '@mui/material/Box';
import OutputSkeleton from '../../Skeleton/OutputSkeleton/OutputSkeleton';
import NotFoundPage from '../../NotFoundPage/NotFoundPage';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { addBooking } from '../../../redux/slices/booking/booking';
import { getOutputTrip } from '../../../services/output/trip';
import { getTenantSettings } from '../../../services/tenant/tenant';
import { setHighlightColor } from '../../../redux/slices/color/color';
import { setHeadingFont, setBodyFont } from '../../../redux/slices/font/font';
import FontDataConfig from '../../GlobalSettings/Templates/FontDataConfig/FontDataConfig';
import './TripOutput.css';

const SETTINGCODE = 'TEMPLATEFIELDS';

// Dynamic import of components
const Crios = lazy(
  () =>
    import(
      '../templates/Trips/DefaultOutput/DefaultTripOutput/DefaultTripOutput'
    )
);
const Alekos = lazy(
  () =>
    import('../templates/Trips/SecondOutput/SecondTripOutput/SecondTripOutput')
);
const Nereus = lazy(
  () => import('../templates/Trips/ThirdOutput/ThirdTripOutput/ThirdTripOutput')
);

const componentMap = {
  Crios,
  Alekos,
  Nereus
};

/**
 * Functional React component for rendering a trip output configuration.
 *
 * @namespace Components
 *
 * @param {object} props - The properties of the component.
 * @param {string} props.id - The ID for the OutputDataConfig element.
 * @param {object} props.rest - Additional props to be spread onto parent element.
 *
 * @returns {JSX.Element} React element representing the OutputDataConfig component.
 */
const TripOutput = ({ id, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['trip', 'common']);
  const { tripId } = useLoaderData();
  const [tripData, setTripData] = useState(null);
  const [isTripLoading, setIsTripLoading] = useState(true);
  const [didTripFetchSucceed, setDidTripFetchSucceed] = useState(true);
  const [selectedOutput, setSelectedOutput] = useState('Crios');
  const headingFont = useSelector((state) => state.font.headingFont);
  const bodyFont = useSelector((state) => state.font.bodyFont);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const bookingPlanId = searchParams.get('bookingPlanId');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getOutputTrip(tripId);

        if (data && Object.keys(data).length > 0) {
          const {
            basecurrency,
            sellingcurrency,
            tenantdomain,
            tenantname,
            tenantlogo,
            tenantid
          } = data?.tenant || {};

          setTripData(data);
          setDidTripFetchSucceed(true);

          const tenantData = {
            domain: tenantdomain,
            name: tenantname,
            logo: tenantlogo,
            tenantid: tenantid,
            basecurrency,
            sellingcurrency
          };

          const serializedTenantData = JSON.stringify(tenantData);
          localStorage.setItem('tenantData', serializedTenantData);

          dispatch(
            addBooking({
              tenant: data?.tenant,
              bookingplans: sortTripPlans(data?.tripplan),
              trip: {
                tripname: data?.tripname,
                tripcontent: data?.tripcontent,
                startdate: data?.startdate,
                duration: data?.duration,
                tripid: data?.tripid,
                fees: data?.fees ?? []
              }
            })
          );
        } else {
          setDidTripFetchSucceed(false);
        }
      } catch (error) {
        setDidTripFetchSucceed(false);
      } finally {
        setIsTripLoading(false);
      }
    };

    const fetchTemplateSettings = async () => {
      try {
        const settingsData = await getTenantSettings(SETTINGCODE);
        if (!settingsData?.data || !settingsData?.data?.settingvalue) {
          dispatch(setHeadingFont('Inter'));
          dispatch(setBodyFont('Inter'));
          dispatch(setHighlightColor('#0D69D4'));
          setSelectedOutput('Crios');
        }
        if (settingsData?.data?.settingvalue) {
          const settingvalue = JSON.parse(settingsData.data.settingvalue)[0];
          const { name, headingfont, bodyfont, color } = settingvalue;
          dispatch(setHeadingFont(headingfont ?? 'Inter'));
          dispatch(setBodyFont(bodyfont ?? 'Inter'));
          dispatch(setHighlightColor(color ?? '#0D69D4'));
          setSelectedOutput(name ?? 'Crios');
        }
      } catch (error) {
        console.error(t('common:errorLoadingSettings'), error);
      }
    };

    fetchData();
    fetchTemplateSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId, dispatch, t]);

  const sortTripPlans = (tripPlans) => {
    if (Array.isArray(tripPlans)) {
      const sortedTripPlans = tripPlans.sort(
        (a, b) => Number(a.priceperperson) - Number(b.priceperperson)
      );

      const foundPlanIndex = sortedTripPlans.findIndex(
        (plan) => plan?.tripplanid === Number(bookingPlanId)
      );
      const planIndex = foundPlanIndex > -1 ? foundPlanIndex : 0;

      return sortedTripPlans.map((tripPlan, index) => {
        return {
          ...tripPlan,
          selectedQuantity:
            index === planIndex ? Number(tripPlan?.occupancy) : 0,
          depositamount: Number(tripPlan?.depositamount),
          maxavailable: Number(tripPlan?.maxavailable),
          priceperperson: Number(tripPlan?.priceperperson),
          isSelected: index === planIndex
        };
      });
    }

    return [];
  };

  const SelectedComponent = componentMap[selectedOutput] || null;

  if (!didTripFetchSucceed) {
    return <NotFoundPage id='not-found-output' />;
  }

  return (
    <Box
      data-testid='trip-output'
      id={id}
      {...rest}
      className='output__container'
    >
      <FontDataConfig
        fonts={[
          { family: headingFont, weights: [400, 700] },
          { family: bodyFont, weights: [400, 700] }
        ]}
      >
        {() => (
          <div>
            {isTripLoading ? (
              <OutputSkeleton id='output-skeleton' />
            ) : (
              SelectedComponent &&
              tripData && (
                <div className='template-output-component-container'>
                  <SelectedComponent
                    id={`${selectedOutput.toLowerCase()}-template`}
                    data={tripData}
                  />
                </div>
              )
            )}
          </div>
        )}
      </FontDataConfig>
    </Box>
  );
};

TripOutput.propTypes = {
  id: PropTypes.string.isRequired
};

export default TripOutput;
