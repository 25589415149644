// @ts-nocheck
import { Prohibit } from '@phosphor-icons/react';
import * as PropTypes from 'prop-types';
import './NotAllowedToDrop.css';

/**
 * Functional React component for rendering a custom not allowed to drop element.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the NotAllowedToDrop component.
 */

const NotAllowedToDrop = ({ id, ...rest }) => {
  return (
    <div id={id} className='not-allowed-to-drop' {...rest}>
      <Prohibit color='#344054' size={35} />
      <p>You can not drop this element here</p>
    </div>
  );
};

NotAllowedToDrop.propTypes = { id: PropTypes.string };

export default NotAllowedToDrop;
