//@ts-nocheck
import Skeleton from '@mui/material/Skeleton';
import ShellLayout from '../../layouts/ShellLayout/ShellLayout';
import shellHeaderBuilder from '../../utils/shellHeaderBuilder';
import CreateTrip from '../../components/Trip/CreateTrip/CreateTrip';
import useFetchData from '../../hooks/useFetchData';
import TripsPanel from '../../components/Trips/TripsPanel/TripsPanel';
import { PlusCircle } from '@phosphor-icons/react';
import { XGButton } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateContentTypes } from '../../redux/slices/contenttypes/contenttypes';

/**
 * This is the trips page component.
 *
 * @namespace Pages
 */

const Trips = ({ id }) => {
  const { t } = useTranslation(['trips']);
  const [openModal, setOpenModal] = useState(false);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const dispatch = useDispatch();
  const {
    data: destinationsData,
    isLoading: destinationsLoading,
    error: destinationsError
  } = useFetchData(`/destinations`);

  const {
    data: tripStatusesData,
    isLoading: tripStatusesLoading,
    error: tripStatusesError
  } = useFetchData(`/tripstatuses`);

  const {
    data: contentTypeData,
    isLoading: contentTypeLoading,
    error: contentTypeError
  } = useFetchData(`/contenttypes`);

  const header = shellHeaderBuilder({
    title: {
      title: t('trips')
    },
    cta: (
      <XGButton
        autoFocus
        id='button-add-trip'
        text={t('addTrip')}
        type='primary'
        size='large'
        variant='contained'
        onClick={() => setOpenModal(true)}
        startIcon={<PlusCircle size={20} />}
      />
    )
  });

  useEffect(() => {
    if (contentTypeData) {
      dispatch(updateContentTypes(contentTypeData));
    }
  }, [contentTypeData, contentTypeLoading, contentTypeError, dispatch]);

  // Sets up destinationsOptions in the format that
  // <CreateTrip /> needs it
  useEffect(() => {
    if (destinationsData && destinationsData.length !== 0) {
      const destinationOptions = [];
      destinationsData.map((destination) => {
        const { countryId, cityId, description } = destination;
        destinationOptions.push({
          countryId,
          cityId,
          title: description
        });
      });
      setDestinationOptions(destinationOptions);
    }
  }, [destinationsData]);

  return (
    <div id={id} data-testid='trip-page'>
      <ShellLayout id='shell-layout' headerData={header}>
        {!destinationsLoading &&
        !destinationsError &&
        destinationsData &&
        destinationsData.length !== 0 ? (
          <CreateTrip
            id='create-trip'
            openModal={openModal}
            setOpenModal={setOpenModal}
            destinations={destinationOptions}
          />
        ) : (
          <Box
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              marginTop: '16px',
              marginBottom: '16px',
              width: '450px',
              height: '40px'
            }}
          >
            <Skeleton
              className='skeletonLoading'
              variant='rectangular'
              width={'100%'}
              height={'87%'}
            />
          </Box>
        )}
        {!tripStatusesLoading &&
        !tripStatusesError &&
        tripStatusesData &&
        tripStatusesData.length !== 0 ? (
          <TripsPanel
            destinations={destinationOptions}
            tripStatuses={tripStatusesData}
          />
        ) : (
          <Box
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              marginTop: '16px',
              marginBottom: '16px',
              width: '450px',
              height: '40px'
            }}
          >
            <Skeleton
              className='skeletonLoading'
              variant='rectangular'
              width={'100%'}
              height={'87%'}
            />
          </Box>
        )}
      </ShellLayout>
    </div>
  );
};

Trips.propTypes = {
  id: PropTypes.string.isRequired
};

export default Trips;
