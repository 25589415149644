// @ts-nocheck
import { Box, Button, TextField, Input } from '@mui/material';
import './EmbedTab.css';
import { useEffect, useState } from 'react';
import { Eyedropper, Link } from '@phosphor-icons/react';
import { getTextColor } from '../../../utils/colors/colors';
import { XGInput } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

const EmbedTab = ({ tripid }) => {
  const { t } = useTranslation(['common', 'embed']);
  const [customColor, setCustomColor] = useState(
    localStorage.getItem('customColor') || '#1361D8'
  );
  const [customText, setCustomText] = useState(
    localStorage.getItem('customText') || 'Book Now'
  );
  const [linkCopied, setLinkCopied] = useState(t('trip:copyLink'));
  const [embedLink, setEmbedLink] = useState('');
  const hostname = window.location.hostname;
  const handleCustomColorChange = (event) => {
    const color = event.target.value;
    setCustomColor(color);
    localStorage.setItem('customColor', color);
  };

  const handleCustomTextChange = (event) => {
    const text = event.target.value;
    setCustomText(text);
    localStorage.setItem('customText', text);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(embedLink);
    setLinkCopied(t('trip:linkCopied'));
    setTimeout(() => {
      setLinkCopied(t('trip:copyLink'));
    }, 1500);
  };

  useEffect(() => {
    const buttonTemplate = `<button
    data-stng-cta-id="stng-payment-${tripid}" data-stng-url="https://${hostname}/shared/trip/${tripid}/booking" style="
             background-color: ${customColor};
             color: #fff;
             border: 1px solid rgb(19, 97, 216);
             border-radius: 5px;
             font-family: 'Poppins', sans-serif;
             font-weight: 400;
             font-size: 14px;
             -webkit-font-smoothing: antialiased;
             text-transform: capitalize;
             padding: 13px 24px;
             text-decoration: none;
             text-align: center;
             line-height: 14px;
             display: inline-block;
             cursor: pointer;
    "
    >
         ${customText}
    </button>
    <script src="https://${hostname}/scripts/bookingManager.min.js"></script>`;
    setEmbedLink(buttonTemplate);
  }, [customColor, customText, hostname, tripid]);

  return (
    <div>
      <div className='embed-header'>
        <p className='embed-title'>{t('embed:buttonCustomization')}</p>
        <p className='embed-hint-text'>{t('embed:hintText')}</p>
      </div>
      <div className='embed-container'>
        <div className='left-section'>
          <section className='embed-input-section'>
            <label className='embed-label'>{t('embed:text')}</label>
            <TextField
              size='small'
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: '160px',
                  height: '40px',
                  padding: '8px 12px'
                }
              }}
              value={customText}
              onChange={handleCustomTextChange}
            />
          </section>

          <section className='embed-color-section'>
            <label className='embed-label'>Color</label>
            <div className='color-config'>
              <TextField
                size='small'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    width: '120px',
                    height: '40px',
                    padding: '8px 12px'
                  }
                }}
                value={customColor}
              />
              <Box className='color-config-custom'>
                <Input
                  type='color'
                  value={customColor}
                  onChange={handleCustomColorChange}
                  className='color-config-input'
                />
                <Eyedropper
                  size={24}
                  color={getTextColor(customColor)}
                  className='color-config-eyedropper'
                />
              </Box>
            </div>
          </section>
        </div>
        <div className='right-section'>
          <Button
            sx={{
              backgroundColor: `${customColor}`
            }}
          >
            {customText}
          </Button>
        </div>
      </div>
      <div className='embed-inputLink'>
        <p className='embed-copy-text '>{t('embed:copyPaste')}</p>
        <XGInput
          data-testid='trip-header-actions-modal-input'
          name='link'
          id='trips-link-input'
          readOnly={true}
          type='text'
          value={embedLink}
          icon
          customIcon={
            <div className='embed-customIcon' onClick={copyToClipboard}>
              <Link size={20} />
              <span style={{ marginLeft: '8px' }}>{linkCopied}</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

EmbedTab.propTypes = {
  tripid: PropTypes.number
};

export default EmbedTab;
