// @ts-nocheck
import CircularProgress from '@mui/material/CircularProgress';
import Editor from '../../Editor/Editor';
import SlideOutDrawer from '../../SlideOutDrawer/SlideOutDrawer';
import ImagePicker from '../../../components/Upload/ImagePicker/ImagePicker';
import { useTranslation } from 'react-i18next';
import { XGButton, XGInput } from '@xg-cl/xg-cl';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { preventBubbling } from '../../../utils/eventHelper';
import { updateTripDay, addTripDayImage } from '../../../services/trip/trip';
import { ErrorToast } from '../../../utils/alerts';
import { useDispatch } from 'react-redux';
import { blue } from '@mui/material/colors';
import { editDay, updateDayImage } from '../../../redux/slices/trip/trip';
import * as PropTypes from 'prop-types';
import './EditDay.css';

const EditActions = ({ onCancel, onSave, isSubmitting, isImageUploading }) => {
  const { t } = useTranslation(['common']);

  return (
    <Box className='edit-day-footer'>
      <XGButton
        data-testid='detailed-view-edit-day-save-button'
        id='detailed-view-edit-day-save-button'
        text={t('cancel')}
        type='secondary'
        size='large'
        variant='outlined'
        onClick={onCancel}
      />
      <Box sx={{ position: 'relative', width: '50%' }}>
        <XGButton
          style={{
            width: '100%',
            backgroundColor: '#475467',
            borderColor: '#475467',
            cursor: isSubmitting || isImageUploading ? 'not-allowed' : 'pointer'
          }}
          data-testid='detailed-view-edit-day-cancel-button'
          id='detailed-view-edit-day-cancel-button'
          text={t('save')}
          type='primary'
          size='large'
          variant='contained'
          onClick={onSave}
          disabled={isSubmitting || isImageUploading}
        />
        {isSubmitting && (
          <CircularProgress
            size={24}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        )}
      </Box>
    </Box>
  );
};

EditActions.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isImageUploading: PropTypes.bool
};

/**
 * Functional React component for rendering a custom edit day component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.data] - The ID for the edit day item component.
 * @param {string} [props.id] - The ID for the edit day item component.
 * @param {string} [props.isOpenDrawer] - Boolean value to indicate whether the edit value is open.
 * @param {string} [props.onToggle] - Callback function to handle keyboard events.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the edit day item component.
 */
const EditDay = ({ data, id, isOpenDrawer, onToggle, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['trip', 'common']);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [markdown, setMarkdown] = useState(data?.description || '');
  const [title, setTitle] = useState(data?.title || '');

  const handleOnSave = async (event) => {
    try {
      const { tripDayId } = data;
      preventBubbling(event);
      setIsSubmitting(true);

      const payload = {
        title: title,
        description: `${markdown}`
      };
      const result = await updateTripDay(tripDayId, payload);

      if (result.error || !result.data) {
        setIsSubmitting(false);
        ErrorToast(t('errors:tripDayCouldNotBeUpdated'));
      } else {
        dispatch(editDay(result.data));

        handleOnCancel();
      }
    } catch (err) {
      setIsSubmitting(false);
      ErrorToast(t('tripDayCouldNotBeUpdated'));
    }
  };

  const handleOnCancel = () => {
    setMarkdown('');
    setTitle('');
    onToggle(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (data) {
      setTitle(data?.title || '');
      setMarkdown(data?.description || '');
    }
  }, [data]);

  const updateDayCoverImage = async (newImageUrl) => {
    const { tripDayId } = data;
    const { data: dayData } = await addTripDayImage(tripDayId, {
      imageUrl: newImageUrl
    });

    if (dayData) {
      const { tripdayimageid, seq, imageurl } = dayData;

      dispatch(
        updateDayImage({
          tripdayid: tripDayId,
          tripdayimage: [
            {
              tripdayimageid,
              seq,
              imageurl
            }
          ]
        })
      );

      return { data: true };
    }

    return { data: false };
  };

  const handleImageUploading = (value = false) => {
    setIsImageUploading(value);
  };

  return (
    <Box
      id={id}
      className='edit-day-container'
      data-testid='edit-day-component'
      {...rest}
    >
      <SlideOutDrawer
        footer={
          <EditActions
            onCancel={handleOnCancel}
            onSave={handleOnSave}
            isSubmitting={isSubmitting}
            isImageUploading={isImageUploading}
          />
        }
        anchor={'right'}
        id='detailed-edit-drawer'
        open={isOpenDrawer}
        position='right'
      >
        <div style={{ width: '100%', position: 'relative' }}>
          <ImagePicker
            callback={updateDayCoverImage}
            id={`image-picker-component`}
            title={`${t('editDay')} ${data?.dayNumber}`}
            url={data?.coverImage?.imageurl}
            handleUploading={handleImageUploading}
          />
        </div>

        <Box className='edit-day-content'>
          <Box className='edit-day-row'>
            <XGInput
              label={t('title')}
              name='title'
              placeholder={t('enterTitle')}
              id='day-title-input'
              type='text'
              value={title || ''}
              onChange={(evt) => {
                setTitle(evt.target.value);
              }}
              inputProps={{ maxLength: 250 }}
            />
            <span className='title-length-hint length-hint text-sm-medium'>
              {title.length}/250
            </span>
          </Box>
          <Box className='edit-day-row'>
            <Editor
              content={data?.description}
              editable={true}
              id='editor'
              label={t('description')}
              setDescription={() => {}}
              setMarkdown={setMarkdown}
              className='custom-day__editor'
            />
          </Box>
        </Box>
      </SlideOutDrawer>
    </Box>
  );
};

EditDay.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  isOpenDrawer: PropTypes.bool,
  onToggle: PropTypes.func
};

export default EditDay;
