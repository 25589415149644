//@ts-nocheck
import * as PropTypes from 'prop-types';
import './DottedPaginator.css';

/**
 * Functional React component for rendering a custom dotted paginator.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {JSX.Element} [props.dots] - The amount of dots to display.
 * @param {string} [props.id] - The ID for the login header element.
 * @param {JSX.Element} [props.selected] - The selected step in the paginator.
 
 *
 * @returns {JSX.Element} React element representing the dotted paginator.
 */

const DottedPaginator = ({ dots, id, selected = 1 }) => {
  const dotList = Array.from({ length: dots });

  return (
    <div
      className='dotted-paginator-container'
      data-testid='dotted-paginator'
      id={id}
    >
      {dotList &&
        dotList.length > 0 &&
        dotList.map((dot, index) => {
          const classes =
            index + 1 === selected
              ? 'paginator-dot paginator-dot--selected'
              : 'paginator-dot';
          return (
            <div
              data-testid='dotted-paginator-item'
              className={classes}
              key={index}
            />
          );
        })}
    </div>
  );
};

/**
 * Prop type definitions for DottedPaginator.
 */
DottedPaginator.propTypes = {
  dots: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.number
};
export default DottedPaginator;
