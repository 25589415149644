//@ts-nocheck
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom call to action section on the main header.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.className] - The custom CSS class name for the call to action.
 * @param {string} [props.id] - The ID for the login call to action element.
 * @param {Object} [props.rest] - Additional props to be spread onto the call to action element.
 *
 * @returns {JSX.Element} React element representing the call to action.
 */

const CallToAction = ({ children, className, id, ...rest }) => {
  return (
    <>
      <Box
        className={className}
        data-testid='call-to-action'
        id={id}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'end'
        }}
        {...rest}
      >
        {children}
      </Box>
    </>
  );
};

/**
 * Prop type definitions for CallToAction.
 */
CallToAction.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string.isRequired
};

export default CallToAction;
