// @ts-nocheck
import DaysNavMenuItem from '../DaysNavMenuItem/DaysNavMenuItem';
import DraggableList from '../../Draggable/DraggableList/DraggableList';
import NotAllowedToDrop from '../../../components/Trip/Library/NotAllowedToDrop/NotAllowedToDrop';
import {
  TRIP_DAYS_DROPPABLE_ID,
  REORGANIZEDAYS
} from '../../../constants/dragAndDrop';
import { updateTripDaysSequence } from '../../../services/trip/trip';
import { updateDaySequence } from '../../../redux/slices/trip/trip';
import { cleanDraggableAction } from '../../../redux/slices/library/library';
import { Box } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorToast } from '../../../utils/alerts';
import { reorderItems, sortByProperty } from '../../../utils/common';
import * as PropTypes from 'prop-types';
import './DaysNavMenu.css';
import '../../Draggable/Draggable.css';

/**
 * Functional React component for rendering a custom day list content for a trip.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip day list.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} React element representing the trip day list.
 */

const DaysNavMenu = ({ id, onAction, selectedDay, ...rest }) => {
  const { tripday: tripdays } = useSelector((state) => state.trip);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const isDragging = useSelector((state) => state.library?.isDragging ?? false);
  const isTripDaysDraggableDisabled = useSelector(
    (state) => state.library?.itinerary?.isTripDaysDraggableDisabled ?? false
  );
  const draggableAction = useSelector(
    (state) => state.library?.itinerary?.draggableAction ?? null
  );

  //Gets the day list of items for the Drag and Drop component
  const getDayItems = (list = []) => {
    const mappedItems = list.map((item, index) => {
      const custom = {
        id: `id-${index}`,
        tripdayid: item?.tripdayid,
        daynumber: item?.daynumber,
        title: item?.title,
        content: (
          <>
            <DaysNavMenuItem
              key={`${item?.tripdayid}`}
              id={`${item?.tripdayid}`}
              item={{ ...item, daynumber: index + 1 }}
              active={item?.isSelected ?? false}
              onAction={onAction}
              showDeleteButton={list.length > 1}
            />
          </>
        )
      };

      return custom;
    });

    return mappedItems;
  };

  const sortDayItems = (sourceIndex, destinationIndex, list = []) => {
    const organizedItems = reorderItems(
      list ?? [],
      sourceIndex,
      destinationIndex
    );

    const tripdaysToUpdate = organizedItems?.map((item, index) => {
      return {
        tripdayid: item?.tripdayid,
        daynumber: index + 1,
        title: item?.title
      };
    });

    return { daynumbers: tripdaysToUpdate };
  };

  const reorderDays = async (sourceIndex, destinationIndex) => {
    const payload = sortDayItems(
      sourceIndex,
      destinationIndex,
      calculatedLocalState
    );

    dispatch(
      updateDaySequence({
        tripdayid: selectedDay,
        tripdays: payload?.daynumbers ?? []
      })
    );

    const { error } = await updateTripDaysSequence(payload);

    if (error) {
      const rollBackPayload = sortDayItems(
        sourceIndex,
        destinationIndex,
        payload?.daynumbers ?? []
      );

      dispatch(
        updateDaySequence({
          tripdayid: selectedDay,
          tripdays: rollBackPayload?.daynumbers ?? []
        })
      );
      ErrorToast(t('errors:tripDayCouldNotBeUpdated'));
    }
  };

  const sortedTripDays = sortByProperty([...(tripdays || [])], 'daynumber');
  const calculatedLocalState = getDayItems(sortedTripDays);

  //On react 18 we need to load Droppable element after the component is mounted otherwise it won't work
  //See: https://github.com/atlassian/react-beautiful-dnd/issues/2399
  useEffect(() => {
    setIsMounted(true);
  }, []);

  //This effect acts as a listener, responding to actions dispatched by the DragDropContext to Redux.
  //This component is capable of identifying these actions and performing its own corresponding actions.
  useEffect(() => {
    const {
      type = null,
      sourceIndex = 0,
      destinationIndex = 0
    } = draggableAction ?? {};

    if (type === REORGANIZEDAYS) {
      reorderDays(sourceIndex, destinationIndex);
      dispatch(cleanDraggableAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggableAction]);

  return (
    <Box>
      {tripdays && (
        <ul
          data-testid='days-nav-menu-list'
          id={id}
          className='days-nav-menu-list'
          {...rest}
        >
          {isTripDaysDraggableDisabled && isDragging && (
            <NotAllowedToDrop id={'library-not-allowed-to-drop'} />
          )}
          {isMounted ? (
            <Droppable
              droppableId={TRIP_DAYS_DROPPABLE_ID}
              isDropDisabled={isTripDaysDraggableDisabled}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`droppable-container ${snapshot.isDraggingOver || (isDragging && !isTripDaysDraggableDisabled) ? 'droppable-container--dragging-over' : ''}`}
                >
                  <DraggableList items={calculatedLocalState} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <></>
          )}
        </ul>
      )}
    </Box>
  );
};

DaysNavMenu.propTypes = {
  id: PropTypes.string.isRequired,
  onAction: PropTypes.func,
  selectedDay: PropTypes.number
};

export default DaysNavMenu;
