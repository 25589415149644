// @ts-nocheck
import { CircularProgress, Link } from '@mui/material';
import { XGInput, XGButton, XGCheckBox, XGRadio } from '@xg-cl/xg-cl';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import {
  updateActiveStep,
  updateTotalItem,
  updatePassengerForms
} from '../../../../redux/slices/booking/booking';
import { ErrorToast } from '../../../../utils/alerts';
import { getBookingTotals } from '../../../../utils/booking';
import { formatCurrency } from '../../../../utils/currencyHelper';
import { useSelector, useDispatch } from 'react-redux';
import { isEmailValid } from '../../../../utils/emailValidator';
import { preventBubbling } from '../../../../utils/eventHelper';
import { updatePaymentIntent } from '../../../../services/stripe/stripe';
import {
  createBooking,
  createPassenger
} from '../../../../services/booking/booking';
import { createTransaction } from '../../../../services/transaction/transaction';
import { getFeesBreakdown } from '../../../../utils/feesHelper';
import {
  paymentElementOptions,
  successfulStatuses,
  SUCCEEDED
} from '../../../../constants/stripe';
import {
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import * as PropTypes from 'prop-types';
import './CheckoutForm.css';

/**
 * Functional React component for rendering a custom checkout form.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the checkout form.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the custom checkout form component.
 */
const CheckoutForm = ({ id, paymentIntentId, paymentMethodOrder, ...rest }) => {
  const dispatch = useDispatch();
  const submitRef = useRef(null);
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const stripe = useStripe();
  const elements = useElements();

  const [customForm, setCustomForm] = useState({
    email: '',
    firstName: '',
    lastName: ''
  });

  const [customFormError, setCustomFormError] = useState({
    email: '',
    firstName: '',
    lastName: ''
  });

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previousPaymentMethod, setPreviousPaymentMethod] = useState(null);
  const booking = useSelector((state) => state.booking);
  const trip = booking?.trip || null;
  const bookingplans = booking?.bookingplans || [];
  const tenant = booking?.tenant || null;
  const passengerForms = booking?.passengerForms || [];
  const { totalPrice, totalDeposit } = getBookingTotals(bookingplans);
  const displayDeposit = totalDeposit >= 1;

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(
    displayDeposit ? 'optiondeposit' : 'optionfullamount'
  );

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const { type } = previousPaymentMethod;

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsSubmitting(true);

      const { error, paymentIntent = {} } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.href}#`,
          payment_method_data: {
            billing_details: {
              address: {
                country: 'US' //Default Country(if we disable country on the form we should set a default value for Stripe to proccess the payment)
              },
              email: customForm?.email.trim(),
              name: `${customForm?.firstName.trim()} ${customForm?.firstName.trim()}`
            }
          }
        },
        redirect: 'if_required' //This instruction avoid Stripe to redirect when getting the transaction result
      });

      const { status } = paymentIntent;

      if (paymentIntent && successfulStatuses.includes(status)) {
        //Create booking
        const plansForBooking = bookingplans
          .filter((tripPlan) => tripPlan?.selectedQuantity > 0)
          .map((tripPlan) => {
            const foundForms = passengerForms.filter(
              (form) => form?.tripplanid === tripPlan?.tripplanid
            );

            return {
              tripplanid: tripPlan?.tripplanid,
              passengers: [],
              occupancy: foundForms?.length ?? 0
            };
          });

        const bookingPayload = {
          maincontact: {
            email: customForm?.email.trim(),
            firstname: customForm?.firstName.trim(),
            lastname: customForm?.lastName.trim()
          },
          tripid: trip?.tripid,
          tripplans: plansForBooking,
          // TODO: This block with "aditionaTempData" data should be removed when sending email to Davidsis no longer necessary
          aditionaTempData: passengerForms
          // TODO: This block with "aditionaTempData" data should be removed when sending email to Davidsis no longer necessary
        };

        const bookingResult = await createBooking(bookingPayload);

        if (bookingResult && bookingResult?.data) {
          const bookingid = bookingResult?.data?.bookingid;

          //Create transaction
          const transactionPayload = {
            bookingid,
            amount:
              selectedPaymentOption === 'optionfullamount'
                ? totalPrice
                : totalDeposit,
            stripetransactionid: paymentIntent?.id,
            paymentMethod: type,
            status
          };

          const transactionResult = await createTransaction(transactionPayload);

          if (transactionResult && transactionResult?.data) {
            const transactionid = transactionResult?.data?.transactionid;

            //Update payment intent
            await updatePaymentIntent(paymentIntent?.id, {
              description: `${bookingid} - ${trip?.tripname}`,
              metadata: {
                'Payment type':
                  selectedPaymentOption === 'optionfullamount'
                    ? t('trip:fullAmount')
                    : t('trip:depositAmount'),
                'Booking number': bookingid,
                'Transaction ID': transactionid
              }
            });

            //Create passengers
            const passengersToBeCreated = getPassengers(passengerForms);

            const passengerPromises = passengersToBeCreated.map((plan) => {
              return createPassenger({ bookingid, ...plan });
            });

            const passengerResults = await Promise.all(passengerPromises);

            if (passengerResults) {
              const message =
                status !== SUCCEEDED
                  ? t('paymentIsBeingProcessed')
                  : t('paymentSuccessful');

              dispatch(updatePassengerForms({ passengerForms: [] }));
              dispatch(updateActiveStep({ key: 'success', message }));
            } else {
              ErrorToast(t('errors:bookingUnexpectedError'));
            }
          }
        } else {
          ErrorToast(t('errors:bookingUnexpectedError'));
        }

        /*
        TODO: Here we're assuming that everything went well. However, we should handle errors in a better way and take
        care of them here.

        We need somehow be able to troubleshoot errors, rollback payments or bookings, take futher steps, etc. TBD
        
        */
      } else {
        if (error) {
          if (error?.type !== 'validation_error') {
            ErrorToast(t('errors:bookingPaymentFailed'));
          }
        }
      }
    } catch (error) {
      ErrorToast(t('errors:bookingUnexpectedError'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChangeCustomInput = (event) => {
    const { name, value } = event.target;

    setCustomForm({ ...customForm, [name]: value });
    setCustomFormError({ ...customFormError, [name]: '' });
  };

  const onGoBack = () => {
    dispatch(
      updateTotalItem({
        totalKey: 'trip-subtotal',
        totalItem: {
          visible: false
        }
      })
    );

    dispatch(
      updateTotalItem({
        totalKey: 'trip-totalfees',
        totalItem: {
          visible: false
        }
      })
    );

    dispatch(
      updateTotalItem({
        totalKey: 'trip-total',
        totalItem: {
          priceperperson: totalPrice,
          totalPrice: totalPrice,
          visible: true
        }
      })
    );

    dispatch(updateActiveStep({ key: 'passenger' }));
  };

  const validateCustomForm = () => {
    setCustomFormError({
      email: '',
      firstName: '',
      lastName: ''
    });

    let errors = { ...customFormError };

    if (customForm.email.trim() === '') {
      errors = { ...errors, email: t('trip:yourEmailIsRequired') };
    } else {
      if (!isEmailValid(customForm.email)) {
        errors = { ...errors, email: t('trip:pleaseEnterValidEmail') };
      }
    }

    if (customForm.firstName.trim() === '') {
      errors = { ...errors, firstName: t('trip:yourFirstNameIsRequired') };
    }

    if (customForm.lastName.trim() === '') {
      errors = { ...errors, lastName: t('trip:yourLastNameIsRequired') };
    }

    const isValidForm =
      errors.email === '' && errors.firstName === '' && errors.lastName === '';
    if (!isValidForm) {
      setCustomFormError(errors);
    }

    return isValidForm;
  };

  const onCompletePayment = (evt) => {
    preventBubbling(evt);
    const isValid = validateCustomForm();
    setTermsError('');

    if (isValid) {
      if ((tenant?.tcrequired && isTermsChecked) || !tenant?.tcrequired) {
        if (submitRef.current) {
          submitRef.current.click();
        }
      } else {
        setTermsError(t('trip:mustAcceptTermsAndConditions'));
      }
    }
  };

  const openInNewTab = (evt, url) => {
    evt.preventDefault();
    window.open(url, '_blank');
  };

  const updateBookingTotal = (newAmount = 0, fees = [], paymentRef = '') => {
    const { totalWithFees, totalFees } = getFeesBreakdown(
      newAmount ?? 0,
      fees ?? [],
      paymentRef ?? ''
    );

    const subtotal = totalWithFees - totalFees;

    dispatch(
      updateTotalItem({
        totalKey: 'trip-total',
        totalItem: {
          priceperperson: totalWithFees,
          totalPrice: totalWithFees
        }
      })
    );

    if (totalFees > 0) {
      dispatch(
        updateTotalItem({
          totalKey: 'trip-subtotal',
          totalItem: {
            priceperperson: subtotal,
            totalPrice: subtotal,
            visible: true
          }
        })
      );

      dispatch(
        updateTotalItem({
          totalKey: 'trip-totalfees',
          totalItem: {
            priceperperson: totalFees,
            totalPrice: totalFees,
            visible: true
          }
        })
      );
    } else {
      dispatch(
        updateTotalItem({
          totalKey: 'trip-subtotal',
          totalItem: {
            priceperperson: subtotal,
            totalPrice: subtotal,
            visible: false
          }
        })
      );

      dispatch(
        updateTotalItem({
          totalKey: 'trip-totalfees',
          totalItem: {
            priceperperson: totalFees,
            totalPrice: totalFees,
            visible: false
          }
        })
      );
    }
  };

  const onChangePaymentOption = async (evt) => {
    setIsProcessing(true);
    if (stripe && elements) {
      const optionType = evt.target.value;
      const newAmount =
        optionType === 'optionfullamount' ? totalPrice : totalDeposit;
      const {
        totalWithFees,
        applicationFeeNotIncluded,
        applicationFeeIncluded
      } = getFeesBreakdown(
        newAmount ?? 0,
        trip?.fees ?? [],
        previousPaymentMethod?.type ?? ''
      );

      updateBookingTotal(
        newAmount ?? 0,
        trip?.fees ?? [],
        previousPaymentMethod?.type ?? ''
      );

      setSelectedPaymentOption(optionType);

      const result = await updatePaymentIntent(paymentIntentId, {
        amount: totalWithFees,
        applicationFees: applicationFeeNotIncluded + applicationFeeIncluded,
        metadata: {
          'Payment type':
            optionType === 'optionfullamount'
              ? t('trip:fullAmount')
              : t('trip:depositAmount'),
          'Booking number': 'None'
        }
      });

      if (!result?.data || result?.error) {
        const previousOption =
          optionType === 'optionfullamount'
            ? 'optiondeposit'
            : 'optionfullamount';

        //Rolling back payment option if something went wrong
        setSelectedPaymentOption(previousOption);
      }

      setIsProcessing(false);
    }
  };

  const getPassengers = (formData) => {
    const passengers = [];

    for (const form of formData) {
      const fields = form.fields;
      const passenger = {};

      for (const field of fields) {
        passenger[field.field] = field.value;
      }

      passengers.push(passenger);
    }

    return passengers;
  };

  const handlePaymentMethodChange = async (event) => {
    if (event?.elementType === 'payment') {
      const currentPaymentMethod = event?.value;
      const { type } = currentPaymentMethod;

      if (type && type !== previousPaymentMethod?.type) {
        const currentAmount =
          selectedPaymentOption === 'optionfullamount'
            ? totalPrice
            : totalDeposit;

        const {
          totalWithFees,
          applicationFeeNotIncluded,
          applicationFeeIncluded
        } = getFeesBreakdown(
          currentAmount ?? 0,
          trip?.fees ?? [],
          event?.value?.type ?? ''
        );

        updateBookingTotal(
          currentAmount ?? 0,
          trip?.fees ?? [],
          event?.value?.type ?? ''
        );

        updatePaymentIntent(paymentIntentId, {
          amount: totalWithFees,
          applicationFees: applicationFeeNotIncluded + applicationFeeIncluded
        });

        setPreviousPaymentMethod(currentPaymentMethod);
      }
    }
  };

  return (
    <div id={id} {...rest} data-testid='checkout-form'>
      <form
        id='payment-form'
        className='checkout-payment__form'
        onSubmit={handleSubmit}
      >
        <span className='h3-semibold'>{t('trip:paymentOptions')}</span>

        {displayDeposit ? (
          <div className='item-to-pay'>
            <div>
              <XGRadio
                id='radio-deposit'
                name='group-payment-options'
                label={t('trip:payDeposit')}
                size='medium'
                value='optiondeposit'
                checked={selectedPaymentOption === 'optiondeposit'}
                onChange={onChangePaymentOption}
              />
            </div>
            <div>
              <span className='h3-semibold'>
                {formatCurrency(totalDeposit)}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className='item-to-pay'>
          <div>
            <XGRadio
              id='radio-full-amount'
              name='group-payment-options'
              label='Pay full amount'
              size='medium'
              value='optionfullamount'
              checked={selectedPaymentOption === 'optionfullamount'}
              onChange={onChangePaymentOption}
            />
          </div>
          <div>
            <span className='h3-semibold'>{formatCurrency(totalPrice)}</span>
          </div>
        </div>

        <div>
          <XGInput
            autoFocus
            error={customFormError.email !== ''}
            hint={customFormError.email}
            label={`${t('common:formLabels.email')}*`}
            name='email'
            placeholder={t('common:formLabels.emailPlaceholder')}
            id='add-day-title-input'
            type='text'
            value={customForm?.email || ''}
            onChange={(evt) => {
              onChangeCustomInput(evt);
            }}
            inputProps={{
              maxLength: 250
            }}
          />

          <div style={{ display: 'flex', gap: '12px', margin: '20px 0' }}>
            <XGInput
              error={customFormError.firstName !== ''}
              hint={customFormError.firstName}
              label={`${t('common:formLabels.firstName')}*`}
              name='firstName'
              placeholder={t('common:formLabels.firstNamePlaceholder')}
              id='add-day-title-input'
              type='text'
              value={customForm?.firstName || ''}
              onChange={(evt) => {
                onChangeCustomInput(evt);
              }}
              inputProps={{
                maxLength: 250
              }}
              style={{ with: '50%' }}
            />
            <XGInput
              error={customFormError.lastName !== ''}
              hint={customFormError.lastName}
              label={`${t('common:formLabels.lastName')}*`}
              name='lastName'
              placeholder={t('common:formLabels.lastNamePlaceholder')}
              id='add-day-title-input'
              type='text'
              value={customForm?.lastName || ''}
              onChange={(evt) => {
                onChangeCustomInput(evt);
              }}
              inputProps={{
                maxLength: 250
              }}
              style={{ with: '50%' }}
            />
          </div>
        </div>

        <PaymentElement
          options={{ ...paymentElementOptions, paymentMethodOrder }}
          onChange={handlePaymentMethodChange}
        />

        <div className='terms-and-conditions__wrapper'>
          <div className='terms-and-conditions__content'>
            <XGCheckBox
              id='check-terms-and-conditions'
              name='terms'
              size='medium'
              checked={isTermsChecked}
              onChange={() => {
                setIsTermsChecked(!isTermsChecked);
              }}
            />
            <span className='terms-and-conditions__link'>
              {t('trip:IHaveReadThe')}{' '}
              <Link
                id='button-49'
                href={tenant?.tclink || ''}
                onClick={(evt) => {
                  openInNewTab(evt, tenant?.tclink);
                }}
                underline='none'
              >
                {t('trip:termsAndConditions')}
              </Link>
            </span>
          </div>
          {termsError !== '' && !isTermsChecked && (
            <span className='text-sm-regular terms-and-conditions__error'>
              {termsError}
            </span>
          )}
        </div>

        <div className='checkout-payment__actions'>
          <XGButton
            disabled={isProcessing || isSubmitting}
            id='cancel-payment'
            size='medium'
            variant='outlined'
            type='secondary'
            text={t('common:back')}
            onClick={onGoBack}
          />
          <div style={{ position: 'relative' }}>
            <XGButton
              disabled={isProcessing || isSubmitting}
              id='complete-payment'
              size='medium'
              variant='contained'
              type='primary'
              text={t('trip:completePayment')}
              onClick={onCompletePayment}
            />
            {isSubmitting && (
              <CircularProgress
                id='booking-modal-payment-actions-loader'
                size={24}
                sx={{
                  color: blue[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </div>

          <input type='submit' style={{ display: 'none' }} ref={submitRef} />
        </div>
      </form>
    </div>
  );
};

CheckoutForm.propTypes = {
  id: PropTypes.string.isRequired,
  paymentIntentId: PropTypes.string,
  paymentMethodOrder: PropTypes.array
};

export default CheckoutForm;
