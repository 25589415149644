// @ts-nocheck
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import './MainHeaderSkeleton.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom skeleton for the main header.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the main header.
 *
 * @returns {JSX.Element} - The rendered skeleton for the main header.
 */

const MainHeaderSkeleton = ({ id }) => {
  return (
    <Box
      id={id}
      className='main-header-skeleton main-header'
      data-testid='main-header-skeleton'
    >
      <Box sx={{ width: '50%' }}>
        <Skeleton variant='text' sx={{ fontSize: '24px' }} width={'80%'} />
        <Box sx={{ display: 'flex', gap: '10px', padding: '8px' }}>
          <Skeleton variant='rectangular' height={20} width={20} />
          <Skeleton variant='rectangular' height={20} width={20} />
          <Skeleton
            variant='text'
            sx={{ fontSize: '12px', marginLeft: '8px' }}
            width={'30%'}
          />
          <Skeleton variant='rectangular' height={20} width={20} />
          <Skeleton
            variant='text'
            sx={{ fontSize: '12px', marginLeft: '8px' }}
            width={'50%'}
          />
        </Box>
      </Box>
      <Box>
        <Skeleton width={150} height={65} />
      </Box>
    </Box>
  );
};

/**
 * Prop type definitions for MainHeaderSkeleton.
 */
MainHeaderSkeleton.propTypes = {
  id: PropTypes.string.isRequired
};

export default MainHeaderSkeleton;
