//@ts-nocheck
import AuthHeader from '../../components/AuthHeader/AuthHeader';
import Box from '@mui/material/Box';
import BoxedIcon from '../../components/BoxedIcon/BoxedIcon';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DottedPaginator from './../../components/DottedPaginator/DottedPaginator';
import { ErrorToast } from '../../utils/alerts';
import { isEmailValid } from '../../utils/emailValidator';
import { resetPassword } from './../../services/auth/auth';
import { useState } from 'react';
import { XGButton, XGInput } from '@xg-cl/xg-cl';
import { Key, WarningCircle } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import * as PropTypes from 'prop-types';

/**
 * This is the forgot password page component.
 *
 * @namespace Pages
 */

const ForgotPassword = ({ id }) => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFocus = () => {
    setErrorMessage('');
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (email.trim() === '') {
        setErrorMessage('Email address is required');
      } else if (!isEmailValid(email.trim())) {
        setErrorMessage('Please enter a valid email address');
      } else {
        const result = await resetPassword({ email });

        if (!result.data) {
          ErrorToast('Email could not be sent, please try again');
        }

        navigate('/forgot-password/send', {
          state: { email: email.trim() }
        });
      }
    } catch (err) {
      ErrorToast('Something went wrong, please try again');
    }
  };

  return (
    <div id={id} data-testid='forgot-password-page'>
      <AuthHeader
        icon={
          <BoxedIcon
            id='forgot-boxed-icon'
            icon={<Key color='#344054' size={28} />}
          />
        }
        id='forgot-password-header'
        text='No worries, we’ll send you reset instructions.'
        title='Forgot password?'
      />
      <Card
        data-testid='forgot-password-page-content'
        sx={{
          padding: '32px 40px 32px 40px',
          borderRadius: '12px',
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '20px',
              alignSelf: 'stretch',
              marginBottom: '24px'
            }}
          >
            <XGInput
              error={errorMessage !== ''}
              icon={errorMessage !== ''}
              hint={errorMessage}
              customIcon={
                errorMessage !== '' && (
                  <WarningCircle color='#F04438' size={16} />
                )
              }
              id='email-forgot-password-input'
              type='email'
              label='Email*'
              name='email'
              onChange={handleChange}
              onFocus={handleFocus}
              placeholder='Enter your email'
              value={email}
            />
          </Box>
        </CardContent>
        <CardActions
          sx={{ width: '100%', '& button': { width: '100%' }, padding: 0 }}
        >
          <XGButton
            id='button-reset-password'
            text='Reset password'
            type='primary'
            size='large'
            variant='contained'
            onClick={handleSubmit}
          />
        </CardActions>
      </Card>

      <Box
        sx={{
          display: 'flex',
          padding: '24px 0px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '32px',
          alignSelf: 'stretch',
          justifyContent: 'center'
        }}
      >
        <XGButton
          href='/login'
          id='forgot-password-back-to-login'
          text='Back to log in'
          type='primary'
          style={{ padding: '0px' }}
          size='medium'
          variant='text'
        />
        <DottedPaginator dots={4} id='forgot-password-paginator' selected={1} />
      </Box>
    </div>
  );
};

/**
 * Prop type definitions for ForgotPassword.
 */
ForgotPassword.propTypes = {
  id: PropTypes.string.isRequired
};

export default ForgotPassword;
