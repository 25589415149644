// @ts-nocheck
import AboutTrip from '../AboutTrip/AboutTrip';
import CustomTabs from '../../Tabs/CustomTabs';
import CustomTab from '../../Tabs/CustomTab';
import DetailedView from '../DetailedView/DetailedView';
import AdditionalContent from '../AdditionalContent/AdditionalContent';
import ImagePicker from '../../../components/Upload/ImagePicker/ImagePicker';
import LibrarySlide from '../Library/LibrarySlide/LibrarySlide';
import { addTripImage } from '../../../services/trip/trip';
import { DragDropContext } from 'react-beautiful-dnd';
import { Books } from '@phosphor-icons/react';
import { updateTrip } from '../../../redux/slices/trip/trip';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { getLastListItem } from '../../../utils/common';
import { XGButton } from '@xg-cl/xg-cl';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  CREATENEWDAY,
  CREATE_NEW_ADDITIONAL_CONTENT,
  UPDATEDAYCONTENT,
  REORGANIZEDAYS,
  CONTENT_LIBRARY_DROPPABLE_ID,
  TRIP_DAYS_DROPPABLE_ID,
  TRIP_DAY_CONTENT_DROPPABLE_ID,
  TRIP_DAY_LINEITEM_DROPPABLE_ID,
  REORGANIZE_ADDITIONAL_ITEMS,
  TRIP_CONTENT_LIST_DROPPABLE_ID,
  TRIP_CONTENT_ITEM_DROPPABLE_ID,
  UPDATE_ADDITIONAL_CONTENT
} from '../../../constants/dragAndDrop';
import {
  addIsDragging,
  addDraggableStatus,
  cleanDraggableStatus,
  addDraggableAction
} from '../../../redux/slices/library/library';
import * as PropTypes from 'prop-types';
import './Itinerary.css';
import '../../Draggable/Draggable.css';
import Amenities from '../Amenities/Amenities';

/**
 * Functional React component for rendering a custom itinerary content for a trip.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip itinerary component.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} React element representing the trip itinerary.
 */

const Itinerary = ({ id, ...rest }) => {
  const trip = useSelector((state) => state.trip);
  const dispatch = useDispatch();
  const { tripid, tripimage } = trip;
  const tripImage = getLastListItem(tripimage);
  const [openedTab, setOpenedTab] = useState(0);
  const [openSlide, setOpenSlide] = useState(false);
  const [showLibraryButton, setsShowLibraryButton] = useState(false);

  const updateTripCoverImage = async (newImageUrl) => {
    const { data } = await addTripImage(tripid, { imageUrl: newImageUrl });
    if (data) {
      const { tripimageid, seq, imageurl } = data;

      dispatch(
        updateTrip({
          tripimage: [
            {
              tripimageid,
              seq,
              imageurl
            }
          ]
        })
      );
      return { data: true };
    }

    return { data: false };
  };

  const handleSlideOpen = () => {
    setOpenSlide(true);
  };

  const handleSlideClose = () => {
    setOpenSlide(false);
  };

  const handleTabChange = (newValue) => {
    if (openedTab !== newValue) {
      handleSlideClose();
    }

    setOpenedTab(newValue);
  };

  const onDragStart = (data) => {
    const { source } = data;

    dispatch(addIsDragging({ isDragging: true }));

    if (source?.droppableId == TRIP_DAYS_DROPPABLE_ID) {
      dispatch(
        addDraggableStatus({
          context: 'itinerary',
          data: {
            isTripDaysDraggableDisabled: false,
            isDayContentDraggableDisabled: true,
            isLibraryDraggableDisabled: true,
            isDayLineItemDraggableDisabled: true
          }
        })
      );
    } else if (source?.droppableId == CONTENT_LIBRARY_DROPPABLE_ID) {
      dispatch(
        addDraggableStatus({
          context: 'itinerary',
          data: {
            isTripDaysDraggableDisabled: false,
            isDayContentDraggableDisabled: false,
            isLibraryDraggableDisabled: false,
            isDayLineItemDraggableDisabled: true,
            isTripContentListDraggableDisabled: false,
            isTripContentDataDraggableDisabled: false
          }
        })
      );
    } else if (source?.droppableId == TRIP_DAY_LINEITEM_DROPPABLE_ID) {
      dispatch(
        addDraggableStatus({
          context: 'itinerary',
          data: {
            isTripDaysDraggableDisabled: true,
            isDayContentDraggableDisabled: true,
            isLibraryDraggableDisabled: true,
            isDayLineItemDraggableDisabled: false
          }
        })
      );
    } else if (source?.droppableId == TRIP_CONTENT_LIST_DROPPABLE_ID) {
      dispatch(
        addDraggableStatus({
          context: 'itinerary',
          data: {
            isLibraryDraggableDisabled: true,
            isTripContentListDraggableDisabled: false,
            isTripContentDataDraggableDisabled: true
          }
        })
      );
    }
  };

  const onDragEnd = (data) => {
    const { destination, source, draggableId } = data;
    dispatch(addIsDragging({ isDragging: false }));
    dispatch(cleanDraggableStatus());

    //Destination is not set, do nothing
    if (!destination) {
      return;
    }

    //Destination and source are exactly the same, in the same index do nothing
    if (
      destination?.droppableId === source?.droppableId &&
      destination?.index === source?.index
    ) {
      return;
    }

    //It's not allowed to move days items to content library
    if (
      source?.droppableId === TRIP_DAYS_DROPPABLE_ID &&
      destination?.droppableId === CONTENT_LIBRARY_DROPPABLE_ID
    ) {
      return;
    }

    //We are getting the content blocks from the library to add to a new trip day
    if (
      source?.droppableId === CONTENT_LIBRARY_DROPPABLE_ID &&
      destination?.droppableId === TRIP_DAYS_DROPPABLE_ID
    ) {
      dispatch(
        addDraggableAction({
          context: 'itinerary',
          data: {
            type: CREATENEWDAY,
            sourceIndex: source.index,
            destinationIndex: destination.index,
            draggableId
          }
        })
      );
      return;
    }

    //We are getting the content blocks from the library to add a new trip content
    if (
      source?.droppableId === CONTENT_LIBRARY_DROPPABLE_ID &&
      destination?.droppableId === TRIP_CONTENT_LIST_DROPPABLE_ID
    ) {
      dispatch(
        addDraggableAction({
          context: 'itinerary',
          data: {
            type: CREATE_NEW_ADDITIONAL_CONTENT,
            sourceIndex: source.index,
            destinationIndex: destination.index,
            draggableId
          }
        })
      );
      return;
    }

    //We are getting the content blocks from the library to update a trip day
    if (
      source?.droppableId === CONTENT_LIBRARY_DROPPABLE_ID &&
      destination?.droppableId === TRIP_DAY_CONTENT_DROPPABLE_ID
    ) {
      dispatch(
        addDraggableAction({
          context: 'itinerary',
          data: {
            type: UPDATEDAYCONTENT,
            sourceIndex: source.index,
            destinationIndex: destination.index,
            draggableId
          }
        })
      );

      return;
    }

    //We are getting the content blocks from the library to update a trip content
    if (
      source?.droppableId === CONTENT_LIBRARY_DROPPABLE_ID &&
      destination?.droppableId === TRIP_CONTENT_ITEM_DROPPABLE_ID
    ) {
      dispatch(
        addDraggableAction({
          context: 'itinerary',
          data: {
            type: UPDATE_ADDITIONAL_CONTENT,
            sourceIndex: source.index,
            destinationIndex: destination.index,
            draggableId
          }
        })
      );

      return;
    }

    //Destination and source are exactly the same for days, but indexes are different, proced to reorganize days
    if (
      source?.droppableId === TRIP_DAYS_DROPPABLE_ID &&
      destination?.droppableId === source?.droppableId &&
      destination?.index !== source?.index
    ) {
      dispatch(
        addDraggableAction({
          context: 'itinerary',
          data: {
            type: REORGANIZEDAYS,
            sourceIndex: source.index,
            destinationIndex: destination.index
          }
        })
      );
      return;
    }

    //Destination and source are exactly the same for content, but indexes are different, proced to reorganize content items
    if (
      source?.droppableId === TRIP_CONTENT_LIST_DROPPABLE_ID &&
      destination?.droppableId === source?.droppableId &&
      destination?.index !== source?.index
    ) {
      dispatch(
        addDraggableAction({
          context: 'itinerary',
          data: {
            type: REORGANIZE_ADDITIONAL_ITEMS,
            sourceIndex: source.index,
            destinationIndex: destination.index
          }
        })
      );
      return;
    }
  };

  useEffect(() => {
    if (openedTab > 1) {
      setsShowLibraryButton(false);
    } else {
      setsShowLibraryButton(true);
    }
  }, [openedTab]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box
        id={id}
        className='itinerary-wapper'
        data-testid='trip-itinerary'
        {...rest}
      >
        <ImagePicker
          callback={updateTripCoverImage}
          id={`image-picker-component`}
          url={tripImage?.imageurl}
        />
        <div className='itinerary-content'>
          <AboutTrip id={`about-trip-component`} />
          <div className='itinerary-description-divider' />
          <div className='itinerary-container'>
            <Box
              className='buttons-container'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Box sx={{ width: '100%', position: 'relative' }}>
                {showLibraryButton && (
                  <XGButton
                    data-testid='library-button'
                    className='library-button'
                    id='library-button'
                    text={t('trip:library')}
                    type='secondary'
                    size='large'
                    variant='outlined'
                    onClick={handleSlideOpen}
                    startIcon={<Books size={20} />}
                  />
                )}

                <CustomTabs handleTabChange={handleTabChange}>
                  <CustomTab label={t('trip:days')}>
                    <DetailedView id='itinerary-detailed-view' />
                  </CustomTab>
                  <CustomTab label={t('trip:additionalContent')}>
                    <AdditionalContent id='itinerary-trip-additional-content' />
                  </CustomTab>
                  <CustomTab label={t('trip:amenities')}>
                    <Amenities tripid={tripid} />
                  </CustomTab>
                </CustomTabs>
              </Box>
            </Box>
            <LibrarySlide
              openSlide={openSlide}
              handleSlideClose={handleSlideClose}
              openedTab={openedTab}
            />
          </div>
        </div>
      </Box>
    </DragDropContext>
  );
};

Itinerary.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired
};

export default Itinerary;
