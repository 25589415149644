// @ts-nocheck
import * as PropTypes from 'prop-types';
import './NavMenuItem.css';
import { useNavigate } from 'react-router-dom';

/**
 * Functional React component for rendering a custom navigation menu item.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {boolean} props.active - A boolean indicating whether the item is active.
 * @param {string} props.id - The unique identifier for the component.
 * @param {Object} props.item - An object representing the menu item.
 * @param {string} props.item.title - The title of the menu item.
 * @param {string} props.item.route - The route associated with the menu item.
 * @param {string} props.item.icon - The icon associated with the menu item.
 * @param {boolean} props.open - A boolean indicating whether the nav menu is open.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} - The rendered NavMenuItem component.
 */

const NavMenuItem = ({ active, id, item, open, ...rest }) => {
  const navigate = useNavigate();

  return (
    <li
      data-testid='nav-menu-item'
      id={id}
      className={'nav-menu-item ' + (active ? 'active' : '')}
      {...rest}
    >
      <a onClick={() => navigate(`${item.route}`)}>
        <div className='nav-menu-item-content'>
          {item.icon} {open && item.title}
        </div>
      </a>
    </li>
  );
};

/**
 * Prop type definitions for NavMenuItem.
 */
NavMenuItem.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string.isRequired,
  item: PropTypes.object,
  open: PropTypes.bool
};

export default NavMenuItem;
