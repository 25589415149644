//@ts-nocheck
import ShellLayout from '../../layouts/ShellLayout/ShellLayout';
import shellHeaderBuilder from '../../utils/shellHeaderBuilder';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { XGButton, XGInputSearch } from '@xg-cl/xg-cl';
import './Library.css';
import { Box, Skeleton } from '@mui/material';
import { Sun, File, Trash } from '@phosphor-icons/react';
import LibraryNewItemSlide from '../../components/Trip/Library/LibraryNewItemSlide/LibraryNewItemSlide';
import { getContent } from '../../services/library/content';
import { getContentTypes } from '../../services/contenttype/contenttype';
import LibraryDeleteContent from '../../components/Trip/Library/LibraryDeleteContent/LibraryDeleteContent';

/**
 * This is the library page component.
 *
 * @namespace Pages
 */

const Library = ({ id }) => {
  const { t } = useTranslation(['trip', 'library']);
  const [libraryContentTypes, setLibraryContentTypes] = useState([]);
  const [selectedLibraryContentType, setSelectedLibraryContentType] =
    useState(null);
  const [initialLibraryContent, setInitialLibraryContent] = useState([]);
  const [libraryContent, setLibraryContent] = useState([]);
  const [inputText, setInputText] = useState('');
  const [idSelected, setIdSelected] = useState(0);
  const [openSlide, setOpenSlide] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const mainHeader = shellHeaderBuilder({
    title: {
      title: t('trip:library')
    },
    cta: <></>
  });

  const handleClickButton = (event) => {
    const { name } = event.target;
    const contentType = libraryContentTypes?.find(
      (contentType) => contentType?.code === name
    );
    setSelectedLibraryContentType(
      contentType?.contenttypeid ? contentType : null
    );
    setInputText('');
  };

  const handleChangeInput = (event) => {
    const { value } = event.target;
    setInputText(value);
  };

  const handleSearch = () => {
    const text = inputText.trim().toLocaleLowerCase();

    if (text.length > 0) {
      if (!selectedLibraryContentType) {
        const filteredContent = initialLibraryContent.filter((content) =>
          content?.title?.toLowerCase().includes(text)
        );
        setLibraryContent(filteredContent);
      } else {
        const filteredContent = initialLibraryContent.filter(
          (content) =>
            content?.title?.toLowerCase().includes(text) &&
            content?.contenttypeid === selectedLibraryContentType?.contenttypeid
        );
        setLibraryContent(filteredContent);
      }
    } else {
      setLibraryContent(initialLibraryContent);
    }
  };

  const handleSlideClose = () => {
    setOpenSlide(false);
    setIdSelected(0);
  };

  const handleNewitem = () => {
    setOpenSlide(true);
  };

  const handleDelete = (id) => {
    setIdSelected(id);
    setOpenModal(true);
  };

  const handleOnItemDeleted = (id) => {
    const contentWitoutItem = initialLibraryContent.filter(
      (item) => item.contentid != id
    );
    setOpenSlide(false);
    setInitialLibraryContent(contentWitoutItem);
  };

  const handleClickOnContent = (id) => {
    setIdSelected(id);
    setOpenSlide(true);
  };

  //Set libary content on content type change
  useEffect(() => {
    const contenttypeid = selectedLibraryContentType?.contenttypeid;

    if (!contenttypeid) {
      setLibraryContent(initialLibraryContent);
      return;
    }

    const filteredLibraryContent = initialLibraryContent.filter(
      (content) => content.contenttypeid === contenttypeid
    );

    setLibraryContent(filteredLibraryContent);
  }, [selectedLibraryContentType, initialLibraryContent]);

  //Fetch library content
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const libraryContent = await getContent();
        const libraryContentModified = libraryContent.data.map((object) => ({
          ...object,
          images: object.contentimage
        }));
        setInitialLibraryContent(libraryContentModified);
        setLibraryContent(libraryContentModified);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  //Fetch content types
  useEffect(() => {
    const fetchLibContentTypesData = async () => {
      const libraryContentTypes = await getContentTypes();
      setLibraryContentTypes(libraryContentTypes?.data);
    };
    fetchLibContentTypesData();
  }, []);

  return (
    <div id={id} data-testid='library-page'>
      <ShellLayout
        id='global-settings-shell'
        data-testid='global-settings-shell'
        headerData={mainHeader}
        isLoadingContent={false}
      >
        <div className='library__wrapper'>
          <div className='library-input-search-container'>
            <XGInputSearch
              name='content-title'
              placeholder={t('trip:search')}
              id='content-title-input'
              type='text'
              value={inputText}
              onChange={handleChangeInput}
              onClick={handleSearch}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <XGButton
              data-testid='library-page-new-item-button'
              className='library-page-new-item-button'
              id='library-page-new-item-button'
              text={'+ ' + t('library:newItem')}
              type='primary'
              size='medium'
              variant='contained'
              onClick={handleNewitem}
            />
          </div>
          <Box className='library-buttons-container'>
            <XGButton
              data-testid='library-page-button'
              className={`library-page-button${selectedLibraryContentType === null ? '-active' : ''}`}
              id='library-page-button'
              name='all'
              text={t('library:all')}
              type='primary'
              size='medium'
              variant='contained'
              onClick={handleClickButton}
            />
            <XGButton
              data-testid='library-page-button'
              className={`library-page-button${selectedLibraryContentType?.contenttypeid === 1000 ? '-active' : ''}`}
              id='library-page-button'
              name='day'
              text={t('library:days')}
              type='primary'
              size='medium'
              variant='contained'
              onClick={handleClickButton}
            />
            <XGButton
              data-testid='library-page-button'
              className={`library-page-button${selectedLibraryContentType?.contenttypeid === 1001 ? '-active' : ''}`}
              id='library-page-button'
              name='additional'
              text={t('library:content')}
              type='primary'
              size='medium'
              variant='contained'
              onClick={handleClickButton}
            />
          </Box>

          <div className='library-content-list'>
            {loading && libraryContent.length === 0 ? (
              <Box>
                {Array.from(new Array(10)).map((_, index) => (
                  <Box
                    key={index}
                    display='flex'
                    alignItems='center'
                    mb={2}
                    className='skeleton-item'
                  >
                    <Skeleton
                      variant='rectangular'
                      width='100%'
                      height='76px'
                      sx={{ marginRight: '10px', borderRadius: '8px' }}
                    />
                  </Box>
                ))}
              </Box>
            ) : libraryContent && libraryContent.length > 0 ? (
              libraryContent.map((item, index) => (
                <li
                  key={index}
                  data-testid='library-content-item'
                  id={item.contentid}
                  className={`library-content-item${idSelected === item.contentid ? '-selected' : ''}`}
                  onClick={() => {
                    handleClickOnContent(item.contentid);
                  }}
                >
                  <span className='text-md-semibold text-ellipsis '>
                    {item.title ? item.title : 'No title'}
                  </span>
                  <div className='library_content_item_icon'>
                    <Trash
                      size={20}
                      color='#667085'
                      className='library_icon'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete(item.contentid);
                      }}
                    />
                    {item.contenttypeid === 1000 ? (
                      <Sun color='#344054' size={20} />
                    ) : (
                      <File size={20} color='#667085' />
                    )}
                  </div>
                </li>
              ))
            ) : (
              <div className='no-content-found'>
                <span className='text-muted--secondary'>
                  {t('library:noResultsFor')} {`"${inputText}"`}
                </span>
              </div>
            )}
          </div>
        </div>
      </ShellLayout>
      {libraryContentTypes && (
        <LibraryNewItemSlide
          openSlide={openSlide}
          handleSlideClose={handleSlideClose}
          libraryContentTypes={
            libraryContentTypes.length > 0 ? libraryContentTypes : []
          }
          handleOnItemAdded={setInitialLibraryContent}
          initialContent={initialLibraryContent}
          idSelected={idSelected}
          handleDelete={handleDelete}
        />
      )}
      <LibraryDeleteContent
        contentid={idSelected}
        setIdSelected={setIdSelected}
        handleOnItemDeleted={handleOnItemDeleted}
        isOpenModal={openModal}
        updateIsOpen={setOpenModal}
        openSlide={openSlide}
        setOpenSlide={setOpenSlide}
      />
    </div>
  );
};

Library.propTypes = {
  id: PropTypes.string.isRequired
};

export default Library;
