//@ts-nocheck
import useFetchData from '../../../../hooks/useFetchData';
import { Box, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { XGButton, XGInput } from '@xg-cl/xg-cl';
import { useState, useEffect } from 'react';
import { Link, Wallet } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';
import { formatCurrency } from '../../../../utils/currencyHelper';
import './BookingsPanel.css';
import Skeleton from '@mui/material/Skeleton';

const BookingsPanel = () => {
  const { t } = useTranslation(['bookings']);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [linkCopied, setLinkCopied] = useState('Copy link');
  const [paymentLink, setPaymentLink] = useState(null);
  const [bookingList, setBookingList] = useState([]);
  const { tripId } = useLoaderData();

  const toggleRequestDialog = (bookinId, bookingKey) => {
    setPaymentLink(
      `${window.location.origin}/bookings/${bookinId}/payment?bookingkey=${bookingKey}`
    );
    setOpenRequestDialog(!openRequestDialog);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(paymentLink);
    setLinkCopied('Link copied');
    setTimeout(() => {
      setLinkCopied('Copy link');
    }, 1500);
  };

  const {
    data: bookingsData,
    isLoading: bookingDataLoading,
    error: bookingsDataError
  } = useFetchData(`/bookings/trip/${tripId}`);

  useEffect(() => {
    if (bookingsData && bookingsData.length > 0) {
      const newBookingsData = bookingsData.map((el, index) => {
        return {
          id: index,
          ...el,
          totalPrice: formatCurrency(el?.totalPrice),
          balance: formatCurrency(el?.balance),
          amountReceived: formatCurrency(el?.amountReceived),
          amountPending: formatCurrency(el?.amountInTransit),
          finalpaymentduedate: el.finalpaymentduedate
            ? formatDate(
                utcToZonedTime(new Date(el.finalpaymentduedate), 'UTC'),
                'dd MMM yyyy',
                { timeZone: 'UTC' }
              )
            : '-',
          unformatBalance: el.balance
        };
      });

      setBookingList(newBookingsData);
    }
  }, [bookingsData, bookingDataLoading, bookingsDataError]);

  const bookingsColumns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'bookingNumber',
      headerName: t('bookings:bookingNumber'),
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'participants',
      headerName: t('bookings:participants'),
      type: 'number',
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'totalPrice',
      headerName: t('bookings:totalPrice'),
      type: 'string',
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'amountReceived',
      headerName: t('bookings:amountReceived'),
      type: 'string',
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'amountPending',
      headerName: t('bookings:amountPending'),
      type: 'string',
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'balance',
      headerName: t('bookings:balance'),
      type: 'string',
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'finalpaymentduedate',
      headerName: t('bookings:finalPaymentDueDate'),
      type: 'number',
      width: 200,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'requestPayment',
      headerName: t('bookings:actions'),
      type: 'number',
      width: 195,
      align: 'right',
      headerAlign: 'center',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const { row = {} } = params;
        const { bookingKey, bookingNumber, unformatBalance } = row;

        return unformatBalance > 0 ? (
          <XGButton
            className='booking-panel-columns-button'
            data-testid='share-dialog-close-button'
            id='dialog-close-button'
            text={'Request payment'}
            type='secondary'
            size='small'
            variant='outlined'
            startIcon={<Wallet size={12} />}
            onClick={() => {
              toggleRequestDialog(bookingNumber, bookingKey);
            }}
          />
        ) : (
          <></>
        );
      }
    }
  ];

  const boxHeight = bookingList.length === 0 ? '100px' : 'auto';

  if (bookingsDataError) {
    return <>Error</>;
  } else if (bookingDataLoading) {
    return (
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={1000}
        sx={{ border: '1px solid #EAECF0' }}
      />
    );
  } else {
    return (
      <Box className='booking-panel-container' height={boxHeight}>
        <DataGrid
          className='booking-panel-datagrid'
          columns={bookingsColumns}
          rows={bookingList}
          initialState={{ columns: { columnVisibilityModel: { id: false } } }}
          hideFooter
          disableColumnMenu
          disableColumnFilter
          localeText={{ noRowsLabel: t('common:noDataAvailable') }}
        />
        <Modal open={openRequestDialog} onClose={toggleRequestDialog}>
          <Box className='booking-panel-modal-box'>
            <h3 className='booking-panel-modal-title'>Request payment</h3>
            <h3 className='request-dialog__subtitle text-sm-medium'>
              {'Link to share'}
            </h3>
            <XGInput
              name='link'
              id='trips-link-input'
              readOnly={true}
              type='text'
              value={paymentLink}
              icon
              customIcon={
                <div
                  className='booking-panel-copy-link'
                  onClick={copyToClipboard}
                >
                  <Link size={20} />
                  <span style={{ marginLeft: '8px' }}>{linkCopied}</span>
                </div>
              }
            />
            <div className='booking-panel-dialog-close-button'>
              <XGButton
                id='dialog-close-button'
                text={'Close'}
                type='secondary'
                size='large'
                variant='outlined'
                onClick={toggleRequestDialog}
              ></XGButton>
            </div>
          </Box>
        </Modal>
      </Box>
    );
  }
};

export default BookingsPanel;
