// @ts-nocheck
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/material';
import * as PropTypes from 'prop-types';
import './SlideOutDrawer.css';

/**
 * Functional React component for rendering a custom drawer component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.id] - The ID for the drawer.
 *
 * @returns {JSX.Element} React element representing the drawer component.
 */
const SlideOutDrawer = ({
  footer,
  children,
  header,
  id,
  open,
  position,
  ...rest
}) => {
  return (
    <Box id={id} className='slide-out-drawer' data-testid='slide-out-drawer'>
      <Drawer anchor={position} hideBackdrop open={open} {...rest}>
        {header && <Box className='slide-out-drawer-header'>{header}</Box>}
        {children && <Box className='slide-out-drawer-content'>{children}</Box>}
        {footer && <Box className='slide-out-drawer-footer'>{footer}</Box>}
      </Drawer>
    </Box>
  );
};

SlideOutDrawer.propTypes = {
  footer: PropTypes.node,
  children: PropTypes.node,
  header: PropTypes.node,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  position: PropTypes.string.isRequired
};

export default SlideOutDrawer;
