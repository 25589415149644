import request from '../request';

export const getStripeConfig = () =>
  request.get('payments/stripe/config').then((res) => res);

export const createPaymentIntent = (data = {}) =>
  request.post('payments/stripe/payment-intent', data).then((res) => res);

export const updatePaymentIntent = (paymentIntentId = '', data = {}) =>
  request
    .put(`payments/stripe/payment-intent/${paymentIntentId}`, data)
    .then((res) => res);
