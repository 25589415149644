// @ts-nocheck
import PaymentSummaryItem from '../PaymentSummaryItem/PaymentSummaryItem';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import './PaymentSummary.css';

/**
 * Functional React component for rendering a custom payment summary section.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the booking totals component.
 * @param {string} [props.items] - The items for the payment summary component.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the payment summary component.
 */

const PaymentSummary = ({ id, items = [], ...rest }) => {
  const { t } = useTranslation(['trip', 'common', 'errors']);

  return (
    <Box
      className='payment-summary'
      data-testid='payment-summary'
      id={id}
      {...rest}
    >
      <div className='payment-summary__header'>
        <span className='h4-semibold '>{t('trip:summary')}</span>
      </div>
      <div className='payment-summary__items'>
        {items &&
          items.map((item) => {
            return (
              item?.show && (
                <div key={item.id} className='payment-summary__item-container'>
                  {item.hasDivider && (
                    <span className='payment-summary__divider' />
                  )}
                  <PaymentSummaryItem id={`${item.id}`} data={{ ...item }} />
                </div>
              )
            );
          })}
      </div>
    </Box>
  );
};

PaymentSummary.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array
};

export default PaymentSummary;
