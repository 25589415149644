// @ts-nocheck
import { DotsSixVertical, Sun, File } from '@phosphor-icons/react';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom library item content.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {boolean} [props.item] - The library item.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the library item.
 */

const LibraryItem = ({ item, ...rest }) => {
  const { contentid = '', title = '', code = '' } = item ?? {};
  return (
    <li
      data-testid={`content-item-${contentid}`}
      id={`content-item-${contentid}`}
      className={'content-item'}
      {...rest}
    >
      <div className='content-item__draggable'>
        <DotsSixVertical color='#344054' size={24} />
      </div>
      <span className='text-md-semibold text-ellipsis'>
        {title ? title : 'No title'}
      </span>
      <div className='content-item__type-icon'>
        {code === 'day' ? (
          <Sun color='#344054' size={20} />
        ) : (
          <File size={20} color='#667085' />
        )}
      </div>
    </li>
  );
};

LibraryItem.propTypes = {
  item: PropTypes.object
};

export default LibraryItem;
