// @ts-nocheck
import PlanForm from '../PlanForm/PlanForm';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import './PlanList.css';
import { TO_BE_DELETED } from '../../../../redux/slices/trippricing/trippricingStatuses';

/**
 * Functional React component for rendering a custom plan list for a trip.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the plan list component.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip plan list.
 */

const PlanList = ({ id, plansRef, ...rest }) => {
  const { tripplans } = useSelector((state) => state.trippricing);

  return (
    <>
      <Box
        id={id}
        className='trip__pricing-plan__list'
        data-testid='trip-pricing-plan-list'
        {...rest}
      >
        {tripplans &&
          tripplans.map((tripplan, index) =>
            tripplan?.status !== TO_BE_DELETED ? (
              <PlanForm
                ref={index === tripplans.length - 1 ? plansRef : null}
                data={tripplan}
                key={tripplan?.tripplanid}
                id='trip-pricing-plan-form'
              />
            ) : null
          )}
      </Box>
    </>
  );
};

PlanList.propTypes = {
  id: PropTypes.string.isRequired,
  plansRef: PropTypes.any
};

export default PlanList;
