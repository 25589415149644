// @ts-nocheck
import actionTypes from '../../../constants/actionTypes';
import DeleteService from '../DeleteService/DeleteService';
import DraggableList from '../../Draggable/DraggableList/DraggableList';
import ServiceCard from '../ServiceCard/ServiceCard';
import ServiceForm from '../ServiceForm/ServiceForm';
import NotAllowedToDrop from '../../../components/Trip/Library/NotAllowedToDrop/NotAllowedToDrop';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XGButton } from '@xg-cl/xg-cl';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { preventBubbling } from '../../../utils/eventHelper';
import { ErrorToast } from '../../../utils/alerts';
import { Plus } from '@phosphor-icons/react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  addService,
  setEditingService,
  updateDayService,
  updateServicesSequence
} from '../../../redux/slices/trip/trip';
import {
  addServiceToTripDay,
  updateTripDayService,
  updateLineItemsSequence
} from '../../../services/trip/trip';
import { reorderItems, sortByProperty } from '../../../utils/common';
import { TRIP_DAY_LINEITEM_DROPPABLE_ID } from '../../../constants/dragAndDrop';
import * as PropTypes from 'prop-types';
import './DetailedDayLineItems.css';
import '../../Draggable/Draggable.css';
import {
  addIsDragging,
  addDraggableStatus,
  cleanDraggableStatus
} from '../../../redux/slices/library/library';

/**
 * Functional React component for rendering a custom list of line items.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {String} [props.id] - The ID for the trip detailed day component.
 * @param {Object} [props.data] - Object containing the data to build the list of line items.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed day.
 */

const DetailedDayLineItems = ({ id, data, ...rest }) => {
  const { t } = useTranslation(['trip', 'common', 'library']);
  const { lineitem, tripdayid } = data || {};
  const storedTripData = useSelector((state) => state.trip);
  const currentUser = useSelector((state) => state.user);
  const services = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const selectedDay = storedTripData?.tripday.find((day) => day.isSelected);
  const lineItems = sortByProperty([...(lineitem || [])], 'seq');
  const editing = lineItems?.find((item) => item?.isEditing);

  //Status definitions
  const [deleteServiceItem, setDeleteServiceItem] = useState(null);
  const [isDeletingServiceModal, setIsDeletingServiceModal] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isServiceFormAdding, setIsServiceFormAdding] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [items, setItems] = useState(null);

  const isDragging = useSelector((state) => state.library?.isDragging ?? false);

  const isDayLineItemDraggableDisabled = useSelector(
    (state) => state.library?.itinerary?.isDayLineItemDraggableDisabled ?? false
  );

  //TODO: figure out the hardcoded values
  const [serviceFormData, setServiceFormData] = useState({
    tripid: storedTripData?.tripid,
    confirmationnumber: 'ABC-123',
    title: '',
    description: '',
    unit: 'Passengers',
    qty: 1,
    unitprice: 1000,
    email: currentUser?.email,
    cityid: 3165,
    vendorname: 'American Airlines',
    tripdayid: selectedDay?.tripdayid
  });

  //Manages the logic when 'Edit" option is clicked from a service card kebad menu
  const onEditDayService = async (tripDayId, lineItemId) => {
    const tripDay = storedTripData?.tripday?.find(
      (day) => day.tripdayid === tripDayId
    );

    if (tripDay) {
      const lineItem = tripDay.lineitem.find(
        (item) => item.lineitemid === lineItemId
      );

      if (lineItem) {
        setServiceFormData((prev) => ({
          ...prev,
          title: lineItem?.title,
          description: lineItem?.description,
          lineItemId,
          lineitemimage:
            lineItem?.lineitemimage && lineItem?.lineitemimage.length
              ? lineItem?.lineitemimage[lineItem?.lineitemimage.length - 1]
              : null,
          tripdayid: selectedDay?.tripdayid
        }));

        setSelectedServiceId(lineItem?.servicetype?.servicetypeid);
      }

      dispatch(
        setEditingService({ tripdayid: tripDayId, lineitemid: lineItemId })
      );
    }
  };

  //Clean up service form data and state
  const cleanUpServiceForm = () => {
    setServiceFormData((prevData) => ({
      ...prevData,
      title: '',
      description: '',
      lineItemId: null,
      lineitemimage: [],
      imageUrl: null
    }));

    setIsServiceFormAdding(false);

    dispatch(
      setEditingService({
        tripdayid: selectedDay.tripdayid,
        lineitemid: null
      })
    );
  };

  //Handles submission when adding a day service
  const handleAddServiceSubmit = async (event, customFields) => {
    try {
      preventBubbling(event);
      setIsSubmitting(true);

      const payload = {
        ...serviceFormData,
        tripdayid: selectedDay?.tripdayid,
        servicetypeid: selectedServiceId,
        ...customFields
      };

      const { data, error } = await addServiceToTripDay(payload);

      if (data && !error) {
        const serviceTypeObj = services.find(
          (service) => service.servicetypeid === data.servicetypeid
        );

        dispatch(
          addService({
            tripdayid: selectedDay?.tripdayid,
            ...data,
            lineitemimage: [
              {
                lineitemimageid: 0,
                seq: 1,
                imageurl: serviceFormData.imageUrl
              }
            ],
            servicetype: serviceTypeObj
          })
        );

        cleanUpServiceForm();
        setIsServiceFormAdding(false);
      } else {
        ErrorToast(t('errors:serviceCouldNotBeCreated'));
      }
      setIsServiceFormAdding(false);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  //Handles submission when editing a day service
  const handleEditServiceSubmit = async (event, customFields) => {
    try {
      preventBubbling(event);
      setIsSubmitting(true);

      const payload = {
        title: serviceFormData?.title,
        description: serviceFormData?.description,
        servicetypeid: selectedServiceId,
        ...customFields
      };

      const { data, error } = await updateTripDayService(
        serviceFormData?.lineItemId,
        payload
      );

      if (data && !error) {
        setIsSubmitting(false);

        const updatedLineItem = {
          tripdayid: selectedDay?.tripdayid,
          lineitemid: serviceFormData?.lineItemId,
          servicetypeid: selectedServiceId,
          title: data?.title,
          description: data?.description
        };
        const serviceTypeObj = services.find(
          (service) => service.servicetypeid === selectedServiceId
        );

        dispatch(
          updateDayService({
            ...updatedLineItem,
            servicetype: serviceTypeObj
          })
        );

        cleanUpServiceForm();
      } else {
        setIsSubmitting(false);
        ErrorToast(t('errors:serviceCouldNotBeUpdated'));
      }
    } catch (err) {
      setIsSubmitting(false);
      ErrorToast(t('errors:serviceCouldNotBeUpdated'));
    }
  };

  //Manages the logic when 'Delete" option is clicked from a service card kebad menu
  const onDeleteDayService = async (tripDayId, lineItemId) => {
    setIsDeletingServiceModal(true);
    setDeleteServiceItem({
      tripId: storedTripData.tripid,
      tripDayId,
      lineItemId: lineItemId
    });
  };

  //General event handler to manage events from service cards.
  const handleOnServiceAction = async (event, action, lineItemId) => {
    preventBubbling(event);

    switch (action) {
      case actionTypes.EDIT:
        onEditDayService(selectedDay?.tripdayid, lineItemId);
        break;
      case actionTypes.DELETE:
        onDeleteDayService(selectedDay?.tripdayid, lineItemId);
        break;
      default:
        break;
    }
  };

  //Handles the selected service type when adding or editing
  const handleOnServiceItemClick = (event, selected) => {
    preventBubbling(event);
    setSelectedServiceId(selected);
  };

  const setDefaultServiceType = () => {
    if (services && services.length > 0) {
      const defaultSelectedService = services.find(
        (service) => service.servicetypecode === 'HTL'
      );
      setSelectedServiceId(defaultSelectedService?.servicetypeid);
    }
  };

  const handleServiceFormCancel = (e) => {
    preventBubbling(e);
    cleanUpServiceForm();
    setDefaultServiceType();
  };

  //Manages the logic when 'Add service" button is clicked
  const handleOnAddService = async (event) => {
    preventBubbling(event);
    cleanUpServiceForm();
    setDefaultServiceType();
    setIsServiceFormAdding(true);
  };

  //Gets list of items for the Drag and Drop components
  const getLineItems = (list = []) => {
    const mappedItems = list.map((item, index) => {
      const custom = {
        id: `id-${index}`,
        lineitemid: item.lineitemid,
        seq: item?.seq,
        content: (
          <>
            <ServiceCard
              isEditing={item?.isEditing}
              key={item.lineitemid}
              id={`${item.lineitemid}-service-card`}
              data={item}
              onAction={handleOnServiceAction}
            />

            <ServiceForm
              style={{ display: `${item?.isEditing ? 'block' : 'none'}` }}
              id={`service-form-edit${item?.lineitemid}`}
              isServiceEditing={true}
              selectedServiceId={selectedServiceId}
              handleServiceItemClick={handleOnServiceItemClick}
              handleServiceFormCancel={handleServiceFormCancel}
              handleServiceFormSubmit={handleEditServiceSubmit}
              serviceFormData={serviceFormData}
              updateServiceFormData={setServiceFormData}
              isSubmitting={isSubmitting}
            />
          </>
        )
      };

      return custom;
    });

    return mappedItems;
  };

  const onDragStart = (data) => {
    const { source } = data;

    dispatch(addIsDragging({ isDragging: true }));

    if (source?.droppableId == TRIP_DAY_LINEITEM_DROPPABLE_ID) {
      dispatch(
        addDraggableStatus({
          context: 'itinerary',
          data: {
            isTripDaysDraggableDisabled: true,
            isDayContentDraggableDisabled: true,
            isLibraryDraggableDisabled: true,
            isDayLineItemDraggableDisabled: false
          }
        })
      );
    }
  };

  //Handles actions when an element is dropped
  const onDragEnd = async (result) => {
    dispatch(addIsDragging({ isDragging: false }));
    dispatch(cleanDraggableStatus());

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const organizedItems = reorderItems(
      items,
      result.source.index,
      result.destination.index
    );

    const lineItemsToUpdate = organizedItems.map((item, index) => {
      return { lineitemid: item?.lineitemid, seq: index + 1 };
    });

    const payload = { sequences: lineItemsToUpdate };

    setItems(organizedItems);

    const { data } = await updateLineItemsSequence(payload);

    if (data) {
      dispatch(
        updateServicesSequence({
          tripdayid: selectedDay?.tripdayid,
          lineitems: lineItemsToUpdate || []
        })
      );
    } else {
      ErrorToast(t('errors:serviceCouldNotBeUpdated'));
      setItems(items);
    }
  };

  //On react 18 we need to load Droppable element after the component is mounted otherwise it won't work
  //See: https://github.com/atlassian/react-beautiful-dnd/issues/2399
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const lineItemList = sortByProperty([...(lineitem || [])], 'seq');
    const calculatedLocalState = getLineItems(lineItemList);

    if (calculatedLocalState) {
      setItems(calculatedLocalState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, serviceFormData, selectedServiceId, isSubmitting, tripdayid]);

  return (
    <Box id={id} {...rest}>
      <Box className='day-header-service-cards-container'>
        {isDragging && isDayLineItemDraggableDisabled && (
          <NotAllowedToDrop id={'line-item-not-allowed-to-drop'} />
        )}
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          {isMounted && items ? (
            <Droppable
              droppableId={TRIP_DAY_LINEITEM_DROPPABLE_ID}
              isDropDisabled={isDayLineItemDraggableDisabled}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`droppable-container ${snapshot.isDraggingOver ? 'droppable-container--dragging-over' : ''}`}
                >
                  <DraggableList items={items} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <></>
          )}
        </DragDropContext>
      </Box>
      {!isServiceFormAdding && (
        <Box sx={{ width: '100%' }}>
          <XGButton
            sx={{ width: '100%' }}
            id='detailed-view-add-service-button'
            data-testid='detailed-view-add-service-button'
            text={t('addService')}
            type='primary'
            size='large'
            variant='contained'
            className='add-day-button'
            endIcon={
              <Box className='add-day-icon'>
                <Plus color='#98A2B3' size={16} />
              </Box>
            }
            onClick={handleOnAddService}
          />
        </Box>
      )}

      {deleteServiceItem && isDeletingServiceModal && (
        <DeleteService
          data={deleteServiceItem}
          id='delete-day-service-component'
          isOpenModal={isDeletingServiceModal}
          updateIsOpen={(isOpenModal) => {
            setIsDeletingServiceModal(isOpenModal);
          }}
        />
      )}

      {isServiceFormAdding && (
        <ServiceForm
          id='service-form-add-service'
          isServiceEditing={false}
          isSubmitting={isSubmitting}
          selectedServiceId={selectedServiceId}
          handleServiceItemClick={handleOnServiceItemClick}
          handleServiceFormCancel={handleServiceFormCancel}
          handleServiceFormSubmit={handleAddServiceSubmit}
          serviceFormData={serviceFormData}
          updateServiceFormData={setServiceFormData}
        />
      )}
    </Box>
  );
};

DetailedDayLineItems.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  onAction: PropTypes.func,
  editingServiceId: PropTypes.number
};

export default DetailedDayLineItems;
