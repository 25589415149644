//@ts-nocheck
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import './BoxedIcon.css';

/**
 * Functional React component for rendering a custom boxed icon.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {JSX.Element} [props.icon] - The icon element to display in the boxed icon.
 * @param {string} [props.id] - The ID for the login header element.
 *
 * @returns {JSX.Element} React element representing the boxed icon.
 */

const BoxedIcon = ({ icon, id, ...rest }) => {
  return (
    <Box
      className={`boxed-icon ${rest.className}`}
      data-testid='boxed-icon'
      id={id}
    >
      {icon}
    </Box>
  );
};

/**
 * Prop type definitions for BoxedIcon.
 */
BoxedIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired
};

export default BoxedIcon;
