//@ts-nocheck

/**
 * Prevents event bubbling and default action.
 * @param {Event} event - The event object
 */
export const preventBubbling = (event) => {
  event.stopPropagation();
  event.preventDefault();
};
