// @ts-nocheck
import PassengersGenderOptions from '../PassengersGenderOptions/PassengerGenderOptions';
import PassengersCheckBoxPanel from '../../../Booking/BookingPassengers/PassengersCheckBoxPanel/PassengersCheckBoxPanel';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from '@mui/material';
import { XGAutocomplete, XGButton, XGInput, XGDatePicker } from '@xg-cl/xg-cl';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePassengerById } from '../../../../redux/slices/passengers/passengers';
import { updatedPassenger } from '../../../../services/booking/booking';
import { ErrorToast } from '../../../../utils/alerts';
import { getCountries } from '../../../../services/country/country';
import {
  hotelRequests,
  dietaryRestrictions
} from '../../../../constants/passengers';
import * as PropTypes from 'prop-types';
import './PassengersUpdateModal.css';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';
import { MuiTelInput } from 'mui-tel-input';

const PassengersUpdateModal = ({
  open,
  setOpen,
  passengerId,
  data,
  columnsLabels
}) => {
  const { t } = useTranslation(['passengers', 'common', 'trip']);
  const [passengerFields, setPassengersFields] = useState(data);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const shouldGetCountries =
    Object.hasOwn({ ...data }, 'country') ||
    Object.hasOwn({ ...data }, 'nationality');
  const [inputChanged, setInputChanged] = useState(false);

  const handleChange = (event) => {
    setInputChanged(true);
    const { name, value } = event.target;
    setPassengersFields((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeList = (name, value) => {
    const updatedPassengerFields = {
      ...passengerFields,
      [name]: value
    };
    delete updatedPassengerFields.id;
    delete updatedPassengerFields.bookingid;
    dispatch(updatePassengerById({ ...updatedPassengerFields }));
    updatedPassenger(passengerId, updatedPassengerFields);
    setPassengersFields(updatedPassengerFields);
  };

  const handleBlur = async () => {
    if (inputChanged) {
      const passengerObj = { ...passengerFields };
      delete passengerObj.id;
      delete passengerObj.bookingid;

      dispatch(updatePassengerById({ ...passengerFields }));
      const result = await updatedPassenger(passengerId, passengerObj);

      if (result.error || !result.data) {
        ErrorToast(t('errors:passengerCouldNotBeUpdated'));
      }
    }
    setInputChanged(false);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const getOptionByCode = (code = '') => {
    const foundCountry = countries?.find(
      (country) => country?.countrycode === code
    );

    return foundCountry ? foundCountry : null;
  };

  useEffect(() => {
    let isMounted = true;

    const fetchCountries = async () => {
      try {
        const response = await getCountries();
        const countriesList = response?.data || [];

        if (response && response.data.length) {
          const mappedCountries = countriesList.map((country) => ({
            ...country,
            title: country?.countryname
          }));

          if (isMounted) {
            setCountries(mappedCountries);
          }
        }
      } catch (error) {
        if (isMounted) {
          setCountries([]);
        }
      }
    };

    if (shouldGetCountries) {
      fetchCountries();
    }

    return () => {
      isMounted = false;
    };
  }, [shouldGetCountries]);

  useEffect(() => {
    setPassengersFields(data);
  }, [data]);

  return (
    <Modal open={open} onClose={setOpen}>
      <Box className='passenger-modal-box'>
        <div className='passenger-modal-box__header'>
          <h3>{t('passengers:editPassenger')}</h3>
        </div>
        <div className='passenger-fields-container'>
          {passengerFields &&
            Object.keys(passengerFields).length > 0 &&
            Object.entries(passengerFields).map(([key, value]) => {
              const columnObj = columnsLabels.find((item) => item.field == key);
              return key !== 'id' ? (
                key == 'phone' ? (
                  <div
                    key={key}
                    className='passenger-field'
                    onBlur={handleBlur}
                  >
                    <label>{columnObj?.headerName}:</label>
                    <MuiTelInput
                      className='passengersUpdated-input-phone'
                      value={value}
                      name={key}
                      id='passengersUpdated-input-phone-id'
                      onChange={(newValue) => {
                        handleChange({
                          target: { name: key, value: newValue }
                        });
                      }}
                      defaultCountry='US'
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                      }}
                    />
                  </div>
                ) : key === 'passportexpirationdate' ||
                  key === 'passportissuedate' ||
                  key === 'birthdate' ? (
                  <div className='passenger-field' key={key}>
                    <XGDatePicker
                      id='passengersUpdate-datePicker'
                      format={`${t('common:dateFormat')}`}
                      label={columnObj?.headerName}
                      value={new Date(value)}
                      onChange={(value) => {
                        const formattedDate = formatDate(
                          utcToZonedTime(new Date(value), 'UTC'),
                          'dd MMM yyyy',
                          { timeZone: 'UTC' }
                        );
                        handleChangeList(key, formattedDate);
                      }}
                    />
                  </div>
                ) : key === 'country' || key === 'nationality' ? (
                  <div className='w-100 passenger-field' key={key}>
                    <XGAutocomplete
                      className='autocomplete'
                      disableClearable={false}
                      id={`passenger-${key}-input`}
                      value={getOptionByCode(value)}
                      placeholder={t('trip:searchCountry')}
                      name={key}
                      label={columnObj?.headerName}
                      options={countries}
                      isOptionEqualToValue={(option, valuex) =>
                        option.title === valuex?.title
                      }
                      onChange={(event, newValue) => {
                        const { countrycode } = newValue || {};
                        handleChangeList(key, countrycode);
                      }}
                    />
                  </div>
                ) : key === 'dietaryrestrictions' || key === 'hotelrequests' ? (
                  <div className='w-100 passenger-field' key={key}>
                    <PassengersCheckBoxPanel
                      id={`passenger-${key}-input`}
                      field={{ label: columnObj?.headerName, fieldId: key }}
                      options={
                        key === 'dietaryrestrictions'
                          ? dietaryRestrictions
                          : hotelRequests
                      }
                      selectedOptions={value ? value?.split(',') : []}
                      handleOnChange={(...args) => {
                        handleChangeList(key, args[2]);
                      }}
                    />
                  </div>
                ) : key === 'gender' ? (
                  <div key={key}>
                    <label>{columnObj?.headerName}:</label>
                    <PassengersGenderOptions
                      gender={value}
                      handleChange={handleChangeList}
                    />
                  </div>
                ) : (
                  <div key={key} className='passenger-field'>
                    <label>{columnObj?.headerName}:</label>
                    <XGInput
                      id={`passenger-${key}-input`}
                      className='passenger-update-modal-input'
                      name={key}
                      type='text'
                      value={value ?? ''}
                      disabled={key === 'bookingid'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                )
              ) : null;
            })}
        </div>
        <div className='share-dialog-close-button'>
          <XGButton
            id='dialog-close-button'
            text={t('common:close')}
            type='secondary'
            size='large'
            variant='outlined'
            onClick={handleClose}
          />
        </div>
      </Box>
    </Modal>
  );
};

PassengersUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  passengerId: PropTypes.number,
  data: PropTypes.object,
  columnsLabels: PropTypes.array
};

export default PassengersUpdateModal;
