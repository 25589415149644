// @ts-nocheck
import { Draggable } from 'react-beautiful-dnd';
import * as PropTypes from 'prop-types';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: '#ffffff',
  borderRadius: '12px',
  border: isDragging ? '2px solid #98C6F6' : '',
  ...draggableStyle
});

/**
 * Functional React component for rendering a custom draggable element.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {Object} props.item - Data containing information for the element.
 * @param {string} props.id - The unique identifier for the component.
 *
 * @returns {JSX.Element} - The rendered draggable component.
 */

const DraggableElement = ({ item, index }) => {
  return (
    <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {item.content}
        </div>
      )}
    </Draggable>
  );
};

/**
 * Prop type definitions for DraggableElement.
 */
DraggableElement.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number
};

export default DraggableElement;
