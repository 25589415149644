// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const emptyPlan = {
  tripplanid: null,
  title: '',
  description: '',
  priceperperson: 0,
  occupancy: 0,
  maxavailable: null,
  depositamount: 0,
  depositduedate: '',
  lastbookdate: '',
  finalpaymentduedate: '',
  selectedQuantity: 0
};

const initialState = {
  bookingplans: [emptyPlan],
  passengerForms: [],
  tenant: {},
  trip: {},
  steps: [
    {
      id: 1,
      active: true,
      title: 'Choose a plan',
      key: 'plan',
      shouldRender: true,
      message: ''
    },
    {
      id: 2,
      active: false,
      title: 'Passengers',
      key: 'passenger',
      shouldRender: true,
      message: ''
    },
    {
      id: 3,
      active: false,
      title: 'Complete payment',
      key: 'payment',
      shouldRender: true,
      message: ''
    },
    {
      id: 4,
      active: false,
      title: 'Payment successful',
      key: 'success',
      shouldRender: false,
      message: ''
    }
  ],
  bookingTotals: []
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    addBooking: (state, action) => {
      const { bookingplans, tenant, trip } = action.payload;

      return {
        ...state,
        bookingplans: bookingplans || [],
        tenant,
        trip
      };
    },
    setUniqueSelectedQuantity: (state, action) => {
      const { tripplanid, quantity } = action.payload;

      const newBookingPlans = state.bookingplans.map((bookingplan) => {
        if (bookingplan.tripplanid === tripplanid) {
          return {
            ...bookingplan,
            selectedQuantity: quantity,
            isSelected: true
          };
        } else {
          return { ...bookingplan, selectedQuantity: 0, isSelected: false };
        }
      });

      return {
        ...state,
        bookingplans: newBookingPlans
      };
    },

    setSelectedQuantity: (state, action) => {
      const { tripplanid, quantity } = action.payload;

      const newBookingPlans = state.bookingplans.map((bookingplan) => {
        if (bookingplan.tripplanid === tripplanid) {
          return { ...bookingplan, selectedQuantity: quantity };
        } else {
          return { ...bookingplan };
        }
      });

      return {
        ...state,
        bookingplans: newBookingPlans
      };
    },
    updateActiveStep: (state, action) => {
      const { key, message = '' } = action.payload;

      const newBookingSteps = state.steps.map((step) => {
        if (step.key === key) {
          return {
            ...step,
            active: true,
            message
          };
        } else {
          return {
            ...step,
            active: false,
            message: ''
          };
        }
      });

      return {
        ...state,
        steps: newBookingSteps
      };
    },
    clearBooking: () => {
      return initialState;
    },

    updatePassengerForms: (state, action) => {
      const { passengerForms } = action.payload;
      return {
        ...state,
        passengerForms
      };
    },

    updatePassengerField: (state, action) => {
      const { formId, fieldId, newValue, error = null } = action.payload;

      const newPassengerForms = state.passengerForms.map(
        (passengerForm = {}) => {
          if (passengerForm?.formId === formId) {
            return {
              ...passengerForm,

              fields: passengerForm?.fields?.map((field) => {
                if (field.fieldId === fieldId) {
                  return {
                    ...field,
                    value: newValue,
                    error
                  };
                } else {
                  return field;
                }
              })
            };
          } else {
            return passengerForm;
          }
        }
      );

      return {
        ...state,
        passengerForms: newPassengerForms
      };
    },

    updateFormErrors: (state, action) => {
      const { forms = [] } = action.payload;

      const newPassengerForms = state.passengerForms.map(
        (passengerForm = {}) => {
          const foundForm = forms?.find(
            (form) => form?.formId === passengerForm?.formId
          );

          if (foundForm) {
            const errors = foundForm?.errors || [];

            return {
              ...passengerForm,
              fields: passengerForm?.fields?.map((field) => {
                const foundError = errors?.find(
                  (error) => error?.fieldId === field?.fieldId
                );
                return {
                  ...field,
                  error: foundError?.error
                };
              })
            };
          } else {
            return passengerForm;
          }
        }
      );

      return {
        ...state,
        passengerForms: newPassengerForms
      };
    },

    resetFieldErrors: (state) => {
      const newPassengerForms = state.passengerForms.map(
        (passengerForm = {}) => {
          return {
            ...passengerForm,
            fields: passengerForm?.fields?.map((field) => {
              return {
                ...field,
                error: null
              };
            })
          };
        }
      );
      return {
        ...state,
        passengerForms: newPassengerForms
      };
    },

    ///Totals
    removeTotalItem: (state, action) => {
      const { totalKey } = action.payload;
      const newTotals = state.bookingTotals.filter(
        (total) => total.key !== totalKey
      );
      return {
        ...state,
        bookingTotals: newTotals
      };
    },
    addTotalItem: (state, action) => {
      const { totalItem } = action.payload;

      return {
        ...state,
        bookingTotals: [...state.bookingTotals, totalItem]
      };
    },
    addTotalItems: (state, action) => {
      const { totalItems = [] } = action.payload;

      return {
        ...state,
        bookingTotals: totalItems
      };
    },

    updateTotalItem: (state, action) => {
      const { totalKey, totalItem } = action.payload;

      const newTotals = state.bookingTotals.map((total) => {
        if (total.key === totalKey) {
          return { ...total, ...totalItem };
        } else {
          return total;
        }
      });
      return {
        ...state,
        bookingTotals: newTotals
      };
    },
    resetTotals: (state) => {
      return {
        ...state,
        bookingTotals: []
      };
    }
  }
});

const { actions, reducer } = bookingSlice;

export const {
  addBooking,
  clearBooking,
  setSelectedQuantity,
  setUniqueSelectedQuantity,
  updateActiveStep,
  updatePassengerForms,
  updatePassengerField,
  resetFieldErrors,
  updateFormErrors,
  removeTotalItem,
  addTotalItem,
  addTotalItems,
  updateTotalItem,
  resetTotals
} = actions;

export default reducer;
