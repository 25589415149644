import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    tenantId: 0,
    countryId: 0,
    countryName: '',
    countryCode: '',
    cityId: 0,
    cityCode: '',
    cityName: '',
    description: ''
  }
];

const destinationSlice = createSlice({
  name: 'destinations',
  initialState,
  reducers: {
    updateDestinations: (state, action) => {
      return action.payload;
    }
  }
});

const { actions, reducer } = destinationSlice;

export const { updateDestinations } = actions;

export default reducer;
