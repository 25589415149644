import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    tripstatusid: 1000,
    tripstatusname: '',
    description: '',
    tripstatuscode: ''
  }
];

const tripStatusesSlice = createSlice({
  name: 'tripstatuses',
  initialState,
  reducers: {
    updateStatuses: (state, action) => {
      return action.payload;
    }
  }
});

const { actions, reducer } = tripStatusesSlice;

export const { updateStatuses } = actions;

export default reducer;
