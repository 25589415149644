//@ts-nocheck
import CallToAction from '../components/MainHeader/CallToAction/CallToAction';
import Title from '../components/MainHeader/Title/Title';
import { XGDropdownMenu } from '@xg-cl/xg-cl';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useTranslation } from 'react-i18next';
import { isValidElement } from 'react';

/**
 * Function that builds the header for the Shell Layout
 *
 * @namespace Utils
 *
 * @param {Object} params - The component's properties
 * @param {Object} [params.title] - Object with properties title, chipLabel and chipType
 * @param {Array} [params.cta] - Array of objects or JSX content with all the actions asociated with the page.
 *
 * @returns {Object} Object with a header value
 */

const shellHeaderBuilder = ({ title, cta }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation(['common']);

  return {
    header: {
      title:
        title && isValidElement(title) ? (
          <>{title && title}</>
        ) : (
          <Title
            id={`title`}
            title={title.title}
            chipLabel={title.chipLabel}
            chipType={title.chipType}
          />
        ),
      cta:
        cta && Array.isArray(cta) ? (
          <CallToAction id='shell-header-builder'>
            <XGDropdownMenu
              isButton={true}
              btnType='primary'
              btnVariant='contained'
              btnSize='large'
              btnText={t('actions')}
              btnStartIcon={<AutoFixHighIcon />}
              options={cta}
              id='xg-dropdown-menu-1'
            />
          </CallToAction>
        ) : (
          <>{cta && cta}</>
        )
    }
  };
};

export default shellHeaderBuilder;
