//@ts-nocheck
import { Box } from '@mui/material';
import './PassengerFieldsPanel.css';
import * as PropTypes from 'prop-types';
import PassengerFieldsTable from './PassengersFieldsTable';

const PassengerFieldsPanel = ({ id }) => {
  return (
    <Box
      id={id}
      className='passenger-fields-panel'
      data-testid='passenger-fields-panel'
    >
      <PassengerFieldsTable />
    </Box>
  );
};

PassengerFieldsPanel.propTypes = {
  id: PropTypes.string
};

export default PassengerFieldsPanel;
