// @ts-nocheck
import Box from '@mui/material/Box';
import './ShellSkeleton.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom skeleton for the shell.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 *
 * @returns {JSX.Element} - The rendered skeleton for the shell.
 */

const ShellSkeleton = ({ id }) => {
  return (
    <Box id={id} className='shell-skeleton' data-testid='shell-skeleton'>
      <Box className='shell-ribbon' />
      <Box className='nav-menu-bar' />
      <Box
        className='shell-content shell-skeleton__content'
        data-testid='shell-content'
      >
        <Box className='shell-content-wrapper shell-skeleton__content-wrapper' />
      </Box>
      <Box className='shell-footer-wrapper shell-skeleton__footer-wrapper' />
    </Box>
  );
};

/**
 * Prop type definitions for ShellSkeleton.
 */
ShellSkeleton.propTypes = {
  id: PropTypes.string.isRequired
};

export default ShellSkeleton;
