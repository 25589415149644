// @ts-nocheck
import Services from '../Services/Services';
import Editor from '../../Editor/Editor';
import ImagePicker from '../../../components/Upload/ImagePicker/ImagePicker';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { XGButton, XGInput } from '@xg-cl/xg-cl';
import { blue } from '@mui/material/colors';
import { addTripDayItemImage } from '../../../services/trip/trip';
import { updateDayServiceImage } from '../../../redux/slices/trip/trip';
import { useState } from 'react';
import * as PropTypes from 'prop-types';
import './ServiceForm.css';

/**
 * Functional React component for rendering a custom service form component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Number} [props.id] - The ID for the edit day item component.
 * @param {Boolean} [props.isServiceEditing] - Boolean value indicating whether the form is in editing mode.
 * @param {Boolean} [props.isSubmitting] - Boolean value indicating whether the form is submitting.
 * @param {Number} [props.selectedServiceId] - The ID of the selected service.
 * @param {Function} [props.handleServiceItemClick] - A callback function to handle service changing.
 * @param {Function} [props.handleServiceFormCancel] - A callback function to handle form closing.
 * @param {Function} [props.handleServiceFormSubmit] - A callback function to handle form submitting.
 * @param {Object} [props.serviceFormData] - The day service data lo load in the form.
 * @param {Function} [props.updateServiceFormData] - A function to update service form state.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the service form component.
 */

const ServiceForm = ({
  id,
  isServiceEditing,
  isSubmitting,
  selectedServiceId,
  handleServiceItemClick,
  handleServiceFormCancel,
  handleServiceFormSubmit,
  serviceFormData,
  updateServiceFormData,
  ...rest
}) => {
  const [markdown, setMarkdown] = useState(serviceFormData.description || '');
  const [isImageUploading, setIsImageUploading] = useState(false);
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const services = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const { lineitemimage } = serviceFormData;

  const updateServiceCoverImage = async (newImageUrl) => {
    if (isServiceEditing) {
      const { data: dayData } = await addTripDayItemImage(
        serviceFormData?.lineItemId,
        {
          imageUrl: newImageUrl
        }
      );

      if (dayData) {
        const { lineitemimageid, seq, imageurl } = dayData;

        dispatch(
          updateDayServiceImage({
            tripdayid: serviceFormData?.tripdayid,
            lineitemid: serviceFormData?.lineItemId,
            lineitemimage: [
              {
                lineitemimageid,
                seq,
                imageurl
              }
            ]
          })
        );

        return { data: true };
      } else {
        return { data: false };
      }
    }

    updateServiceFormData((prev) => ({
      ...prev,
      imageUrl: newImageUrl
    }));

    return { data: true };
  };

  const handleImageUploading = (value = false) => {
    setIsImageUploading(value);
  };

  return (
    <div
      data-testid='service-form-component'
      id={id}
      className={`service-form ${
        isServiceEditing ? 'service-form--editing' : ''
      }`}
      {...rest}
    >
      <div className={`service-form__image-cover`}>
        <ImagePicker
          callback={updateServiceCoverImage}
          id={`image-picker-component`}
          title={!isServiceEditing ? t('addService') : t('editService')}
          url={lineitemimage?.imageurl}
          handleUploading={handleImageUploading}
        />
      </div>

      <div className='service-form__content'>
        <Services
          serviceTypes={services}
          selectedService={selectedServiceId}
          onClickItem={handleServiceItemClick}
        />

        <Box className='service-form__item'>
          <XGInput
            id='service-form-title-input'
            type='text'
            label={`${t('title')}*`}
            name='default-input'
            placeholder={t('enterTitle')}
            value={serviceFormData.title || ''}
            onChange={(e) =>
              updateServiceFormData((prev) => ({
                ...prev,
                title: e.target.value
              }))
            }
          />
        </Box>

        <Box className='service-form__item'>
          <Editor
            content={serviceFormData.description}
            editable={true}
            id='service-form-description-editor'
            label={t('description')}
            placeholder={t('enterDescription')}
            setDescription={() => {}}
            setMarkdown={setMarkdown}
          />
        </Box>

        <div className='detailed-view__add-service-buttons-container'>
          <Box>
            <XGButton
              id='service-form-cancel-button'
              size='medium'
              type='secondary'
              variant='outlined'
              text={t('common:cancel')}
              style={{
                width: '274px',
                height: '44px',
                marginRight: '12px'
              }}
              onClick={handleServiceFormCancel}
              disabled={isSubmitting}
            />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <XGButton
              id='service-form-submit-button'
              size='medium'
              type='primary'
              variant='contained'
              text={!isServiceEditing ? t('addService') : t('common:save')}
              className={`service-form__button`}
              onClick={(event) =>
                handleServiceFormSubmit(event, { description: markdown })
              }
              disabled={
                isSubmitting ||
                serviceFormData.title.length === 0 ||
                isImageUploading
              }
            />
            {isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  color: blue[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

ServiceForm.propTypes = {
  id: PropTypes.string.isRequired,
  isServiceEditing: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleServiceItemClick: PropTypes.func,
  handleServiceFormCancel: PropTypes.func,
  handleServiceFormSubmit: PropTypes.func,
  updateServiceFormData: PropTypes.func,
  selectedServiceId: PropTypes.number,
  serviceFormData: PropTypes.object
};

export default ServiceForm;
