// @ts-nocheck
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import TenantLogo from '../../TenantLogo/TenantLogo';
import './NavMenuHeader.css';

/**
 * Functional React component for rendering a custom nav menu header.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 * @param {Object} props.data - Data containing information for the nav menu header.
 * @param {string} props.data.logo - The URL of the logo to be displayed in the nav menu header.
 * @param {string} props.data.tenantName - The operator name to put as a fallback when image is not defined.
 * @param {...any} props.rest - Additional props to be spread on the img element.
 *
 * @returns {JSX.Element} - The rendered NavMenuHeader component.
 */

const NavMenuHeader = ({ id, data, ...rest }) => {
  return (
    <Box data-testid='nav-menu-header' id={id} className='nav-menu-header'>
      <Stack justifyContent='center'>
        <TenantLogo
          width='150px'
          tenantLogo={data?.logo}
          tenantName={data?.tenantName}
          {...rest}
        />
      </Stack>
    </Box>
  );
};

/**
 * Prop type definitions for NavMenuHeader.
 */
NavMenuHeader.propTypes = {
  data: PropTypes.object.isRequired, // Ensures data is required
  id: PropTypes.string.isRequired
};

export default NavMenuHeader;
