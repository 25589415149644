//@ts-nocheck
import AuthHeader from '../../components/AuthHeader/AuthHeader';
import Box from '@mui/material/Box';
import BoxedIcon from '../../components/BoxedIcon/BoxedIcon';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DottedPaginator from './../../components/DottedPaginator/DottedPaginator';
import { useEffect, useState } from 'react';
import { XGButton, XGInput } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import { LockKey } from '@phosphor-icons/react';
import './SetNewPassword.css';
import globalConfig from '../../config/globalConfig';
import { isValidPassword } from './../../utils/passwordValidator';
import { getAccessToken, updatePassword } from './../../services/auth/auth';
import { useParams, useNavigate } from 'react-router-dom';
import { ErrorToast } from '../../utils/alerts';

/**
 * This is the set new password page component.
 *
 * @namespace Pages
 */

const SetNewPassword = ({ id }) => {
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [formData, setFormData] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { password, confirm } = formData;

    try {
      if (!areFieldsValid()) {
        return;
      }

      if (!resetToken || resetToken === '') {
        ErrorToast('Password could not be updated, please try again.');
        navigate('/forgot-password/error');
        return;
      }

      const response = await updatePassword(resetToken, {
        newPassword: password,
        newPasswordConfirmation: confirm
      });

      if (!response || response.error) {
        navigate('/forgot-password/error');
        return;
      }

      navigate('/forgot-password/success');
    } catch (err) {
      navigate('/forgot-password/error');
    }
  };

  const areFieldsValid = () => {
    const { password, confirm } = formData;

    if (password.trim() === '') {
      setPasswordError('Password is required');
      return false;
    }

    if (confirm.trim() === '') {
      setConfirmPasswordError('Confirm password is required');
      return false;
    }

    if (!isValidPassword(password.trim())) {
      setPasswordError('Password must contain one special character');
      return false;
    }

    if (password.trim().length < globalConfig.maxPasswordLength) {
      setPasswordError(
        `Password must be at least ${globalConfig.maxPasswordLength} characters`
      );
      return false;
    }
    if (password.trim() !== confirm.trim()) {
      setConfirmPasswordError('Password and confirm password must match');
      return false;
    }

    return true;
  };

  const didLengthMatch =
    formData.password.trim().length >= globalConfig.maxPasswordLength;
  const validPassword = isValidPassword(formData.password.trim());

  const handlePasswordOnFocus = () => {
    setPasswordError('');
  };

  const handleConfirmPasswordOnFocus = () => {
    setConfirmPasswordError('');
  };

  useEffect(() => {
    const validateToken = async () => {
      const response = await getAccessToken(resetToken);

      if (
        response.error ||
        !response.data?.passwordResetToken ||
        !response.data?.expiration
      ) {
        navigate('/forgot-password/error');
      }
    };
    validateToken();
  }, [resetToken, navigate]);

  return (
    <div id={id} data-testid='set-new-password-page'>
      <AuthHeader
        id='set-new-password-header'
        icon={
          <BoxedIcon
            id='set-new-boxed-icon'
            icon={<LockKey color='#344054' size={28} />}
          />
        }
        text='Your new password must be different to previously used passwords.'
        title='Set new password'
      />
      <Card
        data-testid='set-new-password-page-content'
        sx={{
          padding: '32px 40px 32px 40px',
          borderRadius: '12px',
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '20px',
              alignSelf: 'stretch'
            }}
          >
            <XGInput
              error={passwordError !== ''}
              hint={passwordError}
              icon={passwordError !== ''}
              id='password-set-new-input'
              label='Password*'
              name='password'
              onChange={handleChange}
              onFocus={handlePasswordOnFocus}
              placeholder='Enter your password'
              type='password'
              value={formData.password}
            />
            <XGInput
              error={confirmPasswordError !== ''}
              hint={confirmPasswordError}
              icon={confirmPasswordError !== ''}
              id='password-confirm-input'
              label='Confirm password*'
              name='confirm'
              onChange={handleChange}
              onFocus={handleConfirmPasswordOnFocus}
              placeholder='Confirm your password'
              type='password'
              value={formData.confirm}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              padding: '24px 0px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '20px',
              alignSelf: 'stretch'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',
                marginBottom: '4px'
              }}
            >
              <div
                className='password-instructions'
                data-testid='set-new-password-instructions'
              >
                <CheckCircleIcon
                  className={
                    'password-instructions-icon ' +
                    (didLengthMatch
                      ? 'password-instructions-icon--success'
                      : '')
                  }
                />
                <p className='text-sm-regular'>
                  Must be at least {globalConfig.maxPasswordLength} characters
                </p>
              </div>
              <div className='password-instructions'>
                <CheckCircleIcon
                  className={
                    'password-instructions-icon ' +
                    (validPassword ? 'password-instructions-icon--success' : '')
                  }
                />
                <p className='text-sm-regular'>
                  Must contain one special character
                </p>
              </div>
            </Box>
          </Box>
        </CardContent>
        <CardActions
          sx={{ width: '100%', '& button': { width: '100%' }, padding: 0 }}
        >
          <XGButton
            data-testid='set-new-password-submit-button'
            id='button-set-new-password'
            text='Reset password'
            type='primary'
            size='large'
            variant='contained'
            onClick={handleSubmit}
          />
        </CardActions>
      </Card>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 0px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '32px',
          alignSelf: 'stretch',
          justifyContent: 'center'
        }}
      >
        <XGButton
          href='/login'
          id='set-new-password-back-to-login'
          text='Back to log in'
          type='primary'
          style={{ padding: '0px' }}
          size='medium'
          variant='text'
        />
        <DottedPaginator
          dots={4}
          id='set-new-password-paginator'
          selected={3}
        />
      </Box>
    </div>
  );
};

/**
 * Prop type definitions for SetNewPassword.
 */
SetNewPassword.propTypes = {
  id: PropTypes.string.isRequired
};

export default SetNewPassword;
