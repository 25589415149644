// @ts-nocheck
import { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Functional React component for fetching and loading specified Google Fonts.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {Function} props.children - The render prop to pass the fonts data.
 * @param {Array<Object>} props.fonts - The list of fonts to fetch and load.
 *
 * @returns {JSX.Element} - The rendered component.
 */
const FontDataConfig = ({ children, fonts }) => {
  useEffect(() => {
    // Dynamically load the specified Google Fonts by adding link tags to the head
    const loadFonts = () => {
      const link = document.createElement('link');
      link.href = `https://fonts.googleapis.com/css2?${fonts
        .map(
          ({ family, weights }) =>
            `family=${family.replace(/ /g, '+')}${weights ? `:wght@${weights.join(';')}` : ''}`
        )
        .join('&')}&display=swap`;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    };

    loadFonts();
  }, [fonts]);

  return children(fonts.map((font) => font.family));
};

FontDataConfig.propTypes = {
  children: PropTypes.func.isRequired,
  fonts: PropTypes.arrayOf(
    PropTypes.shape({
      family: PropTypes.string.isRequired,
      weights: PropTypes.arrayOf(PropTypes.number)
    })
  ).isRequired
};

export default FontDataConfig;
