//@ts-nocheck
const mode = import.meta.env.MODE;
const env = import.meta.env;

//Global object for mapping global settings
const globalConfig = {
  isProduction: mode === 'production',
  mode: mode,
  axios: {
    apiUrl: env.VITE_API_URL,
    requestTimeout: env.VITE_REQUEST_TIMEOUT
  },
  maxPasswordLength: env.VITE_MAX_PASSWORD_LENGTH,
  muiLicense: env.VITE_MUI_LICENSE,
  launchDarklyKey: env.VITE_LAUNCH_DARKLY_SDK_KEY
};

export default globalConfig;
