import { createSlice } from '@reduxjs/toolkit';
import { NEW } from './trippricingStatuses';

const emptyPlan = {
  tripplanid: new Date().getTime(),
  title: '',
  description: '',
  priceperperson: 1,
  occupancy: '1',
  maxavailable: null,
  depositamount: null,
  depositduedate: null,
  lastbookdate: null,
  finalpaymentduedate: null,
  error: {},
  selectedradio: 'unlimited',
  status: NEW
};

const initialState = {
  originaltripplans: [],
  tripplans: [emptyPlan]
};

const tripPricingSlice = createSlice({
  name: 'trippricing',
  initialState,
  reducers: {
    addTripPlan: (state) => {
      return {
        ...state,
        tripplans: [
          ...state.tripplans,
          { ...emptyPlan, tripplanid: new Date().getTime() }
        ]
      };
    },
    updateTripPlan: (state, action) => {
      const { tripplanid } = action.payload;

      const newTripPlans = state.tripplans.map((tripplan) => {
        if (tripplan.tripplanid === tripplanid) {
          return { ...tripplan, ...action.payload };
        } else {
          return tripplan;
        }
      });

      return {
        ...state,
        tripplans: newTripPlans
      };
    },
    removeTripPlan: (state, action) => {
      const { tripplanid } = action.payload;

      const newTripPlans = state.tripplans.filter(
        (tripplan) => tripplan.tripplanid !== tripplanid
      );

      return {
        ...state,
        tripplans: newTripPlans
      };
    },

    updateTripPrincing: (state, payload) => {
      return { ...state, ...payload.payload };
    },

    resetErrors: (state) => {
      return {
        ...state,
        tripplans: state.tripplans.map((tripplan) => ({
          ...tripplan,
          error: {}
        }))
      };
    }
  }
});

const { actions, reducer } = tripPricingSlice;

export const {
  addTripPlan,
  updateTripPlan,
  removeTripPlan,
  resetErrors,
  updateTripPrincing
} = actions;

export default reducer;
