// @ts-nocheck
import { Box, Stack } from '@mui/material';
import * as PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import FaviconLogo from '/img/favicon-softrip.png';
import version from '../../config/version';

/**
 * Functional React component for rendering a custom footer.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.id] - The ID for the footer container.
 
 *
 * @returns {JSX.Element} React element representing the footer.
 */

const Footer = ({ id }) => {
  let newYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
  };

  return (
    <Box
      id={id}
      className='footer'
      data-testid='footer'
      display='flex'
      justifyContent='center'
      alignItems='center'
      paddingX='16px'
      width='100%'
      maxHeight='52px'
      backgroundColor='white'
      borderTop='1px solid #EAECF0'
      paddingY='16px'
    >
      <Stack direction='row' spacing={1}>
        <p style={{ color: '#98A2B3' }}>© Softrip</p>
        <Stack justifyContent='center'>
          <img style={{ width: '32px' }} src={FaviconLogo} alt='Logo' />
        </Stack>
        <p style={{ color: '#98A2B3' }}>
          <span>{newYear()}</span> – All rights reserved - {`v${version}`}
        </p>
      </Stack>
    </Box>
  );
};

Footer.propTypes = {
  id: PropTypes.string.isRequired
};

export default Footer;
