import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    servicetypeid: 0,
    servicetypecode: '',
    servicetypename: '',
    seq: 0,
    description: '',
    iconref: '',
    durationunit: '',
    durationoffset: 0
  }
];

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    updateServices: (state, action) => {
      return action.payload;
    }
  }
});

const { actions, reducer } = servicesSlice;

export const { updateServices } = actions;

export default reducer;
