import request from '../request';
import axios from 'axios';

export const getPresignedUrl = (data = {}) =>
  request.post('uploads/presign-url', data).then((res) => res);

export const uploadToBucket = (presignUrl = '', file = {}) => {
  //This setting disables the browser from sending cookies and credentials with cross-site requests
  axios.defaults.withCredentials = false;

  return axios
    .put(presignUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => res);
};
