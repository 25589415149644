// @ts-nocheck
import { Box } from '@mui/material';
import { XGButton } from '@xg-cl/xg-cl';
import { CheckCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import './PaymentSuccessful.css';
import ConfettiAnimation from '../../ConfettiAnimation/ConfettiAnimation';

/**
 * Functional React component for rendering a custom successful message.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID of the component.
 * @param {function} [props.updateIsOpen] - The function to update the open state.
 * @param {string} [props.message] - The message to display.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the successful message.
 */

const PaymentSuccessful = ({ id, updateIsOpen, message, ...rest }) => {
  const { t } = useTranslation(['trip', 'common']);

  const onCloseMessage = () => {
    updateIsOpen(false);
  };

  return (
    <Box id={id} data-testid='booking-payment-successful' {...rest}>
      <ConfettiAnimation />
      <div className='payment-successfull__header'>
        <span className='payment-successfull__outer-circle'>
          <span className='payment-successfull__inner-circle'>
            <CheckCircle size={32} fill='#079455' />
          </span>
        </span>
      </div>
      <div className='payment-successfull__content'>
        <span className='text-xl-semibold'>
          {message || t('paymentSuccessful')}
        </span>
      </div>

      <div className='payment-successfull__footer'>
        <XGButton
          data-testid='booking-payment-successful-close'
          id='cancel'
          size='large'
          variant='outlined'
          type='secondary'
          text={t('common:close')}
          onClick={onCloseMessage}
        />
      </div>
    </Box>
  );
};

PaymentSuccessful.propTypes = {
  id: PropTypes.string.isRequired,
  updateIsOpen: PropTypes.func,
  message: PropTypes.string
};

export default PaymentSuccessful;
