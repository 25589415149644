// @ts-nocheck
import { Box, Modal } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { XGButton } from '@xg-cl/xg-cl';
import './LibraryDeleteContent.css';
import { deleteContent } from '../../../../services/library/content';
import { ErrorToast, SuccessToast } from '../../../../utils/alerts';

/**
 * Functional React component for rendering a custom delete day component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Number} [props.contentid] - The ID for the content.
 * @param {Array} [props.handleOnItemDeleted] - Updates the content array after item was deleted.
 * @param {string} [props.isOpenModal] - Boolean value to indicate whether modal is open.
 * @param {Function} [props.updateIsOpen] - Function to update the open state.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the delete day component.
 */

const LibraryDeleteContent = ({
  contentid,
  setIdSelected,
  handleOnItemDeleted,
  isOpenModal,
  updateIsOpen,
  openSlide,
  setOpenSlide
}) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    updateIsOpen(false);
    setIdSelected(0);
    openSlide && setOpenSlide(false);
  };

  const handleConfirm = async () => {
    try {
      const result = await deleteContent(contentid);
      if (result.error || !result.data) {
        ErrorToast(t('library:errorDeletingContent'));
      } else {
        SuccessToast(t('library:itemDeleted'));
        handleOnItemDeleted(contentid);
      }
    } catch (err) {
      ErrorToast(t('library:errorDeletingContent'));
    }
    updateIsOpen(false);
  };

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={() => {}}
        data-testid='delete-content-modal'
      >
        <Box className='delete-content-modal-box'>
          <div className='delete-content-box-header'>
            <h3 className='delete-content-title'>
              {t('library:deleteContent')}
            </h3>
          </div>
          <div className='delete-content-modal-body'>
            <p>{t('library:deleteConfirmation')}</p>
          </div>
          <div className='delete-content-buttons'>
            <XGButton
              data-testid='delete-content-button'
              id='delete-content-button'
              text={t('common:confirmDeleting')}
              type='error'
              size='large'
              variant='outlined'
              onClick={handleConfirm}
            ></XGButton>
            <XGButton
              data-testid='delete-content-button'
              id='delete-content-button'
              text={t('common:cancelDeleting')}
              type='primary'
              size='large'
              variant='contained'
              onClick={handleCancel}
            ></XGButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

LibraryDeleteContent.propTypes = {
  contentid: PropTypes.number.isRequired,
  handleOnItemDeleted: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  updateIsOpen: PropTypes.func.isRequired,
  setIdSelected: PropTypes.func.isRequired,
  openSlide: PropTypes.bool,
  setOpenSlide: PropTypes.func
};

export default LibraryDeleteContent;
