//@ts-nocheck
import TripOutput from '../../components/Itineraries/TripOutput/TripOutput';
import * as PropTypes from 'prop-types';
import './Itineraries.css';

/**
 * This is the Itineraries page component.
 *
 * @namespace Pages
 */

const Itineraries = ({ id }) => {
  return (
    <div
      id={id}
      data-testid='itineraries-output'
      className='itineraries-page__container'
    >
      <TripOutput id='itineraries-output' />
    </div>
  );
};

/**
 * Prop type definitions for Itineraries.
 */
Itineraries.propTypes = {
  id: PropTypes.string.isRequired
};

export default Itineraries;
