// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rows: []
};

const passengerSlice = createSlice({
  name: 'passengers',
  initialState,
  reducers: {
    updateAllRows: (state, action) => {
      return action.payload;
    },

    updatePassengerById: (state, action) => {
      const { id } = action.payload;
      const newRows = [...state.rows];

      const index = newRows.findIndex((passenger) => passenger.id === id);
      if (index !== -1) {
        newRows[index] = { ...newRows[index], ...action.payload };
      }

      return {
        rows: newRows
      };
    }
  }
});

const { actions, reducer } = passengerSlice;

export const { updateAllRows, updatePassengerById } = actions;

export default reducer;
