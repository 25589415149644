// @ts-nocheck
import { Box, Grid, InputLabel, FormHelperText } from '@mui/material';
import { XGCheckBox } from '@xg-cl/xg-cl';
import { REQUIRED } from '../../../../constants/inputStatus';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom  passenger checkbox panel.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the checkbox panel.
 * @param {Array} options - The options for the checkbox panel.
 * @param {Array} selectedOptions - The selected options for the checkbox panel.
 * @param {Function} [props.handleOnChange] - The function to handle the onChange event.
 * @param {Function} [props.field] - The field object for the checkbox panel.
 * @param {Function} [props.formId] - The form ID for the checkbox panel.
 *
 * @returns {JSX.Element} React element representing the checkbox panel.
 */
const PassengersCheckBoxPanel = ({
  id,
  options = [],
  selectedOptions = [],
  handleOnChange,
  field,
  formId
}) => {
  return (
    <Grid item xs={12} padding='0px 16px' id={id}>
      <Box
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column'
        }}
      >
        <InputLabel
          className='xg-input-label phone-input-label'
          htmlFor={`passenger-${field?.field}-input-${id}`}
          style={{ marginLeft: '-10px' }}
        >
          {`${field?.label}${field?.display === REQUIRED ? '*' : ''}`}
        </InputLabel>
        {options?.length &&
          options.map((item, index) => (
            <XGCheckBox
              key={index}
              label={item}
              id={`passenger-checkbox-${index}`}
              value={item}
              size='medium'
              style={{ marginRight: '12px' }}
              name={`selected-${item}`}
              checked={selectedOptions.includes(String(item))}
              onChange={(e) => {
                const { value } = e.target;

                if (selectedOptions.includes(value)) {
                  const newOptions = selectedOptions.filter((e) => e !== value);
                  handleOnChange(formId, field?.fieldId, newOptions.join(','));
                } else {
                  const newOptions = [...selectedOptions, value];
                  handleOnChange(formId, field?.fieldId, newOptions.join(','));
                }
              }}
            />
          ))}
        {field?.error && (
          <FormHelperText
            className='input-form-helper-text--error'
            style={{ marginLeft: '-10px' }}
          >
            {field?.error}
          </FormHelperText>
        )}
      </Box>
    </Grid>
  );
};

PassengersCheckBoxPanel.propTypes = {
  id: PropTypes.string,
  selectedOptions: PropTypes.array,
  options: PropTypes.array,
  handleOnChange: PropTypes.func,
  field: PropTypes.object,
  formId: PropTypes.number
};

export default PassengersCheckBoxPanel;
