// @ts-nocheck
import NavMenuItem from '../NavMenuItem/NavMenuItem';
import { Box } from '@mui/material';
import * as PropTypes from 'prop-types';
import './NavMenuList.css';

/**
 * Functional React component for rendering a custom navigation menu list.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.currentPath - The current path of the application.
 * @param {string} props.id - The unique identifier for the component.
 * @param {Array<Object>} props.items - An array of items to be displayed in the navigation menu.
 * @param {boolean} props.open - A boolean indicating whether the nav menu is open.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} - The rendered NavMenuList component.
 */

const NavMenuList = ({ currentPath, id, items, open, ...rest }) => {
  return (
    <Box>
      <ul
        data-testid='nav-menu-list'
        id={id}
        className='nav-menu-list'
        {...rest}
      >
        {items &&
          items.map((item) => {
            return (
              <NavMenuItem
                active={currentPath === item.route}
                id={`nav-menu-item-${item.id}`}
                item={item}
                key={item.id}
                open={open}
              />
            );
          })}
      </ul>
    </Box>
  );
};

/**
 * Prop type definitions for NavMenuList.
 */
NavMenuList.propTypes = {
  active: PropTypes.bool,
  currentPath: PropTypes.string,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  items: PropTypes.array
};

export default NavMenuList;
