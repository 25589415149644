// @ts-nocheck
import actionTypes from '../../../constants/actionTypes';
import Editor from '../../Editor/Editor';
import ServiceImage from '../../../assets/images/ServiceImage';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XGChip, XGDropdownMenu } from '@xg-cl/xg-cl';
import { getServiceTypeIcon } from '../../../utils/serviceTypeHelper';
import { useSelector } from 'react-redux';
import { getLastListItem } from '../../../utils/common';
import {
  DotsSixVertical,
  PencilSimpleLine,
  Trash
} from '@phosphor-icons/react';
import * as PropTypes from 'prop-types';
import './ServiceCard.css';

/**
 * Functional React component for rendering a custom trip day service card.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Object} [props.data] - Object containing the data to build the trip day service card content.
 * @param {string} [props.id] - The ID for the trip day service card component.
 * @param {string} [props.onAction] - Callback function to handle actions.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the trip day service card.
 */

const ServiceCard = ({ data, id, isEditing, onAction, ...rest }) => {
  const services = useSelector((state) => state.services);
  const { t } = useTranslation(['trip', 'common']);
  const {
    description,
    lineitemid,
    servicetype,
    title,
    servicetypeid,
    lineitemimage
  } = data;

  const serviceImage = getLastListItem(lineitemimage);

  const service =
    services &&
    Array.isArray(services) &&
    services.find((service) => service?.servicetypeid === servicetypeid);

  const serviceTypeIcon = getServiceTypeIcon(servicetype?.servicetypecode);
  const serviceOptions = [
    {
      id: `day-service-option-edit-${lineitemid}`,
      name: t('common:edit'),
      onClick: (evt) => onAction(evt, actionTypes.EDIT, lineitemid),
      icon: <PencilSimpleLine width={16} color='#344054' />
    },
    {
      id: `day-service-option-delete-${lineitemid}`,
      styles: 'xg-destructive',
      name: t('common:delete'),
      onClick: (evt) => onAction(evt, actionTypes.DELETE, lineitemid),
      icon: <Trash width={16} color='#D92D20' />
    }
  ];

  return (
    <div
      id={id}
      className={`detailed-view__service-card ${
        isEditing ? 'detailed-view__service-card--editing' : ''
      }`}
      data-testid='detailed-view-service-card'
      {...rest}
    >
      <div className='detailed-view__draggable'>
        <DotsSixVertical color='#344054' size={24} />
      </div>
      <Box
        className='detailed-view__service-card-image '
        sx={{ minWidth: '200px' }}
      >
        {serviceImage?.imageurl ? (
          <img
            src={serviceImage?.imageurl}
            alt={data.title}
            draggable='false'
          />
        ) : (
          <ServiceImage id={`service-card-image-${id}`} />
        )}

        <div className='detailed-view__service-card-image-chip'>
          <XGChip
            icon={serviceTypeIcon}
            id={`line-item-chip-${lineitemid}`}
            size='small'
            text={
              t(`serviceType${service?.servicetypecode}`) !==
              `serviceType${service?.servicetypecode}`
                ? t(`serviceType${service?.servicetypecode}`)
                : service?.description
            }
            type={'primary'}
            variant='outlined'
          />
        </div>
      </Box>
      <Box
        className='detailed-view-service-card__content'
        sx={{ maxWidth: '70%' }}
      >
        <span className='text-lg-semibold service-title'>{title}</span>
        {description && (
          <>
            <Editor
              content={description}
              editable={false}
              isPreviewing={true}
              id='detailed-day-service-description-editor'
            />
          </>
        )}
      </Box>
      <div className='detailed-view__service-card-menu'>
        <XGDropdownMenu
          isButton={false}
          options={serviceOptions}
          id={`xg-dropdown-day-service-${lineitemid}`}
        />
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  id: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  data: PropTypes.object,
  onAction: PropTypes.func
};

export default ServiceCard;
