// @ts-nocheck
import BookingTotals from '../BookingPricing/BookingTotals/BookingTotals';
import BookingStepper from '../BookingStepper/CustomStepper/CustomStepper';
import PaymentSuccessful from '../PaymentSuccessful/PaymentSuccessful';
import BookingPlan from '../BookingPlans/BookingPlan/BookingPlan';
import BookingPayment from '../BookingPayments/BookingPayment/BookingPayment';
import BookingPassengerPanel from '../BookingPassengers/BookingPassengerPanel/BookingPassengerPanel';
import standardFields from '../../../constants/standardFields';
import PlaceholderLogo from '../../../assets/images/PlaceholderLogo';
import { isEmailValid } from '../../../utils/emailValidator';
import { CalendarBlank } from '@phosphor-icons/react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { XGModal } from '@xg-cl/xg-cl';
import { preventBubbling } from '../../../utils/eventHelper';
import {
  updateActiveStep,
  updatePassengerForms,
  resetFieldErrors,
  updateFormErrors,
  addTotalItems,
  updateTotalItem,
  resetTotals
} from '../../../redux/slices/booking/booking';
import { loadStripe } from '@stripe/stripe-js';
import { ErrorToast } from '../../../utils/alerts';
import { getBookingTotals } from '../../../utils/booking';
import {
  getStripeConfig,
  createPaymentIntent
} from '../../../services/stripe/stripe';
import { matchIsValidTel } from 'mui-tel-input';
import { getSettingsByCode } from '../../../services/tenant/tenant';
import { REQUIRED, OPTIONAL } from '../../../constants/inputStatus';
import { PASSENGERFIELDS } from '../../../constants/settingCodes';
import { format, add } from 'date-fns';
import { parseISODateWithoutOffset } from '../../../utils/dateUtility';
import { Box } from '@mui/material';
import { getFeesBreakdown } from '../../../utils/feesHelper';
import { renderToStaticMarkup } from 'react-dom/server';
import * as PropTypes from 'prop-types';
import './BookingModal.css';

/**
 * Functional React component for rendering a custom booking modal.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip output booking element.
 * @param {string} [props.isOpenModal] - Boolean value to indicate whether modal is open.
 * @param {Function} [props.updateIsOpen] - Function to update the open state.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the booking modal component.
 */
const BookingModal = ({ id, isOpenModal, updateIsOpen, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentMethodOrder, setPaymentMethodOrder] = useState(null);
  const booking = useSelector((state) => state.booking);
  const bookingplans = booking?.bookingplans || [];
  const tenant = booking?.tenant || null;
  const trip = booking?.trip || null;
  const steps = booking?.steps || [];
  const activeStep = steps?.find((step) => step.active);
  const { totalPrice, totalDeposit } = getBookingTotals(bookingplans);

  //Trip Data
  const tenantLogo = tenant?.tenantlogo || null;
  const tenantName = tenant?.tenantname || null;
  const tripName = trip?.tripname || null;
  const tripDate = trip?.startdate || null;
  const tripDuration = trip?.duration || null;
  const startDate = parseISODateWithoutOffset(tripDate);
  const endDate =
    startDate && add(new Date(startDate), { days: tripDuration - 1 });
  const dateFormat = t('common:dateFormat');

  const selectedPlans = bookingplans?.filter(
    (plan) => plan.selectedQuantity > 0
  );

  const filteredSteps = steps?.filter((step) => step.shouldRender);
  const passengerForms = booking?.passengerForms || [];

  //Display base total on plan and passenger step
  useEffect(() => {
    if (
      (activeStep && activeStep?.key === 'passenger') ||
      activeStep?.key === 'plan'
    ) {
      dispatch(
        addTotalItems({
          totalItems: [
            {
              title: t('trip:tripTotal'),
              priceperperson: totalPrice,
              occupancy: 1,
              depositamount: totalDeposit,
              hideTotalBreakdown: true,
              hideDeposit: false,
              selectedQuantity: 1,
              key: 'trip-total',
              totalPrice: totalPrice,
              order: 3,
              visible: true
            }
          ]
        })
      );
    }
  }, [dispatch, t, totalPrice, totalDeposit, activeStep]);

  //To avoid "caching" stripe promise, client secret, and payment intent, we are resetting them when user navigates passenger step.
  useEffect(() => {
    if (activeStep && activeStep?.key === 'passenger') {
      setPaymentMethodOrder(null);
      setStripePromise(null);
      setClientSecret(null);
      setPaymentIntent(null);
    }
  }, [activeStep]);

  //Open Booking Modal if openBookingModal query param is true
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('openBookingModal') === 'true') {
      updateIsOpen(true);

      params.delete('openBookingModal');
      params.delete('bookingPlanId');
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}${params.toString()}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToStepper = () => {
    const stepperElement = document.getElementById('book-now-modal');
    if (stepperElement) {
      stepperElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getFeedbackFromForms = () => {
    const forms = passengerForms || [];
    const formErrors = [];

    for (const form of forms) {
      const errors = getFeedbackFromFields(form?.fields || []);

      if (errors && errors.length) {
        formErrors.push({ errors, formId: form.formId });
        continue;
      }
    }

    return formErrors;
  };

  const getFeedbackFromFields = (fields) => {
    const errors = [];

    for (const field of fields) {
      const { value, display, datatype } = field;

      // Check for required fields
      if (display === REQUIRED && (!value || value?.trim() === '')) {
        errors.push({
          ...field,
          error: `${t('common:pleaseEnter')} ${field?.label?.toLowerCase()}.`
        });
      }

      // Check for valid phone number
      if (
        (display === REQUIRED || value?.trim().split(' ')?.length > 1) &&
        datatype === 'phone'
      ) {
        const valid = matchIsValidTel(value?.trim());

        if (!valid) {
          errors.push({
            ...field,
            error: t('trip:pleaseEnterValidPhoneNumber')
          });
        }
      }

      // Check for valid email
      if (datatype === 'email' && value?.trim() !== '') {
        if (!isEmailValid(value)) {
          errors.push({
            ...field,
            error: t('trip:pleaseEnterValidEmail')
          });
        }
      }

      // Check for valid date
      if (
        datatype === 'date' &&
        (display === REQUIRED || value?.trim() !== '')
      ) {
        const parsedDate = Date.parse(value?.trim());

        if (isNaN(parsedDate)) {
          errors.push({
            ...field,
            error: t('trip:pleaseEnterValidDate')
          });
        }
      }
    }

    return errors;
  };

  const handleStepClick = async (event, stepIndex) => {
    const activeIndex = steps.findIndex((step) => step.active);

    if (stepIndex > activeIndex) {
      if (activeStep?.key === 'plan') {
        await onNavigateToPassengers(event);
      } else if (activeStep?.key === 'passenger') {
        await onNavigateToPayment(event);
      }
    } else if (stepIndex < activeIndex) {
      const stepKey = steps[stepIndex]?.key;
      dispatch(updateActiveStep({ key: stepKey }));
    }
  };

  const toggleFeeTotalsVisibility = (visible = false) => {
    dispatch(
      updateTotalItem({
        totalKey: 'trip-subtotal',
        totalItem: {
          visible: visible
        }
      })
    );
    dispatch(
      updateTotalItem({
        totalKey: 'trip-totalfees',
        totalItem: {
          visible: visible
        }
      })
    );
  };

  const onNavigateToPayment = async (event) => {
    try {
      preventBubbling(event);

      if (selectedPlans.length > 0) {
        setIsSubmitting(true);
        dispatch(resetFieldErrors());

        const formErrors = getFeedbackFromForms();

        if (formErrors && formErrors.length) {
          dispatch(updateFormErrors({ forms: formErrors }));
          return;
        }

        const { data: dataConfig } = await getStripeConfig();

        if (dataConfig) {
          const { publishableKey, stripeAccount, paymentMethods } = dataConfig;

          const paymentMethodCodes = paymentMethods?.map(
            (method) => method?.paymentcode
          );

          if (!publishableKey || !stripeAccount) {
            ErrorToast(t('errors:missingPaymentMethods'));
            return;
          }

          if (!paymentMethodCodes || !paymentMethodCodes?.length) {
            ErrorToast(t('errors:missingPaymentMethods'));
          }

          setPaymentMethodOrder(paymentMethodCodes);
          setStripePromise(
            loadStripe(publishableKey, {
              stripeAccount
            })
          );

          const {
            applicationFeeNotIncluded,
            applicationFeeIncluded,
            totalWithFees,
            totalFees
          } = getFeesBreakdown(
            totalDeposit ? totalDeposit : totalPrice,
            trip?.fees ?? [],
            paymentMethodCodes && paymentMethodCodes.length > 0
              ? paymentMethodCodes[0]
              : ''
          );

          const { data: dataIntent } = await createPaymentIntent({
            amount: totalWithFees,
            description: trip?.tripname,
            applicationFees: applicationFeeNotIncluded + applicationFeeIncluded,
            paymentMethods: paymentMethodCodes
          });

          if (dataIntent) {
            const { clientSecret, paymentIntentId } = dataIntent;
            setClientSecret(clientSecret);
            setPaymentIntent(paymentIntentId);

            let totalItems = [
              {
                title: t('trip:tripTotal'),
                priceperperson: totalWithFees,
                occupancy: 1,
                depositamount: totalDeposit,
                hideTotalBreakdown: true,
                hideDeposit: false,
                selectedQuantity: 1,
                key: 'trip-total',
                totalPrice: totalWithFees,
                order: 3,
                visible: true
              },
              {
                title: t('trip:tripSubTotal'),
                priceperperson: totalDeposit > 0 ? totalDeposit : totalPrice,
                occupancy: 1,
                depositamount: 0,
                hideTotalBreakdown: true,
                hideDeposit: true,
                selectedQuantity: 1,
                key: 'trip-subtotal',
                totalPrice: totalDeposit > 0 ? totalDeposit : totalPrice,
                order: 1,
                visible: totalFees > 0
              },
              {
                title: t('trip:processingFee'),
                priceperperson: totalFees,
                occupancy: 1,
                depositamount: 0,
                hideTotalBreakdown: true,
                hideDeposit: true,
                selectedQuantity: 1,
                key: 'trip-totalfees',
                totalPrice: totalFees,
                order: 2,
                visible: totalFees > 0
              }
            ];

            dispatch(addTotalItems({ totalItems }));
            dispatch(updateActiveStep({ key: 'payment' }));
            scrollToStepper(); // Scroll to stepper after navigating to payment
          }
        } else {
          ErrorToast(t('errors:bookingCanNotContinue'));
        }
      } else {
        ErrorToast(t('errors:bookingNeedsAtLeastOnePlan'));
      }
    } catch (err) {
      ErrorToast(t('errors:bookingUnexpectedError'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const onNavigateToPassengers = async () => {
    try {
      setIsSubmitting(true);

      if (selectedPlans.length) {
        toggleFeeTotalsVisibility();

        const { data } = await getSettingsByCode(PASSENGERFIELDS);

        if (data && data.settingvalue) {
          const fieldSettings = JSON.parse(data.settingvalue);

          if (fieldSettings.length) {
            generatePassengerForms(fieldSettings);
          }
        } else {
          generatePassengerForms(standardFields);
        }
        dispatch(updateActiveStep({ key: 'passenger' }));
      } else {
        ErrorToast(t('errors:bookingNeedsAtLeastOnePlan'));
      }
    } catch (error) {
      generatePassengerForms(standardFields);
    } finally {
      setIsSubmitting(false);
      scrollToStepper();
    }
  };

  const generatePassengerForms = (fieldSettings = []) => {
    const passengerForms = [];

    const filteredFieldSettings = fieldSettings.filter(
      (field) => field?.display === REQUIRED || field?.display === OPTIONAL
    );

    const mappedFields = filteredFieldSettings?.map((field, index) => {
      return {
        fieldId: index + 1,
        ...field,
        error: null,
        value: ''
      };
    });

    selectedPlans.forEach((plan) => {
      const indexes = Array(plan?.selectedQuantity).fill(0);

      indexes.forEach((_, index) => {
        const filteredForms = booking.passengerForms
          ?.filter((form) => form?.tripplanid === plan?.tripplanid)
          ?.sort((a, b) => a.order - b.order);

        if (index < filteredForms?.length) {
          passengerForms.push(filteredForms[index]);
        } else {
          passengerForms.push({
            order: index + 1,
            formId: `${plan.tripplanid}-${index + 1}`,
            tripplanid: plan.tripplanid,
            title: plan.title,
            fields: mappedFields.sort((a, b) => a.seq - b.seq)
          });
        }
      });
    });

    dispatch(updatePassengerForms({ passengerForms }));
  };

  const onNavigateToPlans = () => {
    toggleFeeTotalsVisibility();
    dispatch(updateActiveStep({ key: 'plan' }));
    scrollToStepper(); // Scroll to stepper after navigating to plans
  };

  const onCancelBooking = () => {
    dispatch(resetTotals());
    updateIsOpen(false);
  };

  const svgString = encodeURIComponent(
    renderToStaticMarkup(<PlaceholderLogo />)
  );
  const svgLogoUrl = `data:image/svg+xml,${svgString}`;

  return (
    <Box id={id} data-testid='book-now-modal-component-container' {...rest}>
      <XGModal
        allowClosing={activeStep?.key !== 'success'}
        data-testid='book-now-modal-component'
        dismissable={false}
        id='book-now-modal'
        open={isOpenModal}
        title={activeStep?.key !== 'success' ? tripName : ''}
        image={tenantLogo ? tenantLogo : svgLogoUrl}
        imageAlt={tenantName}
        labelledby={tripName}
        maxWidth='xl'
        scroll='paper'
        onClose={() => {
          onCancelBooking();
        }}
      >
        {startDate && (
          <Box
            className='booking-modal-output-header__label'
            style={{ display: 'flex' }}
          >
            <CalendarBlank size={20} />
            <p
              className='text-sm-semibold booking-modal-output-header__label-text'
              style={{ marginLeft: '8px' }}
            >
              {`${format(new Date(startDate), dateFormat)} - ${
                endDate && format(new Date(endDate), dateFormat)
              }`}
            </p>
          </Box>
        )}
        <Box className='book-now-modal__container'>
          {activeStep?.key === 'success' ? (
            <PaymentSuccessful
              id='payment-successfull-message'
              updateIsOpen={updateIsOpen}
              message={activeStep?.message}
            />
          ) : (
            <>
              <BookingStepper
                id='book-now-modal-stepper'
                steps={filteredSteps}
                onStepClick={handleStepClick}
              />
              <Box className='book-now-modal__booking'>
                {activeStep?.key === 'plan' ? (
                  <>
                    <BookingPlan
                      id='booking-plan-step'
                      bookingPlans={bookingplans}
                      onHandleCancel={onCancelBooking}
                      onHandleNext={onNavigateToPassengers}
                      isSubmitting={isSubmitting}
                    />
                  </>
                ) : activeStep?.key === 'passenger' ? (
                  <>
                    <BookingPassengerPanel
                      id='booking-passenger-panel'
                      onHandleBack={onNavigateToPlans}
                      onHandleNext={onNavigateToPayment}
                      isSubmitting={isSubmitting}
                    />
                  </>
                ) : (
                  <>
                    <BookingPayment
                      id='booking-payment-step'
                      stripePromise={stripePromise}
                      clientSecret={clientSecret}
                      paymentIntentId={paymentIntent}
                      paymentMethodOrder={paymentMethodOrder}
                    />
                  </>
                )}
                <BookingTotals id='booking-totals-panel' />
              </Box>
            </>
          )}
        </Box>
      </XGModal>
    </Box>
  );
};

BookingModal.propTypes = {
  id: PropTypes.string.isRequired,
  isOpenModal: PropTypes.bool,
  updateIsOpen: PropTypes.func
};

export default BookingModal;
