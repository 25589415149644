//@ts-nocheck
const ROUND_DECIMALS_VALUE = 2;

//Round decimals
const roundDecimals = (value = 0, decimals = ROUND_DECIMALS_VALUE) => {
  return Number(value.toFixed(decimals));
};

//Sum up all fixed and percentage fees types
const getFeeTypesSum = (baseTotal = 0, feeList = []) => {
  let fixedFees = 0;
  let percentageSum = 0;

  feeList?.forEach((fee) => {
    if (fee?.feetype === 'fixed') {
      fixedFees += fee?.feevalue;
    } else if (fee?.feetype === 'percentage') {
      if (fee?.cap && fee?.feevalue > 0) {
        const feeAmount = (fee?.feevalue / 100) * baseTotal;

        if (feeAmount > fee?.cap) {
          fixedFees += fee?.cap;
        } else {
          percentageSum += fee?.feevalue / 100;
        }
      } else {
        percentageSum += fee?.feevalue / 100;
      }
    }
  });

  return { fixedFees, percentageSum };
};

//Apply logic to get reverse fee total
const getReverseFeeTotal = (
  expectedTotal,
  totalFixed = 0,
  totalPercentage = 0
) => {
  const totalWithFees = (expectedTotal + totalFixed) / (1 - totalPercentage);

  const reverseFeeTotal = totalWithFees - expectedTotal;

  return roundDecimals(reverseFeeTotal);
};

//Get the total of all not included additional fees
const getApplicationFeeNotIncludedTotal = (baseTotal = 0, feeList = []) => {
  const appFees =
    feeList?.filter((fee) => !fee?.paymentref && !fee?.isincluded) ?? [];

  const { fixedFees, percentageSum } = getFeeTypesSum(baseTotal, appFees);

  const totalApplicationFees = fixedFees + baseTotal * percentageSum;

  return roundDecimals(totalApplicationFees);
};
//Get the total of all included additional fees
const getApplicationFeeIncludedTotal = (baseTotal = 0, feeList = []) => {
  const appFees =
    feeList?.filter((fee) => !fee?.paymentref && fee?.isincluded) ?? [];

  const { fixedFees, percentageSum } = getFeeTypesSum(baseTotal, appFees);

  const totalApplicationFees = fixedFees + baseTotal * percentageSum;

  return roundDecimals(totalApplicationFees);
};

//Get the total all specific payment method fees
const getPyamentRefFeeTotal = (
  baseTotalPlusAppFee = 0,
  feeList = [],
  paymentRef = ''
) => {
  const paymentFees =
    feeList?.filter(
      (fee) =>
        fee?.paymentref && fee?.paymentref === paymentRef && !fee?.isincluded
    ) ?? [];

  const { fixedFees, percentageSum } = getFeeTypesSum(
    baseTotalPlusAppFee,
    paymentFees
  );

  const reverseFeeTotal = getReverseFeeTotal(
    baseTotalPlusAppFee,
    fixedFees,
    percentageSum
  );

  return roundDecimals(reverseFeeTotal);
};

//Get fees breakdown
export const getFeesBreakdown = (
  baseTotal = 0,
  feeList = [],
  paymentRef = ''
) => {
  const totalApplicationFeeIncluded = getApplicationFeeIncludedTotal(
    baseTotal,
    feeList
  );
  const totalApplicationFeeNotIncluded = getApplicationFeeNotIncludedTotal(
    baseTotal,
    feeList
  );
  const baseTotalPlusAppFee = baseTotal + totalApplicationFeeNotIncluded;
  const paymentRefFeeTotal = getPyamentRefFeeTotal(
    baseTotalPlusAppFee,
    feeList,
    paymentRef
  );
  const totalFees = totalApplicationFeeNotIncluded + paymentRefFeeTotal;
  const totalWithFees = baseTotal + totalFees;

  return {
    applicationFeeIncluded: roundDecimals(totalApplicationFeeIncluded),
    applicationFeeNotIncluded: roundDecimals(totalApplicationFeeNotIncluded),
    paymentRefFeeTotal: roundDecimals(paymentRefFeeTotal),
    totalFees: roundDecimals(totalFees),
    totalWithFees: roundDecimals(totalWithFees)
  };
};
