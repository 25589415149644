//@ts-nocheck

import {
  Airplane,
  AirplaneTakeoff,
  Buildings,
  Boat,
  Bus,
  Car,
  Compass,
  Package,
  Parachute,
  Tram
} from '@phosphor-icons/react';

/**
 * This array contains the templates icons for service types.
 */

export const serviceTypes = [
  {
    id: 1,
    icon: <Parachute size={17} />,
    code: 'MISC',
    name: 'Misc'
  },
  {
    id: 2,
    icon: <Buildings size={17} />,
    code: 'HTL',
    name: 'Hotel'
  },
  {
    id: 3,
    icon: <Boat size={17} />,
    code: 'CRU',
    name: 'Cruise'
  },
  {
    id: 3,
    icon: <Compass size={17} />,
    code: 'SST',
    name: 'Tour'
  },
  {
    id: 5,
    icon: <Bus size={17} />,
    code: 'TRF',
    name: 'Transfer'
  },
  {
    id: 6,
    icon: <AirplaneTakeoff size={17} />,
    code: 'FLT',
    name: 'Flight'
  },
  {
    id: 7,
    icon: <Car size={17} />,
    code: 'CAR',
    name: 'Car Rental'
  },
  {
    id: 8,
    icon: <Tram size={17} />,
    code: 'TRN',
    name: 'Train'
  },
  {
    id: 9,
    icon: <Package size={17} />,
    code: 'PKG',
    name: 'Package'
  },
  {
    id: 10,
    icon: <Airplane size={17} />,
    code: 'AIRF',
    name: 'Airfare'
  }
];
