// redux/slices/font/fontSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  headingFont: '',
  bodyFont: ''
};

const fontSlice = createSlice({
  name: 'font',
  initialState,
  reducers: {
    setHeadingFont: (state, action) => {
      state.headingFont = action.payload;
    },
    setBodyFont: (state, action) => {
      state.bodyFont = action.payload;
    }
  }
});

export const { setHeadingFont, setBodyFont } = fontSlice.actions;
export default fontSlice.reducer;
