// @ts-nocheck

import { getServiceTypeIcon } from '../../../utils/serviceTypeHelper';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import './Services.css';

/**
 * Functional React component for rendering the list of services.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Array} [props.serviceTypes] - Array of object with each service.
 * @param {Array} [props.selectedService] - Service ID to style the selected one.
 * @param {Array} [props.onClickItem] - Function triggered when clicking on a service item.
 *
 * @returns {JSX.Element} React element representing the list of services.
 */

const Services = ({ serviceTypes, selectedService, onClickItem }) => {
  const { t } = useTranslation(['trip', 'common']);
  return (
    <div
      data-testid='services-panel'
      className='services-list services-list__container'
    >
      {serviceTypes &&
        serviceTypes.map((service) => {
          return (
            <div
              className={`services-list__item ${
                selectedService === service?.servicetypeid
                  ? 'services-list__item--selected'
                  : ''
              }`}
              key={service?.servicetypeid}
              onClick={(e) => onClickItem(e, service?.servicetypeid)}
            >
              {getServiceTypeIcon(service?.servicetypecode)}
              <span className='services-list__text'>
                {t(`serviceType${service?.servicetypecode}`) !==
                `serviceType${service?.servicetypecode}`
                  ? t(`serviceType${service?.servicetypecode}`)
                  : service?.description}
              </span>
            </div>
          );
        })}
    </div>
  );
};

Services.propTypes = {
  serviceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      servicetypeid: PropTypes.number,
      servicetypecode: PropTypes.string,
      servicetypename: PropTypes.string,
      description: PropTypes.string,
      iconref: PropTypes.string,
      durationunit: PropTypes.string,
      durationoffset: PropTypes.number
    })
  ),
  selectedService: PropTypes.number.isRequired,
  onClickItem: PropTypes.func.isRequired
};

export default Services;
