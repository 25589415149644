// @ts-nocheck
import { XGSelect } from '@xg-cl/xg-cl';
import './PassengersFieldsTable.css';
import * as PropTypes from 'prop-types';
import { DotsSixVertical } from '@phosphor-icons/react';

const PassengersFieldsRows = ({ field, updatedField }) => {
  const displayOptions = [
    {
      id: '1',
      value: 'REQUIRED',
      label: 'REQUIRED'
    },
    {
      id: '2',
      value: 'OPTIONAL',
      label: 'OPTIONAL'
    },
    {
      id: '3',
      value: 'HIDDEN',
      label: 'HIDDEN'
    }
  ];

  const handleChangeSelect = (event, option) => {
    updatedField(field, option.props.value);
  };

  return (
    <table className='passengersfields_table'>
      <tbody>
        <tr>
          <td>
            <DotsSixVertical
              className='passengersfields_icon'
              color='#344054'
              size={22}
            />{' '}
            {field.label}
          </td>
          <td>
            <XGSelect
              name='display'
              className='passengers-fields-rows-select'
              size='small'
              value={field.display}
              menuItems={displayOptions}
              onChange={handleChangeSelect}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

PassengersFieldsRows.propTypes = {
  field: PropTypes.object,
  updatedField: PropTypes.any
};

export default PassengersFieldsRows;
