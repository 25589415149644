// @ts-nocheck
import AdditionalContentNavItem from '../AdditionalContentNavItem/AdditionalContentNavItem';
import DraggableList from '../../Draggable/DraggableList/DraggableList';
import NotAllowedToDrop from '../Library/NotAllowedToDrop/NotAllowedToDrop';
import {
  TRIP_CONTENT_LIST_DROPPABLE_ID,
  REORGANIZE_ADDITIONAL_ITEMS
} from '../../../constants/dragAndDrop';
import { updateTripContentItemSequence } from '../../../services/trip/trip';
import { updateAdditionalContentSequence } from '../../../redux/slices/trip/trip';
import { cleanDraggableAction } from '../../../redux/slices/library/library';
import { Box } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorToast } from '../../../utils/alerts';
import { reorderItems, sortByProperty } from '../../../utils/common';
import * as PropTypes from 'prop-types';
import './AdditionalContentNav.css';
import '../../Draggable/Draggable.css';

/**
 * Functional React component for rendering a custom content list for a trip.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip day list.
 * @param {string} [props.onAction] - Callback function to handle actions.
 * @param {string} [props.selectedItem] - The selected item.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} React element representing the trip content list.
 */

const AdditionalContentNav = ({ id, onAction, selectedItem, ...rest }) => {
  const { tripcontent } = useSelector((state) => state.trip);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const isDragging = useSelector((state) => state.library?.isDragging ?? false);
  const isTripContentListDraggableDisabled = useSelector(
    (state) =>
      state.library?.itinerary?.isTripContentListDraggableDisabled ?? false
  );
  const draggableAction = useSelector(
    (state) => state.library?.itinerary?.draggableAction ?? null
  );

  //Gets the day list of items for the Drag and Drop component
  const getContentItems = (list = []) => {
    const mappedItems = list.map((item) => {
      const { tripcontentid, title, seq, description } = item;
      const custom = {
        id: `tripcontentid-${tripcontentid}`,
        tripcontentid: tripcontentid,
        seq,
        title,
        description,
        content: (
          <>
            <AdditionalContentNavItem
              key={`tripcontentid-${tripcontentid}`}
              id={`tripcontentid-${tripcontentid}`}
              item={{ ...item }}
              active={item?.isSelected ?? false}
              onAction={onAction}
            />
          </>
        )
      };

      return custom;
    });

    return mappedItems;
  };

  const sortContentItems = (sourceIndex, destinationIndex, list = []) => {
    const organizedItems = reorderItems(
      list ?? [],
      sourceIndex,
      destinationIndex
    );

    const tripContentToUpdate = organizedItems?.map((item, index) => {
      return {
        tripcontentid: item?.tripcontentid,
        seq: index + 1,
        title: item?.title
      };
    });

    return {
      contentItems: tripContentToUpdate
    };
  };

  const reorderContentItems = async (sourceIndex, destinationIndex) => {
    const payload = sortContentItems(
      sourceIndex,
      destinationIndex,
      calculatedLocalState
    );

    dispatch(
      updateAdditionalContentSequence({
        tripcontentid: selectedItem,
        tripcontent: payload?.contentItems ?? []
      })
    );

    const { error } = await updateTripContentItemSequence(payload);

    if (error) {
      const rollBackPayload = sortContentItems(
        destinationIndex,
        sourceIndex,
        payload?.contentItems ?? []
      );

      dispatch(
        updateAdditionalContentSequence({
          tripcontentid: selectedItem,
          tripcontent: rollBackPayload?.contentItems ?? []
        })
      );
      ErrorToast(t('errors:tripContentCouldNotBeUpdated'));
    }
  };

  const sortedTripContentItem = sortByProperty([...(tripcontent ?? [])], 'seq');
  const calculatedLocalState = getContentItems(sortedTripContentItem);

  //On react 18 we need to load Droppable element after the component is mounted otherwise it won't work
  //See: https://github.com/atlassian/react-beautiful-dnd/issues/2399
  useEffect(() => {
    setIsMounted(true);
  }, []);

  //This effect acts as a listener, responding to actions dispatched by the DragDropContext to Redux.
  //This component is capable of identifying these actions and performing its own corresponding actions.
  useEffect(() => {
    const {
      type = null,
      sourceIndex = 0,
      destinationIndex = 0
    } = draggableAction ?? {};

    if (type === REORGANIZE_ADDITIONAL_ITEMS) {
      reorderContentItems(sourceIndex, destinationIndex);
      dispatch(cleanDraggableAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggableAction]);

  return (
    <Box>
      {tripcontent && (
        <ul
          data-testid='additional-content__list'
          id={id}
          className='additional-content__list'
          {...rest}
        >
          {isTripContentListDraggableDisabled && isDragging && (
            <NotAllowedToDrop id={'additional-not-allowed-to-drop'} />
          )}
          {isMounted ? (
            <Droppable
              droppableId={TRIP_CONTENT_LIST_DROPPABLE_ID}
              isDropDisabled={isTripContentListDraggableDisabled}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`droppable-container ${snapshot.isDraggingOver || (isDragging && !isTripContentListDraggableDisabled) ? 'droppable-container--dragging-over' : ''}`}
                >
                  <DraggableList items={calculatedLocalState} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <></>
          )}
        </ul>
      )}
    </Box>
  );
};

AdditionalContentNav.propTypes = {
  id: PropTypes.string.isRequired,
  onAction: PropTypes.func,
  selectedItem: PropTypes.number
};

export default AdditionalContentNav;
