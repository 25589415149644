// @ts-nocheck
import AdditionalContentNav from '../AdditionalContentNav/AdditionalContentNav';
import AdditionalContentData from '../AdditionalContentData/AdditionalContentData';
import actionTypes from '../../../constants/actionTypes';
import DeleteModal from '../DeleteModal/DeleteModal';
import { Box, CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Plus } from '@phosphor-icons/react';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { XGButton } from '@xg-cl/xg-cl';
import {
  addTripContent,
  removeTripContent,
  updateSelectedContentItem
} from '../../../redux/slices/trip/trip';
import {
  createTripContent,
  deleteTripContent
} from '../../../services/trip/trip';
import { ErrorToast } from '../../../utils/alerts';
import { preventBubbling } from '../../../utils/eventHelper';
import {
  cleanDraggableAction,
  addDraggableAction
} from '../../../redux/slices/library/library';
import {
  CREATE_NEW_ADDITIONAL_CONTENT,
  REORGANIZE_ADDITIONAL_ITEMS
} from '../../../constants/dragAndDrop';
import * as PropTypes from 'prop-types';
import './AdditionalContent.css';

/**
 * Functional React component for rendering a trip additional content component
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip additional content component
 * @param {...any} props.rest - Additional props to be spread on the element
 *
 * @returns {JSX.Element} React element representing the trip additional content component
 */

const AdditionalContent = ({ id, ...rest }) => {
  const { t } = useTranslation(['trip', 'common']);
  const dispatch = useDispatch();
  const storedTripData = useSelector((state) => state.trip);
  const libItems = useSelector((state) => state.library?.items ?? []);
  const { tripcontent } = useSelector((state) => state.trip);
  const selectedContent = storedTripData?.tripcontent.find(
    (item) => item.isSelected
  );

  //Status definitions
  const [isAddingContent, setIsAddingContent] = useState(false);
  const [isDeletingContent, setIsDeletingContent] = useState(false);
  const [deleteContentItem, setDeleteContentItem] = useState(null);
  const [isDeletingDayModalOpen, setIsDeletingDayModalOpen] = useState(null);

  const draggableAction = useSelector(
    (state) => state.library?.itinerary?.draggableAction ?? null
  );

  //Manages the logic when 'Delete" option is clicked from a content item
  const onDeleteContent = async (tripContentId) => {
    setDeleteContentItem({
      tripContentId
    });

    dispatch(updateSelectedContentItem(tripContentId));
    setIsDeletingDayModalOpen(true);
  };

  //Manages the logic when 'Add content" button is clicked
  const handleOnAddContent = async () => {
    try {
      const payload = {
        tripid: storedTripData.tripid
      };

      setIsAddingContent(true);

      const result = await createTripContent(payload);

      if (result.error || !result.data) {
        ErrorToast(t('errors:tripContentCouldNotBeCreated'));
      } else {
        dispatch(addTripContent(result?.data));
      }
      setIsAddingContent(false);
    } catch (err) {
      setIsAddingContent(false);
      ErrorToast(t('errors:tripContentCouldNotBeCreated'));
    }
  };

  //Manages the logit to delete a trip content item
  const handleOnDeleteTripContent = useCallback(
    async (event) => {
      preventBubbling(event);
      try {
        setIsDeletingContent(true);

        const { tripContentId } = deleteContentItem;

        const { data, error } = await deleteTripContent(tripContentId);

        if (error) {
          ErrorToast(t('errors:tripContentCouldNotBeDeleted'));
        } else {
          dispatch(removeTripContent(data?.tripcontentid));
          dispatch(
            addDraggableAction({
              context: 'itinerary',
              data: {
                type: REORGANIZE_ADDITIONAL_ITEMS,
                sourceIndex: 0,
                destinationIndex: 0
              }
            })
          );
        }
      } catch (err) {
        ErrorToast(t('errors:tripContentCouldNotBeDeleted'));
      } finally {
        setIsDeletingContent(false);
        setIsDeletingDayModalOpen(false);
      }
    },
    [deleteContentItem, dispatch, t]
  );

  //Handles the state of selected content when user clicks on a specific content item
  const onSelectContent = async (tripContentId) => {
    dispatch(updateSelectedContentItem(tripContentId));
  };

  //General event handler to manage events from content nav.
  const handleOnContentAction = async (event, action, tripContentId) => {
    preventBubbling(event);

    switch (action) {
      case actionTypes.DELETE:
        onDeleteContent(tripContentId);
        break;

      case actionTypes.SELECT:
        onSelectContent(tripContentId);
        break;
      default:
        break;
    }
  };

  //Creates a new content item from library draggable element
  const onCreateContentFromLibray = useCallback(
    async (draggableId = '', destinationIndex = 0) => {
      try {
        setIsAddingContent(true);
        const elementId = draggableId.split('.')[1] ?? '';

        if (elementId) {
          const foundLibItem = libItems.find(
            (item) => item?.contentid === parseInt(elementId)
          );

          if (foundLibItem) {
            const { title, description, contentimage = [] } = foundLibItem;
            const payload = {
              tripid: storedTripData.tripid,
              title,
              description,
              images: contentimage
            };

            const result = await createTripContent(payload);

            if (result.error || !result.data) {
              ErrorToast(t('errors:tripContentCouldNotBeCreated'));
            } else {
              dispatch(addTripContent(result?.data));
              dispatch(cleanDraggableAction());
              dispatch(
                addDraggableAction({
                  context: 'itinerary',
                  data: {
                    type: REORGANIZE_ADDITIONAL_ITEMS,
                    sourceIndex: tripcontent.length,
                    destinationIndex: destinationIndex
                  }
                })
              );
            }
          }
        }
      } catch (err) {
        ErrorToast(t('errors:tripContentCouldNotBeCreated'));
      } finally {
        setIsAddingContent(false);
      }
    },
    [libItems, tripcontent, dispatch, storedTripData, t]
  );

  useEffect(() => {
    const {
      type = null,
      draggableId = '',
      destinationIndex = 0
    } = draggableAction ?? {};

    if (type === CREATE_NEW_ADDITIONAL_CONTENT) {
      onCreateContentFromLibray(draggableId, destinationIndex);
    }
  }, [draggableAction, onCreateContentFromLibray]);

  return (
    <>
      <Box
        id={id}
        className='additional-content_wapper'
        data-testid='trip-additional-content'
        {...rest}
      >
        <Box id={id} className='additional-content_nav-menu'>
          <AdditionalContentNav
            id='additional-content_nav-menu'
            onAction={handleOnContentAction}
            selectedItem={selectedContent?.tripcontentid}
          />
          <Box
            sx={{
              width: '100%',
              paddingRight: '16px !important',
              position: 'relative'
            }}
          >
            <XGButton
              sx={{ width: '100%' }}
              data-testid='additional-content_add-button'
              id='additional-content_add-button'
              text={t('addAdditionalContent')}
              type='primary'
              size='large'
              variant='contained'
              endIcon={
                <Box className='add-content-icon'>
                  <Plus color='#98A2B3' size={16} />
                </Box>
              }
              onClick={handleOnAddContent}
              disabled={isAddingContent}
            />
            {isAddingContent && (
              <CircularProgress
                size={24}
                sx={{
                  color: blue[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </Box>
        </Box>

        {selectedContent && (
          <Box id={id} className='additional-content_container'>
            <AdditionalContentData
              data={{
                ...selectedContent,
                startdate: storedTripData?.startdate
              }}
              id='additional-content-data'
            />
          </Box>
        )}
      </Box>

      {deleteContentItem && isDeletingDayModalOpen && (
        <DeleteModal
          id='delete-trip-content-component'
          isOpenModal={isDeletingDayModalOpen}
          onConfirm={handleOnDeleteTripContent}
          onCancel={() => {
            setIsDeletingDayModalOpen(false);
          }}
          description={`${t('contentDeletionConfirmation')} ${t(
            'common:thisActionCannotBeUndone'
          )}`}
          title='Delete content'
          isSubmitting={isDeletingContent}
        />
      )}
    </>
  );
};

AdditionalContent.propTypes = {
  id: PropTypes.string.isRequired
};

export default AdditionalContent;
