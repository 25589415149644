import request from '../request';

export const getTenant = () => {
  return request.get('tenant').then((res) => res);
};

export const getSettingsByCode = (code = '') => {
  return request.get(`tenant/settings/${code}`).then((res) => res);
};
export const getTenantSettings = (settingsCode = '', data = {}) =>
  request.get(`/tenant/settings/${settingsCode}`, data).then((res) => res);

export const setTenantSettings = (settingCode = '', data = {}) =>
  request.post(`tenant/settings/${settingCode}`, data).then((res) => res);
