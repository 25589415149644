import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    contenttypeid: 0,
    title: '',
    code: ''
  }
];

const contentTypesSlice = createSlice({
  name: 'contenttypes',
  initialState,
  reducers: {
    updateContentTypes: (state, action) => {
      return action.payload;
    }
  }
});

const { actions, reducer } = contentTypesSlice;

export const { updateContentTypes } = actions;

export default reducer;
