// @ts-nocheck
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { XGButton } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import './BookingPlanActions.css';

/**
 * Render actions for booking plan component
 *
 * @namespace Components
 *
 * @param {function} onCancel - Callback function for cancel action
 * @param {function} onHandleNext - Callback function for submit action
 * @param {boolean} isSubmitting - Flag indicating if form is currently submitting
 * @return {JSX.Element} The booking plan actions component
 */
const BookingPlanActions = ({
  onCancel,
  onHandleNext,
  isSubmitting,
  disabledConfirm = false
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Box className='booking-modal__actions' data-testid='booking-modal-actions'>
      <Box sx={{ position: 'relative' }}>
        <XGButton
          style={{
            width: '54px'
          }}
          data-testid='action-next-cancel-button'
          id='action-next-cancel-button'
          text={t('cancel')}
          type='secondary'
          size='medium'
          variant='outlined'
          onClick={onCancel}
          disabled={isSubmitting}
          data-xgfe-stng-close='false'
        />
      </Box>

      <Box sx={{ position: 'relative' }}>
        <XGButton
          style={{
            width: '54px'
          }}
          data-testid='action-next-confirm-button'
          id='action-next-confirm-button'
          text={t('next')}
          type='primary'
          size='medium'
          variant='contained'
          onClick={onHandleNext}
          disabled={disabledConfirm || isSubmitting}
        />
        {isSubmitting && (
          <CircularProgress
            id='booking-modal-actions-loader'
            size={24}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        )}
      </Box>
    </Box>
  );
};

BookingPlanActions.propTypes = {
  onCancel: PropTypes.func,
  onHandleNext: PropTypes.func,
  isSubmitting: PropTypes.bool,
  disabledConfirm: PropTypes.bool
};

export default BookingPlanActions;
