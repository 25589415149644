//@ts-nocheck
import parse from 'html-react-parser';
import { Box } from '@mui/material';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom editor details.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the editor details component.
 * @param {string} [props.description] - The ID for the editor details component.
 *
 * @returns {JSX.Element} React element representing the editor details.
 */

export const Details = ({ id, description }) => {
  return (
    <>
      <Box
        id={id}
        className='ProseMirror'
        data-testid='md-editor-details'
        width='1000px'
      >
        {parse(description)}
      </Box>
    </>
  );
};

Details.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string
};
