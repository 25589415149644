//@ts-nocheck
import * as XLSX from 'xlsx';

export const downloadExcel = (rows, columns, fileName) => {
  try {
    // Filter rows based on selected columns
    const filteredRows = Object.values(rows).map((row) => {
      return Object.fromEntries(
        Object.entries(row).filter(([key]) =>
          columns.some((col) => col.field === key)
        )
      );
    });

    // Add header
    const ws = XLSX.utils.json_to_sheet(filteredRows, {
      header: columns.map((col) => col.field)
    });

    // Add custom header
    const header = columns.map((col) => col.headerName);
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });

    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Generate and save the Excel file
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } catch (error) {
    //TODO: Define a fallback in case of an error
  }
};
