import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import globalConfig from './config/globalConfig';

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    ns: ['login', 'common', 'trips', 'trip', 'errors', 'passengers', 'library'],
    fallbackLng: 'en',
    debug: !globalConfig.isProduction,
    interpolation: {
      escapeValue: false
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          // expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
          expirationTime: 1000,
          cache: true
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json'
        }
      ]
    }
  });

export default i18n;
