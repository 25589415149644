// @ts-nocheck
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../OutputDataConfig/OutputDataConfig.css';

/**
 * Functional React component for rendering a template error message.
 *
 * @namespace Components
 *
 * @param {object} props - The properties of the component.
 * @param {string} props.error - error translation id.
 * @param {object} props.rest - Additional props to be spread onto parent element.
 *
 * @returns {JSX.Element} React element representing the TemplateErrorMessage component.
 */
const TemplateErrorMessage = ({ error = '', ...rest }) => {
  const { t } = useTranslation(['settings', 'common']);
  return (
    <Box
      className='template-error-container'
      data-testid='template-error-container'
      {...rest}
    >
      <div className='template-error'>{t(error)}</div>
    </Box>
  );
};

TemplateErrorMessage.propTypes = {
  error: PropTypes.string
};

export default TemplateErrorMessage;
