//@ts-nocheck

import { Box, Modal } from '@mui/material';
import { ShareNetwork } from '@phosphor-icons/react';
import { useState } from 'react';
import { XGButton, XGTabs } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import './TripHeaderActions.css';
import ShareTab from './ShareTab';
import EmbedTab from './EmbedTab';

const TripHeaderActions = ({ id }) => {
  const trip = useSelector((state) => state.trip);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const { t } = useTranslation(['trip', 'common', 'embed']);
  const { tripid, ispublic } = trip;
  const [isPublic, setIsPublic] = useState(ispublic);
  const toggleShareDialog = () => {
    setOpenShareDialog(!openShareDialog);
    localStorage.removeItem('customColor');
    localStorage.removeItem('customText');
  };

  const modalTabs = [
    {
      title: 'Share',
      content: (
        <ShareTab
          tripid={tripid}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
        />
      )
    },
    { title: 'Embed', content: <EmbedTab tripid={tripid} /> }
  ];

  return (
    <div id={id} data-testid='trip-header-actions-component'>
      <XGButton
        startIcon={<ShareNetwork width={17} />}
        data-testid='trip-header-actions-share-button'
        id='itinerary-preview-button'
        text={t('common:share')}
        type='secondary'
        size='large'
        variant='outlined'
        onClick={toggleShareDialog}
      />
      <Modal
        open={openShareDialog}
        onClose={toggleShareDialog}
        data-testid='trip-header-actions-modal'
      >
        <Box className='trip-modal-box'>
          <XGTabs id='modal-tabs' tabs={modalTabs} />
          <div className='share-dialog-close-button'>
            <XGButton
              data-testid='share-dialog-close-button'
              id='dialog-close-button'
              text={t('common:close')}
              type='secondary'
              size='large'
              variant='outlined'
              onClick={toggleShareDialog}
            ></XGButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

TripHeaderActions.propTypes = {
  id: PropTypes.string
};

export default TripHeaderActions;
