import axios from 'axios';
import globalConfig from '../config/globalConfig';

// This allows us to resend the cookies that come from the server on each request with AXIOS
axios.defaults.withCredentials = true;

/**
 * Axios instance with global configuration for making HTTP requests.
 * @type {import('axios').AxiosInstance}
 * @alias request
 * @namespace Services
 * @property {Function} interceptors - Axios interceptors for request and response.
 */

const request = axios.create({
  baseURL: globalConfig.axios.apiUrl,
  timeout: globalConfig.axios.requestTimeout
});

/**
 * Axios request interceptor to perform actions before sending a request.
 * @function
 * @memberof request
 * @param {Object} config - Axios request configuration.
 * @returns {Object} Modified Axios request configuration.
 * @throws {Error} If an error occurs during request interception.
 */
request.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    // TODO: The language should be loaded dynamically based on user preference
    config.headers['accept-language'] = 'en';
    config.signal = new AbortController().signal;
    config.params = { ...config.params };

    return config;
  },
  (error) => Promise.reject(error)
);

/**
 * Axios response interceptor to extract the data property from the response.
 * @function
 * @memberof request
 * @param {Object} response - Axios response object.
 * @returns {Object} Data property of the response.
 * @throws {Error} If an error occurs during response interception.
 */

request.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userSession');

      //This avoid login page to be reloaded when login fails
      if (window.location.pathname != '/login') {
        window.location.href = '/login';
      }
      return error.response?.data;
    }

    if (error.response?.status <= 200 || error.response?.status >= 299) {
      const newError = new Error(error.response?.data.error.message);
      newError.name = error.response?.data.error.code;
      throw newError;
    }

    return error.response?.data;
  }
);

export default request;
