//@ts-nocheck

import { CardActions } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import BoxedIcon from '../../components/BoxedIcon/BoxedIcon';
import AuthHeader from '../../components/AuthHeader/AuthHeader';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import DottedPaginator from '../../components/DottedPaginator/DottedPaginator';
import { XGButton } from '@xg-cl/xg-cl';
import { useNavigate } from 'react-router-dom';
import * as PropTypes from 'prop-types';

const ForgotPasswordError = ({ id }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate('/forgot-password');
  };

  return (
    <Box
      id={id}
      sx={{ display: 'flex', flexDirection: 'column' }}
      data-testid='forgot-password-success-page'
    >
      <AuthHeader
        icon={
          <BoxedIcon
            id='checkmark-boxed-icon'
            icon={
              <HighlightOffOutlinedIcon
                className='forgot-password-success-icon'
                sx={{ fontSize: '28px', color: '#D92D20' }}
              />
            }
          />
        }
        id='forgot-password-success-header'
        title='Password reset'
        text='Your password link is expired. Click below to reset your password.'
        data-testid='forgot-password-error-auth-header'
      />
      <Card
        sx={{
          padding: '32px 40px 32px 40px',
          borderRadius: '12px',
          marginBottom: '32px',
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'
        }}
      >
        <CardActions sx={{ padding: 0 }}>
          <XGButton
            id='forgot-password-error-button'
            text='Reset password'
            type='primary'
            size='large'
            variant='contained'
            style={{ width: '100%' }}
            onClick={handleOnClick}
            data-testid='forgot-passoword-error-button'
          />
        </CardActions>
      </Card>
      <DottedPaginator dots={4} id='forgot-password-paginator' selected={4} />
    </Box>
  );
};

ForgotPasswordError.propTypes = {
  id: PropTypes.string.isRequired
};

export default ForgotPasswordError;
