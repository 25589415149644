//@ts-nocheck
import { Box, Popover, Divider } from '@mui/material';
import { XGButton, XGInput } from '@xg-cl/xg-cl';
import { useCallback, useState, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import {
  TextB,
  TextItalic,
  ListNumbers,
  ListBullets,
  ArrowUUpLeft,
  ArrowUUpRight,
  TextHOne,
  TextHTwo,
  TextHThree,
  TextT,
  Link,
  XCircle,
  CaretDown
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import './MenuBar.css';

/**
 * Functional React component for rendering a custom editor manu bar.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Object} [props.editor] - The editor for the editor manu bar component.
 * @param {Object} [props.editorRef] - The editor ref for the editor manu bar component.
 * @param {boolean} [props.isEditing] - Boolean value to indicate whether the editor is editing.
 *
 * @returns {JSX.Element} React element representing the editor manu bar.
 */

// };

const MenuBar = ({ editor, editorRef, isEditing }) => {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorHel, setAnchorHel] = useState(null);
  const [newUrl, setNewUrl] = useState('');
  const isPopoverOpen = Boolean(anchorEl);
  const isHeadingMenuOpen = Boolean(anchorHel);
  const popOverId = isPopoverOpen ? 'edit-link-popover' : undefined;

  const headingTexts = {
    1: t('textEditor.h1'),
    2: t('textEditor.h2'),
    3: t('textEditor.h3'),
    default: t('textEditor.p')
  };

  //Handle the click event when the user clicks on a heading.
  const handleHeadingMenuClick = (event) => {
    setAnchorHel(event.currentTarget);
  };

  //Handle the menu close
  const handleHeadingMenuClose = () => {
    setAnchorHel(null);
  };

  //Handle the selection of the heading.
  const handleHeadingSelection = (level) => {
    if (level === 0) {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level }).run();
    }
  };

  //Handle the click event when the user clicks on a heading.
  const getCurrentHeading = () => {
    if (editor) {
      if (editor.isActive('heading', { level: 1 })) {
        return 1;
      } else if (editor.isActive('heading', { level: 2 })) {
        return 2;
      } else if (editor.isActive('heading', { level: 3 })) {
        return 3;
      }
    }
    return 'default';
  };

  //Show heading icons depending on selection
  const currentHeading = getCurrentHeading();
  const showHeadingIcon = headingTexts[currentHeading] || headingTexts.default;

  //Handle the click event when the user clicks on a link.
  useEffect(() => {
    const currentEditor = editorRef?.current;
    const handleClick = (event) => {
      event.preventDefault();

      const clickedElement = event.target;

      if (clickedElement.tagName.toLowerCase() === 'a') {
        if (isEditing && clickedElement.href) {
          setNewUrl(clickedElement.href);
          setAnchorEl(clickedElement);
        }
      }
    };

    if (currentEditor) {
      currentEditor.addEventListener('click', handleClick);
    }

    return () => {
      if (currentEditor) {
        currentEditor.removeEventListener('click', handleClick);
      }
    };
  }, [editorRef, isEditing]);

  //Handle the popover close when the user leaves the link input field  .
  const handlePopOverClose = useCallback(() => {
    setAnchorEl(null);
    setNewUrl('');
  }, []);

  //Checks if the given text is a valid URL.
  const isValidLink = useMemo(() => {
    return (text) => {
      try {
        const url = new URL(text);
        return url !== null;
      } catch (error) {
        return false;
      }
    };
  }, []);

  //Display a popover with a link input field.
  const toggleLink = useCallback(
    (evt) => {
      const isActive = editor.isActive('link');
      const previousUrl = editor.getAttributes('link').href;

      if (previousUrl && isActive) {
        setNewUrl(previousUrl);
      } else {
        setNewUrl('');
      }

      setAnchorEl(evt.currentTarget);
    },
    [editor]
  );

  //Handle the link input field's key press.
  const handleHyperlinkKeyPress = useCallback(
    (evt) => {
      if (evt.key === 'Enter') {
        evt.preventDefault();

        if (!newUrl) {
          editor.chain().focus().extendMarkRange('link').unsetLink().run();
          setAnchorEl(null);
        }

        if (!isValidLink(newUrl)) {
          return;
        } else {
          editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: newUrl })
            .run();

          handlePopOverClose();
        }
      }
    },
    [editor, handlePopOverClose, isValidLink, newUrl]
  );

  if (!editor) {
    return null;
  }

  return (
    <>
      <Box className='menu-bar' data-testid='editor-menu-bar'>
        <Box className='menu-bar'>
          <XGButton
            id='menu-bar-headings'
            size='large'
            variant='link'
            type='tertiary'
            icon={showHeadingIcon}
            endIcon={<CaretDown weight='bold' size='16px' />}
            onClick={handleHeadingMenuClick}
          />
          <Popover
            data-testid='md-editor-popover'
            id={popOverId}
            open={isHeadingMenuOpen}
            anchorEl={anchorHel}
            onClose={handleHeadingMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Box id='menu-bar-heading' className='menu-bar-heading'>
              <XGButton
                id='menu-bar-heading1'
                size='large'
                variant='link'
                type='tertiary'
                icon={<TextHOne weight='bold' size='22px' />}
                text={t('textEditor.h1')}
                onClick={() => handleHeadingSelection(1)}
                className={`editor-text-h1 ${editor.isActive('heading', { level: 1 }) ? 'editor__button--active' : ''}`}
              />
              <XGButton
                id='menu-bar-heading2'
                size='large'
                variant='link'
                type='tertiary'
                text={t('textEditor.h2')}
                icon={<TextHTwo weight='bold' size='22px' />}
                onClick={() => handleHeadingSelection(2)}
                className={`editor-text-h2 ${editor.isActive('heading', { level: 2 }) ? 'editor__button--active' : ''}`}
              />{' '}
              <XGButton
                id='menu-bar-heading3'
                size='large'
                variant='link'
                type='tertiary'
                text={t('textEditor.h3')}
                icon={<TextHThree weight='bold' size='22px' />}
                onClick={() => handleHeadingSelection(3)}
                className={`editor-text-h3 ${editor.isActive('heading', { level: 3 }) ? 'editor__button--active' : ''}`}
              />
              <XGButton
                id='menu-bar-heading1'
                size='large'
                variant='link'
                type='tertiary'
                icon={<TextT weight='bold' size='22px' />}
                text={t('textEditor.p')}
                onClick={() => handleHeadingSelection(0)}
                className={`editor-text-p ${editor.isActive('paragraph') ? 'editor__button--active' : ''}`}
              />
            </Box>
          </Popover>
          <Divider orientation='vertical' flexItem />
          <XGButton
            id='menu-bar-bold'
            size='large'
            variant='link'
            type='tertiary'
            icon={<TextB weight='bold' size='22px' />}
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'editor__button--active' : ''}
          />
          <XGButton
            id='menu-bar-italic'
            size='large'
            variant='link'
            type='tertiary'
            icon={<TextItalic weight='bold' size='22px' />}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={
              editor.isActive('italic') ? 'editor__button--active' : ''
            }
          />
          <Divider orientation='vertical' flexItem />
          <XGButton
            id='menu-bar-list-bullets'
            size='large'
            variant='link'
            type='tertiary'
            icon={<ListBullets weight='bold' size='22px' />}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={
              editor.isActive('bulletList') ? 'editor__button--active' : ''
            }
          />
          <XGButton
            id='menu-bar-list-numbers'
            size='large'
            variant='link'
            type='tertiary'
            icon={<ListNumbers weight='bold' size='22px' />}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={
              editor.isActive('orderedList') ? 'editor__button--active' : ''
            }
          />
          <Divider orientation='vertical' flexItem />
          <XGButton
            id='menu-bar-link'
            size='large'
            variant='link'
            type='tertiary'
            icon={<Link weight='bold' size='22px' />}
            onClick={toggleLink}
            className={editor.isActive('link') ? 'editor__button--active' : ''}
            aria-describedby={popOverId}
          />
        </Box>
        <Popover
          data-testid='md-editor-popover'
          id={popOverId}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopOverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div className='menu-bar__dialog'>
            <p className='menu-bar__dialog-title  text-sm-medium '>
              Paste a link
            </p>
            <XGInput
              error={
                newUrl && newUrl.length > 0
                  ? isValidLink(newUrl)
                    ? false
                    : true
                  : false
              }
              autoFocus={true}
              data-testid='md-editor-popover-input'
              name='link'
              id='md-editor-popover-input'
              type='text'
              value={newUrl}
              onChange={(e) => setNewUrl(e.target.value)}
              onKeyPress={handleHyperlinkKeyPress}
              icon
              customIcon={
                newUrl ? (
                  <div
                    className='menu-bar__customIcon '
                    onClick={() => {
                      setNewUrl('');
                    }}
                  >
                    <XCircle size={20} weight='fill' fill='#344054' />
                  </div>
                ) : (
                  <></>
                )
              }
            />
          </div>
        </Popover>
        <Box>
          <XGButton
            id='undo'
            size='large'
            variant='link'
            type='tertiary'
            icon={<ArrowUUpLeft weight='bold' size='22px' />}
            onClick={() => editor.chain().focus().undo().run()}
          />
          <XGButton
            id='redo'
            size='large'
            variant='link'
            type='tertiary'
            icon={<ArrowUUpRight weight='bold' size='22px' />}
            onClick={() => editor.chain().focus().redo().run()}
          />
        </Box>
      </Box>
    </>
  );
};

MenuBar.propTypes = {
  editor: PropTypes.shape({
    chain: PropTypes.func,
    isActive: PropTypes.func,
    getAttributes: PropTypes.func
  }),
  editorRef: PropTypes.object,
  isEditing: PropTypes.bool
};

export default MenuBar;
