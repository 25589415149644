//@ts-nocheck

//Fallback values
const presetCurrencyCode = 'USD';
const presetCurrencySymbol = '$';
const presetFractionDigits = 2;
const presetLocale = 'en-US';

//Get the preset config
const getPresetConfig = () => {
  return {
    currencyCode: presetCurrencyCode,
    currencySymbol: presetCurrencySymbol,
    fractionDigits: presetFractionDigits,
    locale: presetLocale
  };
};

//Gets the tenant info from the store
const getTenantInfo = () => {
  try {
    const tenant = JSON.parse(localStorage.getItem('tenantData'));

    return {
      ...tenant
    };
  } catch (error) {
    return {};
  }
};

//Get the configuration
export const getConfig = (config = {}) => {
  const presetConfig = getPresetConfig((config = {}));
  const { sellingcurrency = {} } = getTenantInfo();
  let settings = { ...presetConfig, ...config };

  if (Object.keys(sellingcurrency).length > 0) {
    const { currencycode, currencysymbol } = sellingcurrency;
    const tenantConfig = {
      currencyCode: currencycode,
      currencySymbol: currencysymbol
    };

    settings = { ...presetConfig, ...tenantConfig, ...config };
  }

  return settings;
};

/**
 * Returns a formatted currency string.
 *
 * @param {Object} amount - The amount to be formatted
 * @param {Object} config - The configuration options
 * @return {string}  The formatted currency string
 */
export const formatCurrency = (amount = 0, config = {}) => {
  const currencyConfig = getConfig(config) || {};
  const { currencyCode, currencySymbol, fractionDigits, locale } =
    currencyConfig;

  // Use Intl.NumberFormat to format the number according to the specified options
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }).format(amount);

  // Remove the currency symbol added by Intl.NumberFormat and trim any spaces
  const numberFormat = formattedAmount.replace(
    new RegExp(`\\${currencySymbol}\\s?`),
    ''
  );

  return `${currencySymbol}${numberFormat} ${currencyCode}`;
};
