// @ts-nocheck
import * as PropTypes from 'prop-types';
/**
 * Functional React component for rendering custom tabs
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed view.
 */

const CustomTab = ({ children }) => {
  return <div>{children}</div>;
};

CustomTab.propTypes = {
  children: PropTypes.node
};

export default CustomTab;
