// @ts-nocheck
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import './ItinerarySkeleton.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom skeleton for the itineray content.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 *
 * @returns {JSX.Element} - The rendered skeleton for the itineray.
 */

const ItinerarySkeleton = ({ id }) => {
  const headerBoxes = [0, 1, 2, 3, 4, 5, 6];
  const dayBoxes = [0, 1, 2, 3, 4, 5];

  return (
    <Box
      id={id}
      className='itinerary-skeleton'
      data-testid='itinerary-skeleton'
    >
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={80}
        sx={{ border: '1px solid #EAECF0' }}
      />
      <Box
        sx={{
          margin: '16px',
          borderRadius: '8PX',
          border: '1px solid #EAECF0',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          gap: '12px',
          justifyContent: 'space-around',
          padding: '8px'
        }}
      >
        {headerBoxes &&
          headerBoxes.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  width: '12%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '4px'
                }}
              >
                <Skeleton
                  variant='text'
                  sx={{ fontSize: '12px' }}
                  width={'70%'}
                />
                <Box sx={{ display: 'flex', marginTop: '4px' }}>
                  <Skeleton variant='rectangular' height={20} width={20} />
                  <Skeleton
                    variant='text'
                    sx={{ fontSize: '12px', marginLeft: '8px' }}
                    width={'90%'}
                  />
                </Box>
              </Box>
            );
          })}
      </Box>

      <Box className='trip-description__wrapper'>
        <Skeleton variant='text' sx={{ fontSize: '12px' }} width={'127px'} />
        <Skeleton variant='text' sx={{ fontSize: '14px' }} width={'60%'} />
        <Skeleton variant='text' sx={{ fontSize: '14px' }} width={'55%'} />
        <Skeleton variant='text' sx={{ fontSize: '14px' }} width={'30%'} />
      </Box>
      <Box className='itinerary-description-divider' />

      <Box
        sx={{
          padding: '40px 24px 18px 24px',
          width: '100%',
          display: 'flex',
          gap: '16px',
          justifyContent: 'end',
          backgroundColor: '#ffffff'
        }}
      >
        <Skeleton width={120} height={60} />
        <Skeleton width={120} height={60} />
      </Box>
      <Box sx={{ padding: '0px 24px', backgroundColor: '#ffffff' }}>
        {dayBoxes &&
          dayBoxes.map((item, index) => {
            return (
              <Skeleton
                key={index}
                height={'100px'}
                sx={{ borderRadius: '8px' }}
              />
            );
          })}
      </Box>
    </Box>
  );
};

/**
 * Prop type definitions for ItinerarySkeleton.
 */
ItinerarySkeleton.propTypes = {
  id: PropTypes.string.isRequired
};

export default ItinerarySkeleton;
