import { Box, Skeleton } from '@mui/material';
import * as PropTypes from 'prop-types';
import './TripTableSkeleton.css';
/**
 * Functional React component for rendering a custom skeleton for the itineray content.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 *
 * @returns {JSX.Element} - The rendered skeleton for the table.
 */

const TripTableSkeleton = ({ id }) => {
  const headerCells = [0, 1, 2, 3, 4];
  const rows = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const paginationCells = [0];

  return (
    <Box
      id={id}
      className='shell-skeleton'
      data-testid='shell-skeleton'
      width='100%'
    >
      <Box
        className='header-row'
        sx={{
          display: 'flex',
          gap: '10px',
          padding: '0 10px'
        }}
      >
        {headerCells.map((index) => (
          <Skeleton
            key={index}
            variant='text'
            width='100%'
            height={60} // Set a fixed height for the header row skeleton
            sx={{ marginBottom: '10px', borderRadius: '4px' }}
            animation='wave'
          />
        ))}
      </Box>
      <Box className='data-rows'>
        {rows.map((rowIndex) => (
          <Box
            key={rowIndex}
            className='data-row'
            sx={{
              display: 'flex',
              gap: '10px',
              padding: '0 10px',
              alignItems: 'center',
              minWidth: '100%' // Ensure the row doesn't shrink
            }}
          >
            {headerCells.map((cellIndex) => (
              <Skeleton
                key={`${rowIndex}-${cellIndex}`}
                variant='text'
                width='100%'
                height={40} // Set a fixed height for the data row skeletons
                sx={{ marginBottom: '10px', borderRadius: '4px' }}
                animation='wave'
              />
            ))}
          </Box>
        ))}
      </Box>
      <Box
        className='pagination-row'
        sx={{
          display: 'flex',
          gap: '10px',
          padding: '0 10px',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {paginationCells.map((index) => (
          <Skeleton
            key={index}
            variant='text'
            width='100%' // Adjust width as needed for pagination cells
            height={50} // Set a fixed height for pagination cells
            sx={{ marginBottom: '10px', borderRadius: '4px' }}
            animation='wave'
          />
        ))}
      </Box>
    </Box>
  );
};

TripTableSkeleton.propTypes = {
  id: PropTypes.string.isRequired
};

export default TripTableSkeleton;
