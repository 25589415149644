// colorSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { getLighterShade, getDarkerShade } from '../../../utils/colors/colors';

const defaultHighlightColor = '#0D69D4';
const defaultLighterShade = getLighterShade(defaultHighlightColor, 0.8);
const defaultDarkerShade = getDarkerShade(defaultHighlightColor, 0.8);

const initialState = {
  highlightColor: defaultHighlightColor,
  lighterHighlightColor: defaultLighterShade,
  darkerHighlightColor: defaultDarkerShade
};

const colorSlice = createSlice({
  name: 'color',
  initialState,
  reducers: {
    setHighlightColor: (state, action) => {
      state.highlightColor = action.payload;
      state.lighterHighlightColor = getLighterShade(action.payload, 0.8);
      state.darkerHighlightColor = getDarkerShade(action.payload, 0.2);
    }
  }
});

export const { setHighlightColor } = colorSlice.actions;
export default colorSlice.reducer;
