//Stripe statuses
export const SUCCEEDED = 'succeeded';
export const PROCESSING = 'processing';
export const REQUIRES_ACTION = 'requires_action';
export const REQUIRES_CONFIRMATION = 'requires_confirmation';

//Stripe statuses treated as successful
export const successfulStatuses = [
  REQUIRES_CONFIRMATION,
  REQUIRES_ACTION,
  PROCESSING,
  SUCCEEDED
];

//Default
export const DEFAULT_PAYMENT_METHOD = 'card';

//Stripe appearance settings
export const stripeAppearance = {
  theme: 'stripe',
  variables: {
    borderRadius: '8px',
    colorDanger: '#FF6E6E',
    colorText: '#667085',
    fontSizeSm: '14px'
  },
  rules: {
    '.Input::placeholder': { color: '#aaafb7', fontWeight: '400' },
    '.Input:focus': {
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px #1361D8'
    },
    '.Input--invalid': {
      border: '1px'
    },
    '.Input--invalid:focus': {
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px #f04438',
      borderColor: 'transparent'
    },
    '.Label': {
      fontWeight: '500',
      marginBottom: '8px'
    },
    '.Error': {
      marginTop: '8px'
    }
  }
};

//Setting options for Stripe form
export const paymentElementOptions = {
  layout: 'tabs',
  paymentMethodOrder: [],
  fields: {
    billingDetails: {
      name: 'never',
      email: 'never',
      address: {
        country: 'never'
      }
    }
  }
};
