// @ts-nocheck
import Box from '@mui/material/Box';
import AIGenerateButton from '../../AIGenerateButton/AIGenerateButton';
import CircularProgress from '@mui/material/CircularProgress';
import Editor from '../../Editor/Editor';
import InlineEditor from '../../InlineEditor/InlineEditor';
import inputRules from '../../../constants/inputRules';
import ImagePicker from '../../Upload/ImagePicker/ImagePicker';
import UpdateModal from '../UpdateModal/UpdateModal';
import NotAllowedToDrop from '../Library/NotAllowedToDrop/NotAllowedToDrop';
import { File } from '@phosphor-icons/react';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { XGButton } from '@xg-cl/xg-cl';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { ErrorToast, SuccessToast } from '../../../utils/alerts';
import { Droppable } from 'react-beautiful-dnd';
import {
  cleanDraggableAction,
  addShouldLibraryReload
} from '../../../redux/slices/library/library';
import {
  editTripContent,
  updateContentImage
} from '../../../redux/slices/trip/trip';
import {
  updateTripContent,
  addTripContentImage
} from '../../../services/trip/trip';
import { saveContentToLibrary } from '../../../services/library/content';
import { getLastListItem } from '../../../utils/common';
import {
  TRIP_CONTENT_ITEM_DROPPABLE_ID,
  UPDATE_ADDITIONAL_CONTENT
} from '../../../constants/dragAndDrop';
import * as PropTypes from 'prop-types';
import './AdditionalContentData.css';
import '../../Draggable/Draggable.css';

/**
 * Functional React component for rendering custom trip content data.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {String} [props.id] - The ID for AdditionalContentData.
 * @param {Object} [props.data] - Object containing the data to build the trip content data.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the AdditionalContentData component.
 */

const AdditionalContentData = ({ id, data, ...rest }) => {
  const { t } = useTranslation(['trip', 'common', 'library']);
  const { description, title, tripcontentimage, tripcontentid } = data || {};
  const storedTripData = useSelector((state) => state.trip);
  const contentTypes = useSelector((state) => state.contenttypes);
  const dispatch = useDispatch();
  const selectedContent = storedTripData?.tripcontent.find(
    (item) => item.isSelected
  );

  //Status definitions
  const [isUpdatingContent, setIsUpdatingContent] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isEditorPreviewing, setIsEditorPreviewing] = useState(true);
  const [isEditorDisabled, setIsEditorDisabled] = useState(false);
  const [isSubmittingToLibrary, setIsSubmittingToLibrary] = useState(false);
  const [contentDescription, setContentDescription] = useState(description);
  const [markdown, setMarkdown] = useState(description);
  const [isUpdatingModalOpen, setIsUpdatingModalOpen] = useState(false);
  const [actionDraggableId, setActionDraggableId] = useState(null);
  const libItems = useSelector((state) => state.library?.items ?? []);
  const contentImage = getLastListItem(tripcontentimage);
  const isDragging = useSelector((state) => state.library?.isDragging ?? false);
  const isTripContentDataDraggableDisabled = useSelector(
    (state) =>
      state.library?.itinerary?.isTripContentDataDraggableDisabled ?? false
  );

  const draggableAction = useSelector(
    (state) => state.library?.itinerary?.draggableAction ?? null
  );

  //Cancels editor
  const onCancelEditor = () => {
    setMarkdown(description);
    setContentDescription(description);
  };

  //Saves editor content
  const onSaveEditor = async () => {
    try {
      const { data } = await updateTripContent(tripcontentid, {
        description: `${markdown}`
      });

      if (data) {
        dispatch(editTripContent(data));
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
    }
  };

  //Save trip content to library
  const saveTripContentToLibrary = async () => {
    const { title, tripcontentimage } = selectedContent;

    const contentType = contentTypes?.find(
      (contentType) => contentType?.code === 'additional'
    );

    const contentData = {
      contenttypeid: parseInt(contentType?.contenttypeid ?? 0),
      title,
      description: `${markdown}`,
      images: tripcontentimage
    };

    try {
      setIsSubmittingToLibrary(true);

      const { data } = await saveContentToLibrary(contentData);

      if (data) {
        dispatch(addShouldLibraryReload({ shouldLibraryReload: true }));
        SuccessToast(t('tripContentAddedToLibrary'));
      } else {
        ErrorToast(t('errors:tripContentCouldNotBeAddedToLibrary'));
      }
    } catch (err) {
      ErrorToast(t('errors:tripContentCouldNotBeAddedToLibrary'));
    } finally {
      setIsSubmittingToLibrary(false);
    }
  };

  //Updates trip content title
  const updateTripContentTitle = async (newTripDayTitle) => {
    try {
      if (newTripDayTitle?.trim() !== title) {
        const { data } = await updateTripContent(tripcontentid, {
          title: newTripDayTitle?.trim()
        });

        if (data) {
          dispatch(editTripContent(data));
        }
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
    }
  };

  //Updates trip content cover image
  const updateContentCoverImage = async (newImageUrl) => {
    const { data: contentData } = await addTripContentImage(tripcontentid, {
      imageUrl: newImageUrl
    });

    if (contentData) {
      const { tripcontentimageid, seq, imageurl } = contentData;

      dispatch(
        updateContentImage({
          tripcontentid,
          tripcontentimage: [
            {
              tripcontentimageid,
              seq,
              imageurl
            }
          ]
        })
      );

      return { data: true };
    }

    return { data: false };
  };

  const updateTripContentData = async (newData = {}) => {
    try {
      const { data } = await updateTripContent(tripcontentid, {
        ...newData
      });

      if (data) {
        dispatch(editTripContent(data));
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
    }
  };

  const handleUpdateContentSubmit = async () => {
    setIsUpdatingContent(true);

    if (actionDraggableId) {
      const foundLibItem = libItems.find(
        (item) => item?.contentid === parseInt(actionDraggableId)
      );

      if (foundLibItem) {
        await updateTripContentData({
          title: foundLibItem?.title ?? '',
          description: foundLibItem?.description ?? ''
        });

        const newImages = foundLibItem?.contentimage ?? [];

        if (newImages && newImages.length && newImages[0]?.imageurl) {
          await updateContentCoverImage(newImages[0]?.imageurl);
        }
      }
    }
    dispatch(cleanDraggableAction());
    setIsUpdatingModalOpen(false);
    setIsUpdatingContent(false);
    setActionDraggableId(null);
  };

  const onCancelUpdateContent = () => {
    setIsUpdatingModalOpen(false);
    setIsUpdatingContent(false);
    dispatch(cleanDraggableAction());
  };

  //On react 18 we need to load Droppable element after the component is mounted otherwise it won't work
  //See: https://github.com/atlassian/react-beautiful-dnd/issues/2399
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (tripcontentid) {
      setContentDescription(description || '');
      setMarkdown(description || '');
      setIsEditorPreviewing(true);
    }
  }, [tripcontentid, description]);

  //This effect acts as a listener, responding to actions dispatched by the DragDropContext to Redux.
  //This component is capable of identifying these actions and performing its own corresponding actions.
  useEffect(() => {
    const { type = null, draggableId = '' } = draggableAction ?? {};

    if (type === UPDATE_ADDITIONAL_CONTENT) {
      const foundElementId = draggableId.split('.')[1] ?? '';
      setIsUpdatingModalOpen(true);
      setActionDraggableId(foundElementId);
    }
  }, [draggableAction]);

  return (
    <>
      <Box
        id={id}
        className={`additional-content-data__wapper`}
        data-testid='additional-content-data'
        {...rest}
      >
        {isMounted ? (
          <Droppable
            droppableId={TRIP_CONTENT_ITEM_DROPPABLE_ID}
            isDropDisabled={isTripContentDataDraggableDisabled}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`
                    additional-content__droppable-container 
                    ${(snapshot.isDraggingOver && !isTripContentDataDraggableDisabled) || (isDragging && !isTripContentDataDraggableDisabled) ? 'additional-content__droppable-container--dragging-over' : ''}`}
              >
                <div className='additional-content__droppable-box' />

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : (
          <></>
        )}
        {isTripContentDataDraggableDisabled && (
          <NotAllowedToDrop
            id={'additional-content-data-not-allowed-to-drop'}
          />
        )}
        <Box
          id='additional-content-data__header'
          className={`additional-content-data__header droppable-padding `}
          data-testid='additional-content-data__header'
        >
          <div
            style={{
              width: '100%'
            }}
          >
            <ImagePicker
              callback={updateContentCoverImage}
              id={`additional-content-image-picker-component`}
              url={contentImage?.imageurl}
              handleUploading={() => {}}
            />
          </div>

          <Box className='additional-content-data__header-container'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <File size={20} color='#667085' />
              <InlineEditor
                action={updateTripContentTitle}
                className='text-md-semibold service-content-title'
                id='tripname-inline-editor'
                initialValue={title}
                isValueRequired={false}
                maxTextLength={inputRules.maxTitleLength}
                placeholder={`${t('enterTitle')}`}
                style={{ maxWidth: '750px' }}
              />
            </div>
          </Box>

          <div className='additional-content__editor-container'>
            <Editor
              editable={true}
              isPreviewing={isEditorPreviewing}
              content={contentDescription}
              id={`additional-content-editor-description-${tripcontentid}`}
              setDescription={() => {}}
              setMarkdown={setMarkdown}
              setIsPreviewing={setIsEditorPreviewing}
              showActions={true}
              onCancelAction={onCancelEditor}
              onSaveAction={onSaveEditor}
              placeholder={`${t('enterDescription')}`}
              generatePrompt={
                !isEditorPreviewing ? (
                  <div>
                    <AIGenerateButton
                      id='ai-generate-trip-description-button'
                      setMarkdown={setMarkdown}
                      setTripDescription={setContentDescription}
                      editorContent={markdown}
                      setIsEditorDisabled={setIsEditorDisabled}
                      disableGenerate={true}
                    />
                  </div>
                ) : null
              }
              isEditorDisabled={isEditorDisabled}
            />
          </div>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <XGButton
                data-testid='save-to-library__button'
                id='save-to-library__button'
                text={t('library:saveToLibrary')}
                type='primary'
                size='medium'
                variant='contained'
                onClick={saveTripContentToLibrary}
                disabled={isSubmittingToLibrary}
              />
              {isSubmittingToLibrary && (
                <CircularProgress
                  id='save-to-library-loader'
                  size={24}
                  sx={{
                    color: blue[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {isUpdatingModalOpen && (
        <UpdateModal
          id='update-trip-content-component'
          isOpenModal={isUpdatingModalOpen}
          onCancel={onCancelUpdateContent}
          description={`${t('contentUpdationConfirmation')} ${t(
            'common:thisActionCannotBeUndone'
          )}`}
          title={`${t('updateTripContent')}`}
          onConfirm={handleUpdateContentSubmit}
          isSubmitting={isUpdatingContent}
        />
      )}
    </>
  );
};

AdditionalContentData.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  onAction: PropTypes.func,
  editingServiceId: PropTypes.number
};

export default AdditionalContentData;
