// @ts-nocheck
import { Box } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { XGModal } from '@xg-cl/xg-cl';
import { removeService } from '../../../redux/slices/trip/trip';
import { deleteTripDayService } from '../../../services/trip/trip';
import { ErrorToast } from '../../../utils/alerts';
import { preventBubbling } from '../../../utils/eventHelper';
import DeleteActions from '../DeleteActions/DeleteActions';

/**
 * Functional React component for rendering a custom delete line item component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Object} [props.data] - The data for the service.
 * @param {string} [props.id] - The ID for the edit day item component.
 * @param {Number} [props.tripId] - The ID for the trip.
 * @param {Number} [props.tripDayId] - The ID for the trip day.
 * @param {Number} [props.lineItemId] - The ID for the trip day line item.
 * @param {string} [props.isOpenModal] - Boolean value to indicate whether modal is open.
 * @param {Function} [props.updateIsOpen] - Function to update the open state.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the delete line item component.
 */

const DeleteService = ({ data, id, isOpenModal, updateIsOpen, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnSubmit = useCallback(
    async (event) => {
      preventBubbling(event);
      try {
        setIsSubmitting(true);

        const { lineItemId, tripDayId } = data;
        const result = await deleteTripDayService(lineItemId);

        if (result?.err) {
          ErrorToast(t('errors:serviceCouldNotBeDeleted'));
        } else {
          dispatch(
            removeService({ tripdayid: tripDayId, lineitemid: lineItemId })
          );
          updateIsOpen(false);
        }
      } catch (err) {
        ErrorToast(t('errors:serviceCouldNotBeDeleted'));
      } finally {
        setIsSubmitting(false);
      }
    },
    [data, dispatch, t, updateIsOpen]
  );

  return (
    <Box id={id} data-testid='delete-service-component' {...rest}>
      <XGModal
        allowClosing={false}
        data-testid='delete-service-modal-component'
        actions={
          <DeleteActions
            onCancel={() => {
              updateIsOpen(false);
            }}
            onSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
          />
        }
        dismissable={false}
        id='delete-service-modal'
        open={isOpenModal}
        title={t('deleteService')}
        labelledby={t('deleteService')}
        maxWidth='lg'
        scroll='body'
      >
        <Box className='create-trip-header' sx={{ maxWidth: '400px' }}>
          <p className='text-md-regular'>
            {`${t('serviceDeletionConfirmation')} ${t(
              'common:thisActionCannotBeUndone'
            )}`}
          </p>
        </Box>
      </XGModal>
    </Box>
  );
};

DeleteService.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  isOpenModal: PropTypes.bool,
  updateIsOpen: PropTypes.func
};

export default DeleteService;
