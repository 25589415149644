//@ts-nocheck

const UNLIMITED_PLAN_LIMIT = 30;

export const getItemDepositAmount = (item) => {
  const depositamount = Number(item?.depositamount);
  const selectedQuantity = Number(item?.selectedQuantity);

  if (!isNaN(depositamount) && !isNaN(selectedQuantity)) {
    return depositamount * selectedQuantity;
  } else {
    return 0;
  }
};

export const getItemTotal = (item) => {
  const priceperperson = Number(item?.priceperperson);
  const selectedQuantity = Number(item?.selectedQuantity);

  if (!isNaN(priceperperson) && !isNaN(selectedQuantity)) {
    return priceperperson * selectedQuantity;
  } else {
    return 0;
  }
};

export const getNumberMultiples = (num, limit) => {
  const multiples = [];

  if (num <= 0 || limit < 0) {
    return [0];
  }

  // Loop through numbers from 0 to the limit, checking for multiples
  for (let i = 0; i <= limit; i++) {
    if (i % num === 0) {
      multiples.push(i);
    }
  }

  return multiples;
};

export const getBookingPlanDropdownOptions = (plan) => {
  const planOccupancy = parseInt(plan?.occupancy);
  const maxPlanAvailable =
    !isNaN(parseInt(plan?.maxavailable)) && parseInt(plan?.maxavailable) > 0
      ? parseInt(plan?.maxavailable)
      : UNLIMITED_PLAN_LIMIT;

  if (!isNaN(maxPlanAvailable) && !isNaN(planOccupancy)) {
    const options = getNumberMultiples(
      planOccupancy,
      maxPlanAvailable * planOccupancy
    );

    return options.map((option) => {
      return {
        id: option.toString(),
        value: option.toString(),
        label: option.toString()
      };
    });
  }

  return [
    {
      id: '0',
      value: '0',
      label: '0'
    }
  ];
};

export const getPlanDepositAmount = (plan) => {
  const depositamount = Number(plan?.depositamount);
  const occupancy = Number(plan?.occupancy);

  if (!isNaN(depositamount) && !isNaN(occupancy)) {
    return depositamount * occupancy;
  } else {
    return 0;
  }
};

export const getPlanTotal = (plan) => {
  const priceperperson = Number(plan?.priceperperson);

  if (!isNaN(priceperperson)) {
    return priceperperson;
  } else {
    return 0;
  }
};

export const hasMixedPlans = (bookingPlans = []) => {
  const plansWithDeposit = bookingPlans?.filter(
    (plan) => plan?.depositamount > 0 && plan?.selectedQuantity > 0
  );

  const plansWithoutDeposit = bookingPlans?.filter(
    (plan) => !plan?.depositamount && plan?.selectedQuantity > 0
  );

  return plansWithDeposit.length > 0 && plansWithoutDeposit.length > 0;
};

export const getBookingTotals = (bookingPlans = []) => {
  let totalPrice = 0;
  let totalDeposit = 0;
  const selectedPlans = bookingPlans?.filter(
    (plan) => plan.selectedQuantity > 0
  );
  const includeMixedPlans = hasMixedPlans(selectedPlans);

  selectedPlans.forEach((tripPlan) => {
    const selectedQuantity = Number(tripPlan?.selectedQuantity);
    const priceperperson = Number(tripPlan?.priceperperson);
    const depositamount = Number(tripPlan?.depositamount);

    //Get pricing plan total
    if (!isNaN(priceperperson)) {
      totalPrice += priceperperson * selectedQuantity;
    }

    //Get pricing plan deposit amount
    if (includeMixedPlans) {
      if (!isNaN(depositamount) && depositamount > 0) {
        totalDeposit += depositamount * selectedQuantity;
      } else {
        totalDeposit += priceperperson * selectedQuantity;
      }
    } else {
      if (!isNaN(depositamount)) {
        totalDeposit += depositamount * selectedQuantity;
      }
    }
  });

  return {
    totalPrice,
    totalDeposit
  };
};
