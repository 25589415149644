//@ts-nocheck
import AuthHeader from '../../components/AuthHeader/AuthHeader';
import Box from '@mui/material/Box';
import BoxedIcon from '../../components/BoxedIcon/BoxedIcon';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DottedPaginator from '../../components/DottedPaginator/DottedPaginator';
import { XGButton } from '@xg-cl/xg-cl';
import { EnvelopeSimple } from '@phosphor-icons/react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { useEffect } from 'react';
import { resendPassword } from './../../services/auth/auth';
import { ErrorToast, SuccessToast } from '../../utils/alerts';

/**
 * This is the send/resend password page component.
 *
 * @namespace Pages
 */

const SendPassword = ({ id }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email || '';

  useEffect(() => {
    if (!email?.trim()) {
      navigate('/forgot-password');
    } else {
      SuccessToast('Email was sent successfully');
    }
  }, [email, navigate]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!email?.trim()) {
        navigate('/forgot-password');
        return;
      }

      const result = await resendPassword({ email });

      if (!result.data) {
        ErrorToast('Email could not be sent, please try again');
      }

      SuccessToast('Email was sent successfully');
    } catch (err) {
      ErrorToast('Something went wrong, please try again');
    }
  };

  return (
    <div id={id} data-testid='send-password-page'>
      <AuthHeader
        icon={
          <BoxedIcon
            id='send-boxed-icon'
            icon={<EnvelopeSimple color='#344054' size={28} />}
          />
        }
        id='send-password-header'
        text={
          <span>
            We sent a password reset link to <strong>{email}</strong>
          </span>
        }
        title='Check your email'
      />
      <Card
        data-testid='send-password-page-content'
        sx={{
          padding: '32px 40px 32px 40px',
          borderRadius: '12px',
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '20px',
              alignSelf: 'stretch',
              marginBottom: '24px'
            }}
          >
            <p className='text text-sm-medium text-center'>
              Didn’t receive the email?
            </p>
          </Box>
        </CardContent>
        <CardActions
          sx={{ width: '100%', '& button': { width: '100%' }, padding: 0 }}
        >
          <XGButton
            id='button-reset-password'
            text='Click to resend'
            type='primary'
            size='large'
            variant='contained'
            onClick={handleSubmit}
          />
        </CardActions>
      </Card>

      <Box
        sx={{
          display: 'flex',
          padding: '24px 0px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '32px',
          alignSelf: 'stretch',
          justifyContent: 'center'
        }}
      >
        <XGButton
          href='/login'
          id='send-password-back-to-login'
          text='Back to log in'
          type='primary'
          style={{ padding: '0px' }}
          size='medium'
          variant='text'
        />
        <DottedPaginator dots={4} id='send-password-paginator' selected={2} />
      </Box>
    </div>
  );
};

/**
 * Prop type definitions for SendPassword.
 */
SendPassword.propTypes = {
  id: PropTypes.string.isRequired
};

export default SendPassword;
