//@ts-nocheck

import { Button, Menu, MenuItem } from '@mui/material';
import { CaretDown, Lock, LockOpen, Link } from '@phosphor-icons/react';
import { useState } from 'react';
import { XGInput } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import { updateTripVisibility } from '../../../services/trip/trip';
import { useLoaderData } from 'react-router-dom';
import './ShareTab.css';
import * as PropTypes from 'prop-types';

const ShareTab = ({ tripid, isPublic, setIsPublic }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const { t } = useTranslation(['trip', 'common']);
  const [linkCopied, setLinkCopied] = useState(t('trip:copyLink'));
  const { tripId } = useLoaderData();
  const handleOnClose = async () => {
    setAnchorElement(null);
  };
  const landingPageLink = `${window.location.origin}/itineraries/${tripId}`;

  const handleIsPublicSelect = (option) => {
    if (option === 'public') {
      handleVisibilitySelection(true);
      setIsPublic(true);
    } else {
      handleVisibilitySelection(false);
      setIsPublic(false);
    }
    setAnchorElement(null);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(landingPageLink);
    setLinkCopied(t('trip:linkCopied'));
    setTimeout(() => {
      setLinkCopied(t('trip:copyLink'));
    }, 1500);
  };

  const handleVisibilitySelection = async (visibility) => {
    await updateTripVisibility(tripid, {
      ispublic: visibility
    });
  };

  return (
    <div>
      <div className='trip-modal-box__header'>
        <h3 className='trip-modal-title'>{t('trip:shareItinerary')}</h3>
        <div className='trip-modal-menu_Option'>
          <span>{isPublic ? <LockOpen size={20} /> : <Lock size={20} />}</span>
          <span style={{ marginLeft: '8px' }}>
            {isPublic ? 'Public' : 'Private'}
          </span>
          <Button
            onClick={(event) => setAnchorElement(event.currentTarget)}
            size='small'
            sx={{ minWidth: '20px' }}
          >
            <CaretDown
              size={17}
              style={{ cursor: 'pointer' }}
              color='#667085'
              fill='#667085'
            />
          </Button>

          <Menu
            anchorEl={anchorElement}
            open={Boolean(anchorElement)}
            onClose={handleOnClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {[
              { value: 'public', label: 'Public' },
              { value: 'private', label: 'Private' }
            ].map(({ value, label }) => {
              const disabled = isPublic
                ? value === 'public'
                  ? true
                  : false
                : value === 'private'
                  ? true
                  : false;

              return (
                <MenuItem
                  key={value}
                  onClick={() => handleIsPublicSelect(value)}
                  disabled={disabled}
                >
                  {label}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
      <div className='share-dialog-inputLink'>
        <p className='share-dialog__subtitle'>{t('trip:linkToShare')}</p>
        <XGInput
          data-testid='trip-header-actions-modal-input'
          name='link'
          id='trips-link-input'
          readOnly={true}
          type='text'
          value={landingPageLink}
          icon
          customIcon={
            <div className='share-dialog-customIcon' onClick={copyToClipboard}>
              <Link size={20} />
              <span style={{ marginLeft: '8px' }}>{linkCopied}</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

ShareTab.propTypes = {
  tripid: PropTypes.number,
  isPublic: PropTypes.any,
  setIsPublic: PropTypes.any
};

export default ShareTab;
