//@ts-nocheck
import { statuses } from './tripStatuses/tripStatuses';

const defaultCode = 'DEFAULT';

/**
 * Retrieves the trip status settings for the given trips status code.
 *
 * @param {string} code - The trip status code
 * @return {Object} The trip status settings
 */
export const getStatusByCode = (statusCode = '') => {
  const tripStatus =
    statuses.find((st) => st.tripstatuscode === statusCode?.toUpperCase()) ||
    statuses.find((st) => st.tripstatuscode === defaultCode);

  return tripStatus;
};
