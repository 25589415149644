// @ts-nocheck
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import './OutputSkeleton.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom skeleton for the itinerary output.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 *
 * @returns {JSX.Element} - The rendered skeleton for the itinerary output.
 */

const OutputSkeleton = ({ id }) => {
  return (
    <Box id={id} className='output-skeleton' data-testid='output-skeleton'>
      <Box className='trip-output__branding-container' />
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={465}
        animation='wave'
      />
      <Box className='output-skeleton__header'>
        <Skeleton width={'30%'} height={45} />

        <Box className='output-skeleton__label__container'>
          <Box className='output-skeleton__label'>
            <Skeleton width={25} height={40} />
            <Skeleton width={210} height={30} />
          </Box>
          <Box className='output-skeleton__label'>
            <Skeleton width={25} height={40} />
            <Skeleton width={210} height={30} />
          </Box>
        </Box>

        <Skeleton width={'70%'} height={120} />
      </Box>

      <Box className='output-skeleton__tripday__container'>
        <Box className='output-skeleton__tripday__card'>
          <Skeleton
            variant='rectangular'
            width={'100%'}
            height={400}
            animation='wave'
            sx={{ borderRadius: '8px' }}
          />
          <Skeleton width={'30%'} height={45} />
          <Skeleton width={'70%'} height={80} />
        </Box>
      </Box>
    </Box>
  );
};

/**
 * Prop type definitions for OutputSkeleton.
 */
OutputSkeleton.propTypes = {
  id: PropTypes.string.isRequired
};

export default OutputSkeleton;
