/**
 * This array contains the statutes map to matching trip status type.
 */

export const statuses = [
  {
    id: 1,
    tripstatuscode: 'PROPOSAL',
    type: 'warning',
    customClass: '',
    size: 'medium',
    tripstatusname: 'Proposal'
  },
  {
    id: 2,
    tripstatuscode: 'QUOTE',
    type: 'default',
    customClass: 'MuiChip-colorBlueLight',
    size: 'medium',
    tripstatusname: 'Quote'
  },
  {
    id: 3,
    tripstatuscode: 'CONFIRMED',
    type: 'default',
    customClass: 'MuiChip-colorIndigo',
    size: 'medium',
    tripstatusname: 'Confirmed'
  },
  {
    id: 4,
    tripstatuscode: 'ACTIVE',
    type: 'success',
    customClass: '',
    size: 'medium',
    tripstatusname: 'Active'
  },

  {
    id: 5,
    tripstatuscode: 'CANCELLED',
    type: 'error',
    customClass: '',
    size: 'medium',
    tripstatusname: 'Cancelled'
  },
  {
    id: 6,
    tripstatuscode: 'DEFAULT',
    type: 'default',
    customClass: 'MuiChip-colorIndigo',
    size: 'medium',
    tripstatusname: 'Default'
  }
];
