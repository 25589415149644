// @ts-nocheck
import Box from '@mui/material/Box';
import UpdateActions from '../UpdateActions/UpdateActions';
import { XGModal } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom update modal component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the update day component.
 * @param {string} [props.isOpenModal] - Boolean value to indicate whether modal is open.
 * @param {Function} [props.onConfirm] - Callback function to handle form submission.
 * @param {Function} [props.onCancel] - Callback function to handle form closing.
 * @param {string} [props.description] - The description for the update modal.
 * @param {string} [props.title] - The title for the update modal.
 * @param {boolean} [props.isSubmitting] - Boolean value to indicate whether form is submitting.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the update modal component..
 */

const UpdateModal = ({
  id,
  isOpenModal,
  onConfirm,
  onCancel,
  description,
  title,
  isSubmitting,
  ...rest
}) => {
  return (
    <XGModal
      id={id}
      allowClosing={false}
      data-testid={id}
      actions={
        <UpdateActions
          onCancel={onCancel}
          onSubmit={onConfirm}
          isSubmitting={isSubmitting}
        />
      }
      dismissable={false}
      open={isOpenModal}
      title={title}
      labelledby={title}
      maxWidth='lg'
      scroll='body'
      onClose={onCancel}
      {...rest}
    >
      <Box className='create-trip-header' sx={{ maxWidth: '400px' }}>
        <p className='text-md-regular'>{description}</p>
      </Box>
    </XGModal>
  );
};

UpdateModal.propTypes = {
  id: PropTypes.string.isRequired,
  isOpenModal: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  isSubmitting: PropTypes.bool
};

export default UpdateModal;
