//@ts-nocheck
import { DataGrid } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import * as PropTypes from 'prop-types';
import './TripsList.css';
import { tripsColumns } from './TripsListColumns';
import { useNavigate } from 'react-router';
import { XGButton } from '@xg-cl/xg-cl';
import { Copy, Trash } from '@phosphor-icons/react';
import { cloneTrip, deleteTrip } from '../../../services/trip/trip';
import { ErrorToast } from '../../../utils/alerts';
import { useTranslation } from 'react-i18next';

const TripsList = ({
  trips,
  totalRecordsCount,
  onPaginationChange,
  currentPage
}) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const handleCellClickEvent = (params) => {
    const { field } = params;
    if (field === 'title') {
      navigate(`/trips/${params.id}`);
    }
  };

  const handleClickCopyTrip = async (id) => {
    try {
      const tripidCopy = await cloneTrip(id);
      navigate(`/trips/${tripidCopy.data[0].newtripid}`);
    } catch (error) {
      ErrorToast(t('errors:tripCouldNotBeCopied'));
    }
  };

  const handleDeleteTripClick = (id) => async (event) => {
    try {
      await deleteTrip(id);
      onPaginationChange(event, 1);
    } catch (error) {
      ErrorToast(t('errors:tripCouldNotBeDeleted', { reason: error.message }));
    }
  };

  const updatedColumns = [
    ...tripsColumns,
    {
      field: 'deleteTrip',
      headerClassName: 'trips-list__data-grid-header',
      headerName: '',
      sortable: false,
      width: 80,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <XGButton
            data-testid='delete-trip-button'
            id={`trips-list-delete-button-${params?.id}`}
            text={t('common:delete')}
            size='small'
            variant='text'
            type='primary'
            startIcon={<Trash size={12} />}
            onClick={handleDeleteTripClick(params?.id)}
          />
        );
      }
    },
    {
      field: 'cloneTrips',
      headerClassName: 'trips-list__data-grid-header',
      headerName: '',
      sortable: false,
      width: 200,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <XGButton
            data-testid='clone-trip-button'
            id={`trips-list-clone-button-${params?.id}`}
            text={t('common:copy')}
            size='small'
            variant='text'
            type='primary'
            startIcon={<Copy size={12} />}
            onClick={() => handleClickCopyTrip(params?.id)}
          />
        );
      }
    }
  ];

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <div style={{ height: '100%', minWidth: '850px' }}>
        <DataGrid
          className='trips-list__footer'
          xs={{
            '.MuiDataGrid-footerContainer': {
              justifyContent: 'center'
            }
          }}
          rows={trips}
          columns={updatedColumns}
          onCellClick={handleCellClickEvent}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
                page: 0
              }
            }
          }}
          slots={{
            pagination: (props) => (
              <Pagination
                count={Math.ceil(totalRecordsCount / 10)}
                siblingCount={0}
                boundaryCount={1}
                onChange={onPaginationChange}
                size='large'
                page={currentPage}
                showFirstButton
                showLastButton
                variant='string'
                {...props}
              />
            )
          }}
          disableRowSelectionOnClick
          disableColumnMenu
          columnHeaderHeight={44}
          paginationMode='server'
          rowCount={Math.ceil(totalRecordsCount / 10)}
        />
      </div>
    </div>
  );
};

TripsList.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      destination: PropTypes.string,
      startDate: PropTypes.string,
      lastModified: PropTypes.string
    })
  ),
  totalRecordsCount: PropTypes.number,
  onPaginationChange: PropTypes.func,
  currentPage: PropTypes.number
};

export default TripsList;
