// @ts-nocheck

import PassengersHeader from '../PassengersHeader/PassengersHeader';
import PassengersUpdateModal from '../PassengersUpdateModal/PassengersUpdateModal';
import useFetchData from '../../../../hooks/useFetchData';
import { useTranslation } from 'react-i18next';
import { WarningCircle } from '@phosphor-icons/react';
import { useLoaderData } from 'react-router-dom';
import { Box } from '@mui/material';
// import { useGridApiRef } from '@mui/x-data-grid';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { XGChip } from '@xg-cl/xg-cl';
import { useEffect, useState } from 'react';
import { PASSENGERFIELDS } from '../../../../constants/settingCodes';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';
import { useSelector, useDispatch } from 'react-redux';
import { updateAllRows } from '../../../../redux/slices/passengers/passengers';
import './PassengersPanel.css';

const PassengersPanel = () => {
  const { t } = useTranslation(['passengers']);
  const { tripId } = useLoaderData();
  const [initialStateRows, setInicialStateRows] = useState([]);
  const [initialStateColumns, setInicialStateColumns] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectColumns, setSelectColumns] = useState([]);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [bookingPassengerId, setBookingPassengerId] = useState();
  const [selectedRow, setSelectedRow] = useState({});
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.passengers);
  const gridApiRef = useGridApiRef();

  const {
    data: passengersData,
    isLoading: passengersDataLoading,
    error: passengersDataError
  } = useFetchData(`/bookingpassengers/${tripId}`);

  const { data: settingsData, isLoading: passengersSettingsLoading } =
    useFetchData(`/tenant/settings/${PASSENGERFIELDS}`);

  const handleCellClickEvent = (params) => {
    const { field, id } = params;
    if (field === 'firstname') {
      setSelectedRow(rows.rows.find((item) => item.id == id));
      setBookingPassengerId(passengersData.passengers[id].bookingpassengerid);
      setOpenUpdateDialog(true);
    }
  };

  const toggleUpdateDialog = () => {
    setOpenUpdateDialog(!openUpdateDialog);
  };

  const handleColumnsFilter = (selectedColumns) => {
    const columnsCopy = [...initialStateColumns];
    const rowsCopy = [...initialStateRows];

    const filteredColumns = columnsCopy.filter((column) => {
      return selectedColumns.includes(column.field);
    });

    setColumns(filteredColumns);

    const filteredRows = rowsCopy.map((passenger) => {
      return selectedColumns.reduce((contactField, field) => {
        if (passenger[field] !== undefined) {
          contactField[field] = passenger[field];
        }
        return contactField;
      }, {});
    });

    dispatch(
      updateAllRows({
        rows: filteredRows
      })
    );
  };

  useEffect(() => {
    const flattenPassenger = (passengersData) => {
      const { bookingid, contact } = passengersData;
      const filteredContact = visibleFields.reduce((contactField, field) => {
        if (field in contact) {
          if (field === 'birthdate') {
            contactField[field] = contact[field]
              ? formatDate(
                  utcToZonedTime(
                    new Date(contact[field].split('T')[0].toString()),
                    'UTC'
                  ),
                  'dd MMM yyyy',
                  { timeZone: 'UTC' }
                )
              : null;
          } else {
            contactField[field] = contact[field];
          }
        }
        return contactField;
      }, {});
      return { bookingid, ...filteredContact };
    };

    const generatedRows =
      !passengersDataLoading && !passengersDataError && passengersData
        ? passengersData.passengers.map((passenger, index) => ({
            id: index,
            ...flattenPassenger(passenger)
          }))
        : [];

    dispatch(
      updateAllRows({
        rows: generatedRows
      })
    );
    setInicialStateRows(generatedRows);
  }, [
    dispatch,
    passengersData,
    passengersDataLoading,
    passengersDataError,
    visibleFields
  ]);

  useEffect(() => {
    const generateColumns = (fields) => {
      if (!fields.length) return [];
      const bookingid = {
        field: 'bookingid',
        headerName: 'Booking id',
        renderCell: (params) => (
          <XGChip
            id='passenger-panel-grid-chip'
            text={`#${params.value}`}
            size='small'
            type='default'
            variant='outlined'
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        ),
        width: 150
      };
      const titles = fields.map((field) => {
        return {
          field: field.field,
          headerName: field.label,
          width: field.field === 'email' ? 300 : 200,
          cellClassName:
            field.field === 'firstname'
              ? 'passengers-panel-data-grid-firstname'
              : null
        };
      });

      return [bookingid, ...titles];
    };

    if (settingsData && Object.keys(settingsData).length > 0) {
      const settings = JSON.parse(settingsData?.settingvalue);

      const fields = settings.filter((field) => field.display !== 'HIDDEN');
      setSelectColumns(fields);
      const generatedColumns = generateColumns(fields);

      setColumns(generatedColumns);
      setInicialStateColumns(generatedColumns);

      const visibleFields = fields.map((field) => field.field);
      setVisibleFields(visibleFields);
    }
  }, [settingsData]);

  return (
    <Box height={rows.rows.length === 0 ? '100px' : 'auto'}>
      {passengersDataLoading || passengersSettingsLoading ? (
        <h3>Loading</h3>
      ) : passengersData &&
        (passengersData.length === 0 ||
          passengersDataError ||
          (settingsData && Object.keys(settingsData).length === 0)) ? (
        <div className='no-passengers-found'>
          <div className='no-trips-found__search-container'>
            <WarningCircle size={32} />
          </div>
          <h2 className='h3-semibold no-passengers-found__title'>
            {t('common:noPassengersExist')}
          </h2>
        </div>
      ) : (
        passengersData &&
        settingsData && (
          <>
            <PassengersHeader
              options={selectColumns}
              onUpdateColumns={handleColumnsFilter}
              rows={rows}
              columns={columns}
              gridApiRef={gridApiRef}
            />
            <DataGrid
              className='passengers-datagrid'
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  background: 'var(--gray-50)'
                }
              }}
              columns={columns}
              rows={rows?.rows}
              onCellClick={handleCellClickEvent}
              initialState={{
                columns: { columnVisibilityModel: { id: false } }
              }}
              hideFooter
              disableColumnMenu
              disableColumnFilter
              disableRowSelectionOnClick={true}
              autosizeOnMount
              localeText={{ noRowsLabel: t('common:noDataAvailable') }}
              apiRef={gridApiRef}
            />
            <PassengersUpdateModal
              open={openUpdateDialog}
              setOpen={toggleUpdateDialog}
              passengerId={bookingPassengerId}
              data={selectedRow}
              columnsLabels={initialStateColumns}
            />
          </>
        )
      )}
    </Box>
  );
};

export default PassengersPanel;
