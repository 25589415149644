import request from '../request';

export const saveContentToLibrary = (data = {}) =>
  request.post('library/content', data).then((res) => res);

export const getContent = (type = '', timestamp = '') =>
  request
    .get(`library/content/${type}?timestamp=${timestamp}`)
    .then((res) => res);

export const updateContent = (contentid = '', data = {}) =>
  request.put(`library/content/${contentid}`, data).then((res) => res);

export const deleteContent = (contentid = '') =>
  request.delete(`library/content/${contentid}`).then((res) => res);
