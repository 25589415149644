import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  userId: ''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const { email, firstName, lastName, middleName, userId } = action.payload;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.middleName = middleName;
      state.userId = userId;
    },
    clearUser: (state) => {
      state.email = '';
      state.firstName = '';
      state.lastName = '';
      state.middleName = '';
      state.userId = '';
    }
  }
});

const { actions, reducer } = userSlice;

export const { updateUser, clearUser } = actions;

export default reducer;
