//@ts-nocheck
import MenuBar from './MenuBar';
import { useEditor, EditorContent } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { TextAlign } from '@tiptap/extension-text-align';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Link } from '@tiptap/extension-link';
import { Box, InputLabel, LinearProgress } from '@mui/material';
import { XGButton } from '@xg-cl/xg-cl';
import { Markdown } from 'tiptap-markdown';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './Editor.css';

/**
 * Functional React component for rendering a custom editor menu bar.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.content] - The content for the editor component.
 * @param {string} [props.id] - The ID for the editor menu bar component.
 * @param {boolean} [props.isPreviewing] - Boolean value to indicate whether the editor is previewing.
 * @param {string} [props.label] - The label for the editor menu bar component.
 * @param {Function} [props.setDescription] - The set value of the html output for the editor menu bar component.
 * @param {Function} [props.setMarkdown] - The set value of the markdown output for the editor menu bar component.
 * @param {Function} [props.setIsPreviewing] - The set value of the isPreviewing output for the editor.
 * @param {boolean} [props.showActions] - Boolean value to indicate whether the editor actions should be shown.'
 * @param {boolean} [props.editable] - Boolean value to indicate whether the editor should be editable.
 * @param {Function} [props.onCancelAction] - The callback function to be called when the cancel button is clicked.
 * @param {Function} [props.onSaveAction] - The callback function to be called when the save button is clicked.
 * @param {string} [props.placeholder] - The placeholder text for the editor.
 * @param {node} [props.generatePrompt] - The component to render for generating AI content.
 * @param {Object} [props.style] - Custom styles to apply to the editor content.
 * @param {boolean} [props.isEditorDisabled] - Custom styles to disable the content of the Editor.
 *
 * @returns {JSX.Element} React element representing the editor menu bar.
 */

const Editor = ({
  content,
  editable = true,
  isPreviewing = false,
  id,
  label,
  setDescription,
  setMarkdown,
  setIsPreviewing,
  showActions,
  onCancelAction,
  onSaveAction,
  placeholder,
  generatePrompt,
  style,
  isEditorDisabled,
  ...props
}) => {
  const editorRef = useRef(null);

  const editor = useEditor({
    content: `${content || ''}`,
    editorProps: {
      attributes: {
        spellcheck: false
      }
    },
    editable: editable,
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3]
        }
      }),
      Markdown.configure({
        transformPastedText: true,
        transformCopiedText: true
      }),
      TextAlign.configure({
        types: ['paragraph', 'heading']
      }),
      Placeholder.configure({
        placeholder: `${placeholder || ''}`
      }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: 'markdown__link',
          rel: null,
          target: '_blank'
        }
      })
    ],
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const markdown = editor.storage.markdown.getMarkdown();
      setDescription(html);
      setMarkdown(markdown);
    },
    onFocus() {
      if (editable) {
        setIspreviewingEditor(false);
      }
    }
  });

  const setIspreviewingEditor = (value = false) => {
    if (typeof setIsPreviewing === 'function') {
      setIsPreviewing(value);
    }
  };

  const onCancel = () => {
    if (typeof onCancelAction === 'function') {
      onCancelAction();
    }

    editor.commands.setContent(content);
    setIspreviewingEditor(true);
  };

  const onSave = async () => {
    if (typeof onSaveAction === 'function') {
      await onSaveAction();
      setIspreviewingEditor(true);
    }
  };

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  useEffect(() => {
    if (editor && isPreviewing) {
      editor.commands.blur();
    }
  }, [isPreviewing, editor]);

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box
      {...props}
      className={`editor ${isPreviewing ? 'text-editor__preview' : ''} ${isPreviewing && editable ? 'text-editor__preview--editable' : ''} ${props?.className || ''} `}
      id={id}
      data-testid='md-editor'
      ref={editorRef}
      style={{
        pointerEvents: isEditorDisabled ? 'none' : 'auto',
        opacity: isEditorDisabled ? 0.5 : 1
      }}
    >
      {label && (
        <InputLabel
          className='xg-input-label'
          data-testid='xg-input-label'
          sx={{ textAlign: 'left', marginBottom: '8px' }}
          shrink={false}
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      <Box className='text-editor' data-testid='md-text-editor'>
        {!isPreviewing && editable && (
          <>
            <MenuBar
              editor={editor}
              editorRef={editorRef}
              isEditing={!isPreviewing}
            />
          </>
        )}

        {generatePrompt}

        {isEditorDisabled && (
          <Box className='editor-progress'>
            <LinearProgress />
          </Box>
        )}

        <Box className='editor__content-area'>
          <EditorContent editor={editor} style={style} />
        </Box>

        {showActions && editable && !isPreviewing && (
          <Box className='editor-buttons'>
            <XGButton
              id='editor-cancel-button'
              data-testid='editor-cancel-button'
              size='medium'
              variant='contained'
              type='secondary'
              text='Cancel'
              onClick={onCancel}
              disabled={isEditorDisabled}
            />
            <XGButton
              id='editor-save-button'
              data-testid='editor-save-button'
              size='medium'
              variant='contained'
              type='primary'
              text='Save'
              onClick={onSave}
              disabled={isEditorDisabled}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
  isPreviewing: PropTypes.bool,
  editable: PropTypes.bool,
  content: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  setDescription: PropTypes.func,
  setMarkdown: PropTypes.func,
  setIsPreviewing: PropTypes.func,
  showActions: PropTypes.bool,
  onCancelAction: PropTypes.func,
  onSaveAction: PropTypes.func,
  placeholder: PropTypes.string,
  generatePrompt: PropTypes.node,
  style: PropTypes.object,
  isEditorDisabled: PropTypes.bool
};

export default Editor;
