//@ts-nocheck
import * as PropTypes from 'prop-types';

const CloseIcon = ({ height = 24, width = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='x-close'>
        <path
          id='Icon'
          d='M18 6L6 18M6 6L18 18'
          stroke='#667085'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

CloseIcon.propTypes = {
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  width: PropTypes.number
};

export default CloseIcon;
