//@ts-nocheck
import PaymentPanel from '../../components/Payment/PaymentPanel/PaymentPanel';
import Box from '@mui/material/Box';
import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import { getBookingById } from '../../services/booking/booking';
import { useEffect, useState } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import { getStripeConfig } from '../../services/stripe/stripe';
import { getOutputTrip } from '../../services/output/trip';
import * as PropTypes from 'prop-types';
import './FinalBookingPayment.css';

/**
 * This is the FinalBookingPayment page component.
 *
 * @namespace Pages
 */

const FinalBookingPayment = ({ id }) => {
  const { bookingId } = useLoaderData();
  const [isBookingLoading, setIsBookingLoading] = useState(true);
  const [didTripFetchSucceed, setDidTripFetchSucceed] = useState(true);
  const [bookingData, setBookingData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingKey = queryParams.get('bookingkey');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bookingResult = await getBookingById(bookingId, bookingKey);

        if (bookingResult?.data) {
          const [promiseConfig, promiseTrip] = await Promise.all([
            getStripeConfig(),
            getOutputTrip(bookingResult?.data?.tripid)
          ]);

          const { data: dataConfig } = promiseConfig;
          const { data: tripData } = promiseTrip;

          setBookingData({ ...bookingResult?.data, tripData, dataConfig });
        } else {
          setDidTripFetchSucceed(false);
        }
      } catch (error) {
        setDidTripFetchSucceed(false);
      } finally {
        setIsBookingLoading(false);
      }
    };
    fetchData();
  }, [bookingId, bookingKey]);

  return (
    <Box
      className='final-booking-payment'
      id={id}
      data-testid='final-booking-payment'
    >
      {!didTripFetchSucceed ? (
        <NotFoundPage id='not-found-page' />
      ) : (
        !isBookingLoading &&
        bookingData && (
          <PaymentPanel id='payment-panel' bookingData={bookingData} />
        )
      )}
    </Box>
  );
};

/**
 * Prop type definitions for FinalBookingPayment.
 */
FinalBookingPayment.propTypes = {
  id: PropTypes.string.isRequired
};

export default FinalBookingPayment;
