//Hardcoded standard fields for passenger form for now
const standardFields = [
  {
    field: 'firstname',
    label: 'First Name',
    seq: 1,
    datatype: 'string',
    display: 'REQUIRED'
  },
  {
    field: 'lastname',
    label: 'Last Name',
    seq: 3,
    datatype: 'string',
    display: 'REQUIRED'
  },
  {
    field: 'email',
    label: 'Email Address',
    seq: 4,
    datatype: 'email',
    display: 'REQUIRED'
  }
];

export default standardFields;
