// @ts-nocheck
import { DotsSixVertical, Trash } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import actionTypes from '../../../constants/actionTypes';
import * as PropTypes from 'prop-types';
import './DaysNavMenuItem.css';

/**
 * Functional React component for rendering a custom trip day item.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 *  @param {boolean} [props.active] - A boolean indicating whether the item is active.
 * @param {string} [props.id] - The ID for the trip day item component.
 * @param {Object} [props.item] - Object containing the data to build the trip day item.
 * @param {string} [props.onAction] - Callback function to handle actions.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the trip day item component.
 */

const DaysNavMenuItem = ({
  active,
  id,
  item,
  onAction,
  showDeleteButton,
  ...rest
}) => {
  const { t } = useTranslation(['trip', 'common']);

  return (
    <li
      data-testid='days-nav-menu-item'
      id={id}
      className={'days-nav-menu-item ' + (active ? 'active' : '')}
      onClick={(evt) => onAction(evt, actionTypes.SELECT, item.tripdayid)}
      {...rest}
    >
      <div className='days-nav-menu-item__draggable'>
        <DotsSixVertical color='#344054' size={24} />
      </div>
      <span className='text-md-semibold text-ellipsis'>
        {`${t('day')} #${item.daynumber}${
          item.title ? ' - ' + item.title : ''
        }`}
      </span>
      {showDeleteButton && (
        <div
          className='days-nav-menu-item__delete'
          onClick={(evt) => onAction(evt, actionTypes.DELETE, item.tripdayid)}
        >
          <Trash size={20} color='#667085' />
        </div>
      )}
    </li>
  );
};

DaysNavMenuItem.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string.isRequired,
  item: PropTypes.object,
  onAction: PropTypes.func,
  showDeleteButton: PropTypes.bool
};

export default DaysNavMenuItem;
