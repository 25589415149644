// @ts-nocheck
import PropTypes from 'prop-types';
import PlaceholderLogo from '../../assets/images/PlaceholderLogo';

/**
 * Component for displaying the tenant's logo or a placeholder logo.
 *
 * @param {string} tenantLogo - The URL of the tenant's logo.
 * @param {string} tenantName - The name of the tenant, used for the image's alt attribute.
 * @param {string} width - The width of the logo.
 * @param {Object} rest - Additional props to be spread onto the image element.
 * @returns {JSX.Element} - The TenantLogo component.
 */

const TenantLogo = ({ tenantLogo, tenantName, width, ...rest }) => {
  return (
    <>
      {tenantLogo ? (
        <img
          id={`${tenantName}-${Math.random().toString(36).slice(2, 8)}`} // Unique ID based on tenantName
          src={tenantLogo}
          alt={tenantName || ''}
          draggable='false'
          style={{ width }}
          {...rest}
        />
      ) : (
        <PlaceholderLogo />
      )}
    </>
  );
};

TenantLogo.propTypes = {
  tenantLogo: PropTypes.string,
  tenantName: PropTypes.string,
  width: PropTypes.string
};

export default TenantLogo;
