//@ts-nocheck
import AuthHeader from '../../components/AuthHeader/AuthHeader';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState, useEffect } from 'react';
import { XGButton, XGInput } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import { isEmailValid } from '../../utils/emailValidator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signIn } from './../../services/auth/auth';
import { useTranslation } from 'react-i18next';
import { getTenant } from './../../services/tenant/tenant';
import './Login.css';
import ImportScript from '../../hooks/useImportScript';
import pendo from '../../assets/pendo';
import { Modal } from '@mui/material';
import { Info } from '@phosphor-icons/react';
import TenantLogo from '../../components/TenantLogo/TenantLogo';

/**
 * This is the login page component.
 *
 * @namespace Pages
 */

const Login = ({ id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tenant, setTenant] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [openAlertDialog, setOpenAlertDialog] = useState(true);
  const [searchParams] = useSearchParams();
  const sessionExpired = searchParams.get('sessionExpired');

  const getTenantData = async () => {
    const response = await getTenant();
    if (response.data) {
      const {
        basecurrency,
        sellingcurrency,
        tenantdomain,
        tenantname,
        tenantlogo,
        tenantid
      } = response.data;

      const tenantData = {
        domain: tenantdomain,
        name: tenantname,
        logo: tenantlogo,
        tenantid: tenantid,
        basecurrency,
        sellingcurrency
      };

      setTenant(tenantData);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      getTenantData();
    }

    return () => {
      ignore = true;
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const areFieldsValid = () => {
    const { email, password } = formData;

    if (email === '') {
      setEmailError(t('validation.emailAddresIsRequired'));
      return false;
    }

    if (!isEmailValid(email)) {
      setEmailError(t('validation.pleaseEnterAValidEmailAddress'));
      return false;
    }

    if (password === '') {
      setPasswordError(t('validation.passwordIsARequiredField'));
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const { email, password } = formData;

      if (!areFieldsValid()) {
        return;
      }

      const result = await signIn({ login: email, password });

      if (!result.data && result.error) {
        setLoginError(true);
      } else {
        const { accessToken, firstName, lastName, middleName, login, userId } =
          result.data;

        const userSession = {
          email: login,
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          userId: userId
        };

        // Enable Pendo only for PRODUCTION
        if (import.meta.env.PROD) {
          await loadingPendo(userSession, tenant);
        }

        const serializedUserSession = JSON.stringify(userSession);
        const serializedTenantData = JSON.stringify(tenant);

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('userSession', serializedUserSession);
        localStorage.setItem('tenantData', serializedTenantData);
        navigate('/trips');
      }
    } catch (err) {
      setLoginError(true);
    }
  };

  // Pendo
  const loadingPendo = async (userSession, tenant) => {
    // Loading Pendo
    ImportScript(pendo);
    // Initialize Pendo
    window.pendo.initialize({
      visitor: {
        id: userSession?.userId,
        email: userSession?.login,
        firstName: userSession?.firstName,
        lastName: userSession?.lastName
      },
      account: {
        id: tenant?.tenantid,
        accountName: tenant?.name,
        accountDomain: tenant?.domain
      }
    });

    // Command to validate if Pendo is installed from Browser console
    // window.pendo.validateInstall();
  };

  const handleEmailOnFocus = () => {
    setLoginError(false);
    setEmailError('');
  };

  const handlePasswordOnFocus = () => {
    setLoginError(false);
    setPasswordError('');
  };

  const toggleAlertDialog = () => {
    setOpenAlertDialog(!openAlertDialog);
  };

  return (
    <div id={id} data-testid='login-page'>
      {sessionExpired ? (
        <Modal
          open={openAlertDialog}
          onClose={toggleAlertDialog}
          data-testid='session-expired-modal'
        >
          <Box className='session-expired-modal-box'>
            <div className='session-expired-box__header'>
              <h3 className='session-expired-title'>
                {t('common:sessionExpired')}
              </h3>
            </div>
            <div className='session-expired-modal-body'>
              <Info
                size={45}
                weight='light'
                style={{ color: 'var(--primary-600)' }}
              />
              <p className='session-expired-modal-body'>
                {t('common:loginAgain')}
              </p>
            </div>
            <div className='session-expired-dialog-close-button'>
              <XGButton
                data-testid='session-expired-dialog-close-button'
                id='dialog-close-button'
                text={'Log in'}
                type='primary'
                size='large'
                variant='outlined'
                onClick={toggleAlertDialog}
              ></XGButton>
            </div>
          </Box>
        </Modal>
      ) : null}
      <AuthHeader
        id='login-header'
        logo={
          <TenantLogo
            width='250px'
            tenantLogo={tenant?.logo}
            tenantName={tenant?.name}
          />
        }
        name={tenant && !tenant.logo && tenant.name ? tenant?.name : null}
        text={t('text.subtitle')}
        title={t('text.title')}
      />
      <Card
        data-testid='login-page-content'
        sx={{
          padding: '32px 40px 32px 40px',
          borderRadius: '12px',
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '20px',
              alignSelf: 'stretch'
            }}
          >
            <XGInput
              error={loginError || emailError !== ''}
              hint={emailError}
              icon={loginError || emailError !== ''}
              customIcon={<ErrorOutlineIcon />}
              id='email-login-input'
              data-testid='login-email-input'
              label='Email*'
              name='email'
              onChange={handleChange}
              placeholder={t('placeHolder.email', { count: 1 })}
              type='email'
              value={formData.email}
              onFocus={handleEmailOnFocus}
            />
            <XGInput
              error={loginError || passwordError !== ''}
              hint={
                `${
                  loginError ? t('validation.emailOrPasswordIsInvalid') : ''
                }` || passwordError
              }
              icon
              id='password-login-input'
              label='Password*'
              name='password'
              onChange={handleChange}
              placeholder={t('placeHolder.password')}
              type='password'
              value={formData.password}
              onFocus={handlePasswordOnFocus}
              onKeyDown={onKeyDown}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              padding: '24px 0px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '24px',
              alignSelf: 'stretch'
            }}
          >
            <XGButton
              id='forgot-password'
              text={t('button.forgotPassword')}
              type='primary'
              style={{ padding: '0px' }}
              size='medium'
              variant='text'
              onClick={() => navigate('/forgot-password')}
            />
          </Box>
        </CardContent>
        <CardActions
          sx={{ width: '100%', '& button': { width: '100%' }, padding: 0 }}
        >
          <XGButton
            id='login-submit-button'
            data-testid='login-submit-button'
            text={t('button.signIn')}
            type='primary'
            size='large'
            variant='contained'
            onClick={handleSubmit}
          />
        </CardActions>
      </Card>
    </div>
  );
};

/**
 * Prop type definitions for Login.
 */
Login.propTypes = {
  id: PropTypes.string.isRequired
};

export default Login;
