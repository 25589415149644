//@ts-nocheck
/**
 * This is the BookingModalPage page component.
 *
 * @namespace Pages
 */

import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOutputTrip } from '../../services/output/trip';
import {
  addBooking,
  updateActiveStep,
  setUniqueSelectedQuantity
} from '../../redux/slices/booking/booking';
import BookingModal from '../../components/Booking/BookingModal/BookingModal';
import { Skeleton, CircularProgress, Box } from '@mui/material';

const BookingModalPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const dispatch = useDispatch();
  const booking = useSelector((state) => state.booking);
  const { tripid } = useParams();
  const [showBookingModal, setShowBookingModal] = useState(true);
  const navigate = useNavigate();

  const sortTripPlans = (tripPlans) => {
    if (Array.isArray(tripPlans)) {
      const sortedTripPlans = tripPlans.sort(
        (a, b) => Number(a.priceperperson) - Number(b.priceperperson)
      );

      return sortedTripPlans.map((tripPlan, index) => {
        return {
          ...tripPlan,
          selectedQuantity: index === 0 ? Number(tripPlan?.occupancy) : 0,
          depositamount: Number(tripPlan?.depositamount),
          maxavailable: Number(tripPlan?.maxavailable),
          priceperperson: Number(tripPlan?.priceperperson),
          isSelected: index === 0
        };
      });
    }

    return [];
  };

  const onOpenBookingModal = (open) => {
    if (open) {
      dispatch(updateActiveStep({ key: 'plan' }));
    } else {
      if (booking.bookingplans?.length) {
        dispatch(
          setUniqueSelectedQuantity({
            tripplanid: booking.bookingplans[0]?.tripplanid,
            quantity: booking.bookingplans[0]?.occupancy
          })
        );
      }
      navigate(-1);
    }

    setShowBookingModal(open);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getOutputTrip(tripid);

        if (data && Object.keys(data).length > 0) {
          dispatch(updateActiveStep({ key: 'plan' }));

          dispatch(
            addBooking({
              tenant: data?.tenant,
              bookingplans: sortTripPlans(data?.tripplan),
              trip: {
                tripname: data?.tripname,
                startdate: data?.startdate,
                tripid: data?.tripid,
                fees: data?.fees ?? []
              }
            })
          );
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
        setTimeout(() => setShowSpinner(false), 500); // Hide spinner and show skeleton after delay
      }
    };

    fetchData();
  }, [dispatch, tripid]);

  //This effect add a transparent background to the iframe
  useEffect(() => {
    document.documentElement.style.backgroundColor = 'transparent';
  }, []);

  const handleCloseIframe = (event) => {
    event.stopPropagation();
    event.preventDefault();
    //Send a message to the parent window(script listener)
    window.parent.postMessage({ action: 'close' }, '*');
  };

  const addCloseEventListener = useCallback((element) => {
    if (element) {
      element.addEventListener('click', handleCloseIframe);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const closeButton = document.querySelector('[data-xgcl-stng-close]');

      if (closeButton) {
        clearInterval(interval);
        addCloseEventListener(closeButton);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [addCloseEventListener]);

  useEffect(() => {
    const interval = setInterval(() => {
      const cancelButton = document.querySelector('[data-xgfe-stng-close]');

      if (cancelButton) {
        clearInterval(interval);
        addCloseEventListener(cancelButton);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [addCloseEventListener]);

  if (loading) {
    if (showSpinner) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%'
        }}
      >
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={1000}
          sx={{ border: '1px solid #EAECF0' }}
        />
      </Box>
    );
  }

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <BookingModal
        id='book-now-modal'
        isOpenModal={showBookingModal}
        updateIsOpen={onOpenBookingModal}
      />
    </div>
  );
};

export default BookingModalPage;
