// @ts-nocheck
import PlanList from '../PlanList/PlanList';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XGButton, XGRadio } from '@xg-cl/xg-cl';
import { useDispatch, useSelector } from 'react-redux';
import { useLoaderData } from 'react-router-dom';
import {
  LOADED_FROM_DB,
  NEW,
  TO_BE_DELETED,
  UPDATED
} from '../../../../redux/slices/trippricing/trippricingStatuses';
import {
  createTripPricingPlan,
  deleteTripPricingPlan,
  getTripPricingPlan,
  updateTripFees,
  updateTripPricingPlan
} from '../../../../services/trip/trip';
import {
  updateTripPrincing,
  addTripPlan,
  updateTripPlan,
  resetErrors
} from '../../../../redux/slices/trippricing/trippricing';
import { useEffect, useState, useCallback, useRef } from 'react';
import { PlusCircle } from '@phosphor-icons/react';
import { validateNumber } from '../../../../utils/numberValidator';
import { ErrorToast, SuccessToast } from '../../../../utils/alerts';
import { updateTrip } from '../../../../redux/slices/trip/trip';
import * as PropTypes from 'prop-types';
import './PrincingPanel.css';

/**
 * Functional React component for rendering a custom pricing panel for a trip.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the pricing panel component.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the pricing panel.
 */

const PricingPanel = ({ id, ...rest }) => {
  const { t } = useTranslation(['trip', 'common', 'trippricing']);
  const newPlanRef = useRef(null);
  const { tripId } = useLoaderData();
  const [tripplans, setTripplans] = useState([]);
  const { tripplans: reduxTripplans, originaltripplans } = useSelector(
    (state) => state.trippricing
  );
  const dispatch = useDispatch();
  const trip = useSelector((state) => state.trip);
  const [groupFees, setGroupFees] = useState([]);
  const [tripFees, setTripFees] = useState(trip.fees);

  const visibleFees = trip?.fees.filter((fee) => fee?.isvisible);

  const handleOnCancel = () => {
    dispatch(
      updateTripPrincing({
        tripplans: originaltripplans,
        originaltripplans: originaltripplans
      })
    );
  };

  const validateForm = () => {
    let error = {};

    dispatch(resetErrors());

    for (const tripplan of reduxTripplans) {
      const {
        maxavailable,
        occupancy,
        priceperperson,
        selectedradio,
        title,
        depositamount
      } = tripplan;

      if (!title || title.trim() === '') {
        error = { ...error, title: t('titleRequired') };
      }

      if (!validateNumber(priceperperson, 'float')) {
        error = { ...error, priceperperson: t('pricePerPersonRequired') };
      }

      if (priceperperson < 1) {
        error = { ...error, priceperperson: t('invalidPricePerPerson') };
      }

      if (
        depositamount &&
        (!validateNumber(depositamount) || parseInt(depositamount) < 1)
      ) {
        error = { ...error, depositamount: t('invalidDepositAmount') };
      }

      if (!validateNumber(occupancy) || parseInt(occupancy) < 1) {
        error = { ...error, occupancy: t('participantsPerBookingRequired') };
      }

      if (
        (selectedradio === 'limited' && !validateNumber(maxavailable)) ||
        parseInt(maxavailable) < 1
      ) {
        error = { ...error, maxavailable: t('quantityRequired') };
      }

      if (Object.keys(error).length > 0) {
        dispatch(
          updateTripPlan({
            tripplanid: tripplan.tripplanid,
            error: { ...error }
          })
        );

        return false;
      }
    }

    return true;
  };

  const handleOnSave = async () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      ErrorToast(t('trippricing:notSaved'));
      return;
    }

    const plansToBeCreated = reduxTripplans.filter(
      (plan) => plan?.status === NEW
    );
    const plansToBeDeleted = reduxTripplans.filter(
      (plan) => plan?.status === TO_BE_DELETED
    );
    const plansToBeUpdated = reduxTripplans.filter(
      (plan) => plan?.status === UPDATED
    );

    if (plansToBeCreated.length > 0) {
      const createPromises = plansToBeCreated.map((plan) => {
        return createTripPricingPlan({ ...plan, tripid: parseInt(tripId) });
      });

      await Promise.allSettled(createPromises);
    }

    if (plansToBeDeleted.length > 0) {
      const deletePromises = plansToBeDeleted.map((plan) => {
        return deleteTripPricingPlan(plan.tripplanid);
      });

      await Promise.allSettled(deletePromises);
    }

    if (plansToBeUpdated.length > 0) {
      const updatePromises = plansToBeUpdated.map((plan) => {
        return updateTripPricingPlan(plan.tripplanid, plan);
      });

      await Promise.allSettled(updatePromises);
    }

    const feesToBeUpdated = tripFees.map((fee) => ({
      feeid: fee.feeid,
      isincluded: fee.isincluded
    }));

    dispatch(updateTrip({ fees: tripFees }));

    await updateTripFees(tripId, { fees: feesToBeUpdated });

    SuccessToast(t('trippricing:saved'));
    getDatabaseTripplans();
  };

  const getDatabaseTripplans = useCallback(async () => {
    const newPlans = await getTripPricingPlan(tripId);
    if (Object.keys(newPlans?.data).length > 0 && !newPlans.error) {
      const tripplans = newPlans?.data.pricingPlans;
      if (tripplans.length > 0) {
        const modifiedTripplans = tripplans.map((plan) => {
          return {
            ...plan,
            occupancy: plan?.occupancy ? `${plan?.occupancy}` : '1',
            maxavailable: plan?.maxavailable ? `${plan?.maxavailable}` : null,
            error: {},
            selectedradio:
              !plan?.maxavailable || isNaN(plan?.maxavailable)
                ? 'unlimited'
                : 'limited',
            status: LOADED_FROM_DB
          };
        });
        setTripplans(modifiedTripplans);
      }
    }
  }, [tripId]);

  const scrollToNewPlan = useCallback(() => {
    if (newPlanRef.current) {
      setTimeout(() => {
        newPlanRef.current.scrollIntoView({ behavior: 'smooth' });
        newPlanRef.current.querySelector('input').focus();
      }, 200);
    }
  }, []);

  const onAddNewPlan = () => {
    const isformValid = validateForm();

    if (!isformValid) {
      return;
    }
    dispatch(addTripPlan());
    scrollToNewPlan();
  };

  const groupingFees =
    visibleFees &&
    Object.values(
      visibleFees.reduce((acc, fee) => {
        const { paymentref, isincluded, description } = fee;
        if (!acc[paymentref]) {
          acc[paymentref] = { paymentref, isincluded, description };
        }
        return acc;
      }, {})
    );

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    const valueBolean = value === 'organizer' ? true : false;
    const updatedFees = groupFees.map((fee) => {
      if (fee.paymentref === name) {
        return {
          ...fee,
          isincluded: valueBolean
        };
      }
      return fee;
    });
    setGroupFees(updatedFees);

    const newTripFees = tripFees.map((fee) => {
      if (fee.paymentref === name) {
        return {
          ...fee,
          isincluded: valueBolean
        };
      }
      return fee;
    });
    setTripFees(newTripFees);
  };

  useEffect(() => {
    setGroupFees(groupingFees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Setup redux state based on data loaded from db
  useEffect(() => {
    dispatch(
      updateTripPrincing({
        tripplans: tripplans,
        originaltripplans: tripplans
      })
    );
  }, [tripplans, dispatch]);

  //Loads the data from the database
  useEffect(() => {
    getDatabaseTripplans();
  }, [tripId, dispatch, getDatabaseTripplans]);

  return (
    <Box
      id={id}
      className='trip__pricing-panel'
      data-testid='trip-pricing-panel'
      {...rest}
    >
      <PlanList id='trip-pricing-plan-list' plansRef={newPlanRef} />

      <Box className='trip__pricing-list__add'>
        <XGButton
          id='pricing-list-add-button'
          data-testid='pricing-list-add-button'
          text={t('addPlan')}
          type='primary'
          style={{ padding: '0px' }}
          size='medium'
          variant='text'
          onClick={onAddNewPlan}
          startIcon={<PlusCircle size={20} />}
        />
      </Box>
      <Box className='trip-pricing-fees'>
        <hr className='trip-princing-line' />
        <h4 className='trip-pricing-label-fees'>
          {t('trippricing:whoPaysFees')}
        </h4>

        {groupFees &&
          groupFees.map((fee, index) => {
            return (
              <div key={index}>
                <p className='text-md-semibold'>{fee.description}</p>
                <div className='trip-pricing-radio-fees'>
                  <XGRadio
                    id={`availability-radio-organizer-${index}`}
                    name={fee.paymentref}
                    label={t('trippricing:organizer')}
                    size='small'
                    value='organizer'
                    checked={fee.isincluded}
                    onChange={handleRadioChange}
                  />
                  <XGRadio
                    id={`availability-radio-participant-${index}`}
                    name={fee.paymentref}
                    label={t('trippricing:participant')}
                    size='small'
                    value='participant'
                    checked={!fee.isincluded}
                    onChange={handleRadioChange}
                  />
                </div>
              </div>
            );
          })}
      </Box>
      <Box className='trip__pricing-panel__footer'>
        <XGButton
          id='pricing-panel-cancel-button'
          data-testid='pricing-panel-cancel-button'
          size='large'
          variant='contained'
          type='secondary'
          text={t('common:cancel')}
          onClick={handleOnCancel}
        />
        <XGButton
          id='pricing-panel-save-button'
          data-testid='pricing-panel-save-button'
          size='large'
          variant='contained'
          type='primary'
          text={t('common:save')}
          onClick={handleOnSave}
        />
      </Box>
    </Box>
  );
};

PricingPanel.propTypes = {
  id: PropTypes.string.isRequired
};

export default PricingPanel;
