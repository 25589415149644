import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: true
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    updateNavigation: (state, action) => {
      const { open } = action.payload;
      state.open = open;
    },
    clearNavigation: (state) => {
      state.open = true;
    }
  }
});

const { actions, reducer } = navigationSlice;

export const { updateNavigation, clearNavigation } = actions;

export default reducer;
