// @ts-nocheck
import { Box, Stack, Typography } from '@mui/material';
import { XGChip } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom title on the main header.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the title.
 * @param {string} [props.title] - The Title for the Header container.
 * @param {string} [props.chipLabel] - The label of the status for the Header container.
 * @param {string} [props.chipType] - The color type of the chip.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 
 *
 * @returns {JSX.Element} React element representing the Title.
 */

const Title = ({ chipLabel, chipType, id, title, ...rest }) => {
  return (
    <Box id={id} data-testid='main-header-title' {...rest}>
      <Stack direction='row' alignItems='center' gap='8px'>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '600'
          }}
        >
          {title}
        </Typography>
        {chipLabel && chipType && (
          <XGChip
            id='header-title-chip'
            data-testid='header-title-chip'
            text={chipLabel}
            color={chipType}
            variant='outlined'
          />
        )}
      </Stack>
    </Box>
  );
};

Title.propTypes = {
  chipLabel: PropTypes.string,
  chipType: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Title;
