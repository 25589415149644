// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shouldLibraryReload: false,
  isDragging: false,
  items: [],
  itinerary: {
    isTripDaysDraggableDisabled: false,
    isDayContentDraggableDisabled: false,
    isDayLineItemDraggableDisabled: false,
    isLibraryDraggableDisabled: false,
    isTripContentListDraggableDisabled: false,
    isTripContentDataDraggableDisabled: false,
    draggableAction: {}
  }
};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    addIsDragging: (state, action) => {
      const { isDragging = false } = action.payload;

      return {
        ...state,
        isDragging
      };
    },
    addShouldLibraryReload: (state, action) => {
      const { shouldLibraryReload = false } = action.payload;

      return {
        ...state,
        shouldLibraryReload
      };
    },
    addLibraryItems: (state, action) => {
      const { items = [] } = action.payload;

      return {
        ...state,
        items
      };
    },
    addDraggableStatus: (state, action) => {
      const { context, data } = action.payload;

      return {
        ...state,
        [context]: { ...data }
      };
    },
    addDraggableAction: (state, action) => {
      const { context, data } = action.payload;

      if (!state[context] || !state[context].draggableAction) {
        return {
          ...state,
          [context]: {
            draggableAction: { ...data }
          }
        };
      }

      return {
        ...state,
        [context]: {
          ...state[context],
          draggableAction: { ...data }
        }
      };
    },
    cleanDraggableStatus: (state) => {
      return {
        ...initialState,
        items: state.items ?? [],
        itinerary: {
          ...initialState.itinerary,
          draggableAction: state.itinerary.draggableAction
        }
      };
    },
    cleanDraggableAction: (state) => {
      return {
        ...state,
        itinerary: {
          ...state.itinerary,
          draggableAction: {}
        }
      };
    }
  }
});

const { actions, reducer } = librarySlice;

export const {
  addShouldLibraryReload,
  addIsDragging,
  addLibraryItems,
  addDraggableStatus,
  addDraggableAction,
  cleanDraggableStatus,
  cleanDraggableAction
} = actions;

export default reducer;
