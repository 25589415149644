// @ts-ignore
import { useState } from 'react';
import { Box, InputLabel } from '@mui/material';
import Editor from '../Editor/Editor';
import { Details } from '../Editor/Details';

const Test = () => {
  const [description, setDescription] = useState('');
  const [markdown, setMarkdown] = useState('');

  return (
    <Box display='grid' gap='24px' margin='24px'>
      <Box>
        <Editor
          id='editor'
          label='Description'
          setDescription={setDescription}
          setMarkdown={setMarkdown}
        />
      </Box>
      <Box>
        <InputLabel
          className='xg-input-label'
          sx={{ textAlign: 'left', marginBottom: '8px' }}
          shrink={false}
        >
          HTML
        </InputLabel>
        <Details id='editor-details' description={description} />
      </Box>
      <Box>
        <InputLabel
          className='xg-input-label'
          sx={{ textAlign: 'left', marginBottom: '8px' }}
          shrink={false}
        >
          Markdown
        </InputLabel>
        <Details id='editor-markdown' description={markdown} />
      </Box>
    </Box>
  );
};

export default Test;
