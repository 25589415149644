//Droppable AREAS IDs
export const CONTENT_LIBRARY_DROPPABLE_ID = 'CONTENT_LIBRARY_DROPPABLE_ID';
export const TRIP_DAYS_DROPPABLE_ID = 'TRIP_DAYS_DROPPABLE_ID';
export const TRIP_DAY_CONTENT_DROPPABLE_ID = 'TRIP_DAY_CONTENT_DROPPABLE_ID';
export const TRIP_DAY_LINEITEM_DROPPABLE_ID = 'TRIP_DAY_LINEITEM_DROPPABLE_ID';
export const TRIP_CONTENT_LIST_DROPPABLE_ID = 'TRIP_CONTENT_LIST_DROPPABLE_ID';
export const TRIP_CONTENT_ITEM_DROPPABLE_ID = 'TRIP_CONTENT_ITEM_DROPPABLE_ID';

//Custom drag and drop actions
export const CREATENEWDAY = 'CREATENEWDAY';
export const CREATE_NEW_ADDITIONAL_CONTENT = 'CREATENEWADDITIONALCONTENT';
export const UPDATEDAYCONTENT = 'UPDATEDAYCONTENT';
export const UPDATE_ADDITIONAL_CONTENT = 'UPDATE_ADDITIONAL_CONTENT';
export const REORGANIZEDAYS = 'REORGANIZEDAYS';
export const REORGANIZE_ADDITIONAL_ITEMS = 'REORGANIZE_ADDITIONAL_ITEMS';
