// @ts-nocheck
import DraggableElement from '../DraggableElement/DraggableElement';
import { memo } from 'react';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom draggable list.
 *
 * @namespace Components
 *
 * @param {Object} props - The properties of the component.
 * @param {Array} props.items - Data containing information for the element.
 *
 * @returns {JSX.Element} - The rendered draggable list component.
 */

const DraggableList = memo(function DraggableList({ items }) {
  return items.map((item, index) => (
    <DraggableElement item={item} index={index} key={item.id} />
  ));
});

/**
 * Prop type definitions for DraggableList.
 */
DraggableList.propTypes = {
  items: PropTypes.array
};

export default DraggableList;
