// @ts-nocheck
import { useState, useEffect } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  Button,
  ListItemText,
  ListItemIcon,
  Popover,
  List
} from '@mui/material';
import {
  MagicWand,
  CaretDown,
  CaretRight,
  Checks,
  ListPlus,
  Minus,
  Pen
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ErrorToast } from '../../utils/alerts';
import { getGenAIDescription } from '../../services/ai/ai';
import './AIGenerateButton.css';
import { XGButton, XGInput } from '@xg-cl/xg-cl';

/**
 * Functional React component for rendering a custom AIGenerateButton.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.id] - The ID for the AIGenerateButton.
 * @param {Number} [props.tripId] - The ID of the trip.
 * @param {string} [props.editorContent] - The content of the editor's textarea.
 * @param {Function} [props.setMarkdown] - Function to set markdown content in the parent component.
 * @param {Function} [props.setTripDescription] - Function to set trip description in the parent component.
 * @param {Function} [props.getAIDescription] - Function to get the trip description.
 * @param {Function} [props.setIsEditorDisabled] - Function to set the disabled state of the editor.
 * @param {boolean} [props.disableGenerateWithoutInput] - Flag to disable the generate button when there is no input.
 *
 * @returns {JSX.Element} React element representing the AIGenerateButton.
 */

const AIGenerateButton = ({
  id,
  tripId,
  editorContent,
  setMarkdown,
  setTripDescription,
  getAIDescription,
  setIsEditorDisabled,
  disableGenerate = false
}) => {
  const { t } = useTranslation(['trip', 'common']);
  const [inputValue, setInputValue] = useState('');
  const [buttonText, setButtonText] = useState(t('common:generate'));
  const [isGenerating, setIsGenerating] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  const options = [
    {
      label: t('common:promptInstructions.shortenContentLabel'),
      instruction: t('common:promptInstructions.shortenContentInstruction'),
      icon: <Minus size={20} />
    },
    {
      label: t('common:promptInstructions.lengthenContentLabel'),
      instruction: t('common:promptInstructions.lengthenContentInstruction'),
      icon: <ListPlus size={20} />
    },
    {
      label: t('common:promptInstructions.improveContentLabel'),
      instruction: t('common:promptInstructions.improveContentInstruction'),
      icon: <Checks size={20} />
    },
    {
      label: t('common:promptInstructions.changeToneLabel'),
      icon: <Pen size={20} />,
      subOptions: [
        {
          label: t('common:promptInstructions.excitingToneLabel'),
          instruction: t('common:promptInstructions.excitingToneInstruction')
        },
        {
          label: t('common:promptInstructions.friendlyToneLabel'),
          instruction: t('common:promptInstructions.friendlyToneInstruction')
        },
        {
          label: t('common:promptInstructions.professionalToneLabel'),
          instruction: t(
            'common:promptInstructions.professionalToneInstruction'
          )
        }
      ]
    }
  ];

  useEffect(() => {
    if (editorContent) {
      setButtonText(t('common:customize'));
    } else {
      setButtonText(t('common:generate'));
    }
  }, [editorContent, t]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleGenerateClick = async (instructions) => {
    setIsGenerating(true);
    setIsEditorDisabled(true);
    await generateDescription(instructions || inputValue);
    setIsEditorDisabled(false);
    setIsGenerating(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !isGenerating && inputValue) {
      handleGenerateClick(inputValue);
    }
  };

  const handleOnClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleOptionClick = (instruction) => {
    handleGenerateClick(instruction);
    handleOnClose();
  };

  const handleExpandClick = (event) => {
    if (subMenuAnchorEl) {
      setSubMenuAnchorEl(null);
    } else {
      setSubMenuAnchorEl(event.currentTarget);
    }
  };

  const handleExpandLeave = () => {
    setSubMenuAnchorEl(null);
  };

  const generateDescription = async (instructions) => {
    try {
      if (instructions) {
        const question = editorContent;
        const { data, error } = await getGenAIDescription({
          instructions,
          question
        });

        if (error) {
          ErrorToast(t('errors:tripDescriptionCouldNotBeGenerated'));
          return;
        }

        if (data) {
          setTripDescription(data);
          setMarkdown(data);
          return;
        }
      } else {
        const { data, error } = await getAIDescription(tripId);

        if (error) {
          ErrorToast(t('errors:tripDescriptionCouldNotBeGenerated'));
          return;
        }

        if (data) {
          setTripDescription(data);
          setMarkdown(data);
          return;
        }
      }
    } catch (err) {
      ErrorToast(t('errors:tripDescriptionCouldNotBeGenerated'));
    }
  };

  const isButtonDisabled = () => {
    if (buttonText === t('common:customize')) {
      return !inputValue;
    }
    if (buttonText === t('common:generate') && disableGenerate) {
      return !inputValue;
    }
    return false;
  };

  return (
    <Box data-testid='ai-generate' className='ai-generate-container'>
      <XGInput
        data-testid='ai-prompt-input'
        name='link'
        id='ai-prompt-input'
        type='text'
        placeholder={
          buttonText === t('common:customize')
            ? t('trip:customizeai')
            : t('trip:askai')
        }
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        icon
        customIcon={
          <Box className='ai-generate-button'>
            <XGButton
              data-testid='ai-generate-button'
              startIcon={
                <MagicWand
                  color={
                    isButtonDisabled()
                      ? 'var(--primary-300)'
                      : 'var(--primary-600)'
                  }
                  size={20}
                />
              }
              id={`ai-generate-button-${id}`}
              text={
                isGenerating
                  ? editorContent
                    ? t('common:customizing')
                    : t('common:generating')
                  : buttonText
              }
              type='primary'
              size='large'
              variant='text'
              className='ai-generate-button'
              onClick={() => handleGenerateClick(inputValue)}
              disabled={isGenerating || isButtonDisabled()}
              style={{ marginRight: '10px' }}
            />
            {editorContent && (
              <Button
                id='trip-header-dropdowm-button'
                data-testid='trip-header-dropdowm-button'
                aria-controls={open ? 'trip-header-dropdowm' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOnClickAnchor}
                size='small'
                className='caret-button'
                sx={{ minWidth: '20px' }}
              >
                <CaretDown size={17} weight='bold' className='caret-icon' />
              </Button>
            )}
          </Box>
        }
      />
      <Menu
        data-testid='trip-header-dropdown'
        id='trip-header-dropdown'
        aria-labelledby='trip-header-dropdowm-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className='menu-container'
      >
        {options.map((option, index) =>
          option.subOptions ? (
            <MenuItem
              key={index}
              onClick={handleExpandClick}
              aria-haspopup='true'
              aria-owns={subMenuOpen ? 'sub-menu' : undefined}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText primary={option.label} />
              <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                <CaretRight size={17} weight='bold' />
              </ListItemIcon>
              <Popover
                id='sub-menu'
                open={subMenuOpen}
                anchorEl={subMenuAnchorEl}
                onClose={handleExpandLeave}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left'
                }}
                onMouseLeave={handleExpandLeave}
              >
                <List>
                  {option.subOptions.map((subOption, subIndex) => (
                    <MenuItem
                      key={subIndex}
                      onClick={() => handleOptionClick(subOption.instruction)}
                    >
                      {subOption.label}
                    </MenuItem>
                  ))}
                </List>
              </Popover>
            </MenuItem>
          ) : (
            <MenuItem
              key={index}
              onClick={() => handleOptionClick(option.instruction)}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              {option.label}
            </MenuItem>
          )
        )}
      </Menu>
    </Box>
  );
};

AIGenerateButton.propTypes = {
  id: PropTypes.string.isRequired,
  tripId: PropTypes.number,
  editorContent: PropTypes.string,
  setMarkdown: PropTypes.func.isRequired,
  setTripDescription: PropTypes.func.isRequired,
  getAIDescription: PropTypes.func,
  disableGenerate: PropTypes.bool,
  setIsEditorDisabled: PropTypes.func
};

export default AIGenerateButton;
