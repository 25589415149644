// @ts-nocheck
import { Box } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import './SuccessfulPaymentMessage.css';
import { SUCCEEDED } from '../../../constants/stripe';
/**
 * Functional React component for rendering a custom  successful payment message.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID of the component.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the successful payment message.
 */

const SuccessfulPaymentMessage = ({ id, ...rest }) => {
  const { t } = useTranslation(['trip', 'common']);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');

  return (
    <Box
      className='payment-successfull-message'
      id={id}
      data-testid='payment-transaction-successful'
      {...rest}
    >
      <div className='payment-successfull-message__header'>
        <span className='payment-successfull-message__outer-circle'>
          <span className='payment-successfull-message__inner-circle'>
            <CheckCircle size={32} fill='#079455' />
          </span>
        </span>
      </div>
      <div className='payment-successfull-message__content'>
        <span className='text-xl-semibold'>
          {status !== SUCCEEDED
            ? t('paymentIsBeingProcessed')
            : t('paymentSuccessful')}
        </span>
      </div>
    </Box>
  );
};

SuccessfulPaymentMessage.propTypes = {
  id: PropTypes.string.isRequired
};

export default SuccessfulPaymentMessage;
