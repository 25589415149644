// @ts-nocheck

import BookingPlanActions from '../BookingPlanActions/BookingPlanActions';
import BookingPlanCard from '../BookingPlanCard/BookingPlanCard';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom booking plan options component .
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the booking plan options component.
 * @param {boolean} [props.isSubmitting] - The boolean value indicating whether the form is submitting.
 * @param {Function} [props.onHandleCancel] - The function to handle the form cancellation.
 * @param {Function} [props.onHandleNext] - The function to handle the form submission.
 * @param {Array} [props.bookingplans] - The booking plans.
 *
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the booking plan options component.
 */

const BookingPlan = ({
  id,
  onHandleCancel,
  onHandleNext,
  isSubmitting,
  bookingPlans,
  ...rest
}) => {
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const selectedPlans = bookingPlans?.filter(
    (plan) => plan.selectedQuantity > 0
  );

  return (
    <Box
      id={id}
      data-testid='booking-plan-options'
      {...rest}
      className='book-now-modal__payment-options'
    >
      <div className='book-now-modal__section-header'>
        <span className='h4-semibold '>{t('paymentPlans')}</span>
      </div>
      <div className='book-now-modal__plans-container'>
        {bookingPlans &&
          bookingPlans?.map((bookingPlan) => {
            return (
              <BookingPlanCard
                key={bookingPlan?.tripplanid}
                id={`${bookingPlan?.tripplanid}`}
                data={bookingPlan}
              />
            );
          })}
        <BookingPlanActions
          onCancel={onHandleCancel}
          onHandleNext={onHandleNext}
          isSubmitting={isSubmitting}
          disabledConfirm={selectedPlans?.length === 0}
        />
      </div>
    </Box>
  );
};

BookingPlan.propTypes = {
  onHandleNext: PropTypes.func,
  bookingPlans: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      depositamount: PropTypes.number,
      depositduedate: PropTypes.string,
      lastbookdate: PropTypes.string,
      maxavailable: PropTypes.number,
      occupancy: PropTypes.number,
      priceperperson: PropTypes.number,
      tripplanid: PropTypes.number,
      title: PropTypes.string,
      selectedQuantity: PropTypes.number,
      hideTotalBreakdown: PropTypes.bool
    })
  ),
  onHandleCancel: PropTypes.func,
  id: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool
};
export default BookingPlan;
