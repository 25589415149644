//@ts-nocheck

import { useEffect, useState } from 'react';
import request from './../services/request.js';

const useFetchData = (endpoint) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await request.get(endpoint);
        setData(response?.data || null);
      } catch (error) {
        setError(error.message || 'An error ocurred');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [endpoint]);

  return { data, isLoading, error };
};

export default useFetchData;
