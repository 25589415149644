// @ts-nocheck
import Editor from '../../Editor/Editor';
import destinationHelper from '../../../utils/destinationHelper';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateTripHeader } from '../../../services/trip/trip';
import { updateTrip } from '../../../redux/slices/trip/trip';
import { MapPin, CalendarBlank } from '@phosphor-icons/react';
import { parseISODateWithoutOffset } from '../../../utils/dateUtility';
import { format, add } from 'date-fns';
import { useState, useEffect, useRef, useMemo } from 'react';
import { XGAutocomplete, XGDatePicker } from '@xg-cl/xg-cl';
import { ErrorToast } from '../../../utils/alerts';
import AIGenerateButton from '../../AIGenerateButton/AIGenerateButton';
import { getTripDescription } from '../../../services/ai/ai';
import * as PropTypes from 'prop-types';
import './AboutTrip.css';

/**
 * Functional React component for rendering a custom details about the trip.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the about trip component.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} React element representing the about trip component.
 */

const AboutTrip = ({ id, ...rest }) => {
  const { getOptions, getSelectedDestination } = destinationHelper;
  const { t } = useTranslation(['trip', 'common']);
  const dispatch = useDispatch();
  const dateFormat = t('common:dateFormat');
  const trip = useSelector((state) => state.trip);
  const destinations = useSelector((state) => state.destinations);
  const { tripid, description, city, country, startdate } = trip;

  const [isEditingDestination, setIsEditingDestination] = useState(false);
  const [isEditingStartDate, setIsEditingStartDate] = useState(false);
  const [markdown, setMarkdown] = useState(description);
  const [tripDescription, setTripDescription] = useState(description);
  const [isEditorPreviewing, setIsEditorPreviewing] = useState(true);
  const [isEditorDisabled, setIsEditorDisabled] = useState(false);

  const tripDate = startdate || '';
  const startDate = tripDate ? parseISODateWithoutOffset(tripDate) : null;
  const endDate =
    startDate && add(new Date(startDate), { days: trip?.duration - 1 });
  const destinationRef = useRef(null);
  const startDateRef = useRef(null);

  const options = useMemo(
    () => getOptions(destinations) || [],
    [destinations, getOptions]
  );

  const selectedDestination = useMemo(
    () => getSelectedDestination(options, country, city) || null,
    [city, country, options, getSelectedDestination]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsEditingDestination(false);
        setIsEditingStartDate(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dialog = document.querySelector('[role="dialog"]');
      const presentation = document.querySelector(
        '[role="presentation"]:not(iframe)'
      );

      if (
        destinationRef.current &&
        destinationRef.current.contains(event.target)
      ) {
        setIsEditingStartDate(false);
        setIsEditingDestination(true);
      } else if (
        startDateRef.current &&
        startDateRef.current.contains(event.target)
      ) {
        setIsEditingDestination(false);
        setIsEditingStartDate(true);
      } else {
        if (!dialog && !presentation) {
          setIsEditingDestination(false);
          setIsEditingStartDate(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const updateTripDescription = async (newDescription) => {
    if (newDescription !== description) {
      const data = await updateTripData({ description: newDescription });
      if (data) {
        dispatch(updateTrip({ description: data.description }));
      }
    }
  };

  const updateTripDestination = async (newDestination) => {
    if (newDestination && newDestination !== selectedDestination) {
      const data = await updateTripData({ destination: newDestination });

      if (data) {
        const isCity = newDestination.countryId && newDestination.cityId;
        let updatedDestination = {};

        if (isCity) {
          const newCountry = options.find(
            (option) =>
              option.countryId === newDestination.countryId &&
              option.cityId === null
          );

          updatedDestination = {
            country: {
              countryid: newCountry.countryId,
              countryname: newCountry.title,
              countrycode: newCountry.code
            },
            city: {
              cityid: newDestination.cityId,
              countryid: newDestination.countryId,
              cityname: newDestination.title,
              countryname: newDestination.countryName,
              citycode: newDestination.code
            }
          };
        } else {
          updatedDestination = {
            country: {
              countryid: newDestination.countryId,
              countryname: newDestination.title,
              countrycode: newDestination.code
            },
            city: null
          };
        }

        dispatch(updateTrip(updatedDestination));
        setIsEditingDestination(false);
      }
    }
  };

  const updateTripStartDate = async (newStartDate) => {
    const data = await updateTripData({ startDate: newStartDate });

    if (data) {
      dispatch(updateTrip({ startdate: data.startdate }));
      setIsEditingStartDate(false);
    }
  };

  const updateTripData = async (newData) => {
    try {
      if (newData) {
        const { data } = await updateTripHeader(tripid, {
          ...newData
        });

        return data;
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
      return null;
    }
  };

  const onCancelEditor = () => {
    setMarkdown(description);
    setTripDescription(description);
  };

  const onSaveEditor = async () => {
    await updateTripDescription(markdown);
  };

  return (
    <Box
      id={id}
      className='trip-description__wrapper'
      data-testid='about-the-trip'
      {...rest}
    >
      <span
        className='MuiTypography-root MuiTypography-body2'
        style={{ fontSize: '14px' }}
      >
        {t('aboutTrip')}
      </span>

      <div className='trip-description__content'>
        <Editor
          editable={true}
          isPreviewing={isEditorPreviewing}
          content={tripDescription}
          id='trip-description-editor'
          setDescription={() => {}}
          setMarkdown={setMarkdown}
          setIsPreviewing={setIsEditorPreviewing}
          showActions={true}
          onCancelAction={onCancelEditor}
          onSaveAction={onSaveEditor}
          placeholder={`${t('enterDescription')}`}
          generatePrompt={
            !isEditorPreviewing ? (
              <div>
                <AIGenerateButton
                  id='ai-generate-trip-description-button'
                  tripId={tripid}
                  setMarkdown={setMarkdown}
                  setTripDescription={setTripDescription}
                  editorContent={markdown}
                  getAIDescription={getTripDescription}
                  setIsEditorDisabled={setIsEditorDisabled}
                />
              </div>
            ) : null
          }
          isEditorDisabled={isEditorDisabled}
        />
      </div>
      <div
        data-testid='about-inputs-container'
        className='trip-about-inputs__container'
      >
        <div className='container__item' ref={destinationRef}>
          <p
            className='MuiTypography-root MuiTypography-body2'
            style={{ fontSize: '14px' }}
          >
            {t('destination')}
          </p>
          {!isEditingDestination ? (
            <div
              className='content-editable item-lable__contianer'
              onClick={() => setIsEditingDestination(true)}
            >
              <div className='item--editable'>
                <MapPin fill='#475467' size={20} />
                {country || city ? (
                  <p className='text-md-semibold' style={{ color: '#475467' }}>
                    {country && country?.countryname}
                    {country && city ? ' , ' + city?.cityname : city?.cityname}
                  </p>
                ) : (
                  <p className='text-sm-regular'>{t('enterDestination')}</p>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                minWidth: '250px'
              }}
            >
              <XGAutocomplete
                startAdornment={[]} // Empty array for no start adornment
                endAdornment={<MapPin fill='#344054' size={20} />}
                placeholder={t('searchByCountryOrCity')}
                name='destination'
                id='trip-destination-input'
                options={options}
                value={selectedDestination}
                isOptionEqualToValue={(option, value) =>
                  option.title === value?.title
                }
                onChange={(event, newValue) => {
                  updateTripDestination(newValue);
                }}
              />
            </div>
          )}
        </div>
        <div className='container__item' ref={startDateRef}>
          <p
            className='MuiTypography-root MuiTypography-body2'
            style={{ fontSize: '14px' }}
          >
            {t('startDate')}
          </p>
          {!isEditingStartDate ? (
            <div
              className='content-editable item-lable__contianer'
              onClick={() => setIsEditingStartDate(true)}
            >
              <div className='item--editable'>
                <CalendarBlank color='#475467' size={20} />

                {startDate ? (
                  <p className='text-md-semibold' style={{ color: '#475467' }}>
                    {format(new Date(startDate), dateFormat)} -
                    {endDate && format(new Date(endDate), dateFormat)}
                  </p>
                ) : (
                  <p className='text-sm-regular'>{t('enterStartDate')}</p>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                maxWidth: '196px'
              }}
            >
              <XGDatePicker
                format={`${t('common:dateFormat')}`}
                id='trip-start-date-input'
                name='startDate'
                value={startDate}
                onChange={(selectedDate) => {
                  updateTripStartDate(selectedDate);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

AboutTrip.propTypes = {
  id: PropTypes.string.isRequired
};

export default AboutTrip;
