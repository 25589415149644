// @ts-nocheck

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CustomStepConnector from '../CustomStepConnector/CustomStepConnector';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import './CustomStepper.css';

/**
 * CustomStepper component renders a stepper with clickable steps.
 *
 * @param {string} id - The id for the stepper container.
 * @param {Array} steps - An array of step objects containing `active`, `id`, `key`, `shouldRender`, and `title`.
 * @param {function} onStepClick - Callback function when a step is clicked.
 * @param {object} rest - Any additional props to be passed to the stepper container.
 */

const CustomStepper = ({ id, steps, onStepClick, ...rest }) => {
  const activeIndex = steps.findIndex((step) => step.active);
  const active = activeIndex !== -1 ? activeIndex : -1;

  return (
    <Box
      id={id}
      sx={{ width: '100%' }}
      className='book-now__stepper'
      data-testid='book-now-stepper'
      {...rest}
    >
      <Stepper
        className='book-now__stepper-content'
        activeStep={active}
        alternativeLabel
        connector={<CustomStepConnector />}
      >
        {steps.map((step, index) => {
          const clickable = index <= active + 1;

          return (
            <Step
              key={step?.title}
              className={clickable ? 'clickable-step' : 'inactive-step'}
              onClick={(event) => clickable && onStepClick(event, index)}
            >
              <StepLabel
                className={clickable ? 'next-step' : ''}
                StepIconProps={{
                  className: clickable ? 'next-step-icon' : ''
                }}
              >
                {step?.title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

CustomStepper.propTypes = {
  id: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.number,
      key: PropTypes.string,
      shouldRender: PropTypes.bool,
      title: PropTypes.string
    })
  ).isRequired,
  onStepClick: PropTypes.func.isRequired
};

export default CustomStepper;
