//@ts-nocheck
import { parseISO } from 'date-fns';

export const getDateWithoutOffset = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const parseISODateWithoutOffset = (date) => {
  return date && typeof date === 'string' ? parseISO(date.split('T')[0]) : date;
};

export const getDayOfWeekName = (date) => {
  if (!date) {
    return '';
  }

  return date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
};
