// @ts-nocheck
import { useState, useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import './TemplatesPanel.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FontStyleConfig from './FontStyleConfig/FontStyleConfig';
import ColorConfig from './ColorConfig/ColorConfig';
import OutputDataConfig from './OutputDataConfig/OutputDataConfig';
import { XGButton } from '@xg-cl/xg-cl';
import Outputs from '../../GlobalSettings/Templates/Outputs/Outputs';
import { useDispatch, useSelector } from 'react-redux';
import { setHighlightColor } from '../../../redux/slices/color/color';
import { setHeadingFont, setBodyFont } from '../../../redux/slices/font/font';
import { SuccessToast, ErrorToast } from '../../../utils/alerts';
import {
  getTenantSettings,
  setTenantSettings
} from '../../../services/tenant/tenant';

const SETTINGCODE = 'TEMPLATEFIELDS';

/**
 * Functional React component for rendering the templates panel.
 *
 * @namespace Components
 *
 * @param {object} props - The properties of the component.
 * @param {string} props.id - The ID for the component.
 *
 * @returns {JSX.Element} - The rendered component.
 */
const TemplatesPanel = ({ id }) => {
  const [selectedOutput, setSelectedOutput] = useState('Crios');
  const { t } = useTranslation(['trip', 'common']);
  const dispatch = useDispatch();
  const highlightColor = useSelector((state) => state.color.highlightColor);
  const headingFont = useSelector((state) => state.font.headingFont);
  const bodyFont = useSelector((state) => state.font.bodyFont);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getTenantSettings(SETTINGCODE);
        if (!settingsData?.data || !settingsData?.data?.settingvalue) {
          dispatch(setHeadingFont('Inter'));
          dispatch(setBodyFont('Inter'));
          dispatch(setHighlightColor('#0D69D4'));
          setSelectedOutput('Crios');
        }
        if (settingsData?.data?.settingvalue) {
          const settingvalue = JSON.parse(settingsData.data.settingvalue)[0];
          const { name, headingfont, bodyfont, color } = settingvalue;
          dispatch(setHeadingFont(headingfont || 'Inter'));
          dispatch(setBodyFont(bodyfont || 'Inter'));
          dispatch(setHighlightColor(color || '#0D69D4'));
          setSelectedOutput(name || 'Crios');
        }
      } catch (error) {
        ErrorToast(t('common:errorLoadingSettings'));
      }
    };

    fetchSettings();
  }, [dispatch, t]);

  const updateSettings = async () => {
    const settings = {
      name: selectedOutput,
      headingfont: headingFont,
      bodyfont: bodyFont,
      color: highlightColor
    };

    try {
      await setTenantSettings(SETTINGCODE, [{ ...settings }]);
      SuccessToast(t('common:settingsSavedSuccessfully'));
    } catch (error) {
      ErrorToast(t('common:errorSavingSettings'));
    }
  };

  const handleColorChange = (color) => {
    dispatch(setHighlightColor(color));
  };

  const handleFontChange = (type, font) => {
    if (type === 'heading') {
      dispatch(setHeadingFont(font));
    } else if (type === 'body') {
      dispatch(setBodyFont(font));
    }
  };

  return (
    <Box id={id} className='templates-panel' data-testid='templates-panel'>
      <Box className='template-config-panel'>
        <Outputs
          selectedOutput={selectedOutput}
          setSelectedOutput={(output) => setSelectedOutput(output)}
        />
        <Box className='template-style-container'>
          <ColorConfig event={handleColorChange} />
          <FontStyleConfig onFontChange={handleFontChange} />
          <Divider orientation='vertical' />
          <XGButton
            id='output-save-templates-button'
            data-testid='output-save-templates-button'
            size='large'
            variant='contained'
            type='primary'
            text={t('common:save')}
            onClick={updateSettings}
          />
        </Box>
      </Box>
      <Box className='template-output-panel'>
        <OutputDataConfig
          id='output-template-preview'
          selectedOutput={selectedOutput}
        />
      </Box>
    </Box>
  );
};

TemplatesPanel.propTypes = {
  id: PropTypes.string
};

export default TemplatesPanel;
