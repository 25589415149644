// @ts-nocheck
import './PassengersFieldsTable.css';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableList from '../../Draggable/DraggableList/DraggableList';
import { reorderItems } from '../../../utils/common';
import PassengersFieldsRows from './PassengersFieldsRows';
import useFetchData from '../../../hooks/useFetchData';
import { useTranslation } from 'react-i18next';
import { setTenantSettings } from '../../../services/tenant/tenant';

const SETTINGCODE = 'PASSENGERFIELDS';

const PassengerFieldsTable = () => {
  const { t } = useTranslation(['settings']);
  const [isMounted, setIsMounted] = useState(false);
  const [mandatoryPassengersFields, setMandatoryPassengersFields] = useState(
    []
  );
  const [optionalPassengerFields, setOptionalPassengerFields] = useState([]);
  const [travelerFields, setTravelerFields] = useState([]);

  const {
    data: settingsData,
    isLoading,
    error
  } = useFetchData(`/tenant/settings/${SETTINGCODE}`);

  //Handles actions when an element is dropped
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedFields = reorderItems(
      optionalPassengerFields,
      result.source.index,
      result.destination.index
    );

    setOptionalPassengerFields(reorderedFields);
  };

  const handleFieldChange = (fieldObject, value) => {
    const optionalFieldsCopy = [...optionalPassengerFields];
    const index = optionalFieldsCopy.findIndex(
      (field) => field.field === fieldObject.field
    );

    if (index !== -1) {
      const updatedField = {
        ...optionalFieldsCopy[index],
        display: value
      };

      optionalFieldsCopy[index] = updatedField;

      setOptionalPassengerFields(optionalFieldsCopy);
    }
  };

  //On react 18 we need to load Droppable element after the component is mounted otherwise it won't work
  //See: https://github.com/atlassian/react-beautiful-dnd/issues/2399
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const setupTravelerFields = () => {
      if (
        optionalPassengerFields &&
        Object.keys(optionalPassengerFields).length > 0
      ) {
        const travelerFieldsList = [];
        optionalPassengerFields.map((field, index) => {
          const newField = {
            id: index,
            content: (
              <PassengersFieldsRows
                field={field}
                updatedField={handleFieldChange}
              />
            )
          };
          travelerFieldsList.push(newField);
        });
        setTravelerFields(travelerFieldsList);
      }
    };

    //TODO (improvement): Not to call this on first load
    const save = async () => {
      const finalList = [
        ...mandatoryPassengersFields,
        ...optionalPassengerFields
      ];
      const updatedSeq = finalList.map((field, index) => ({
        ...field,
        seq: index + 1
      }));
      if (updatedSeq.length) {
        await setTenantSettings(SETTINGCODE, updatedSeq);
      }
    };

    setupTravelerFields();
    save();
    // eslint-disable-next-line
  }, [optionalPassengerFields]);

  useEffect(() => {
    const setupPassengerFields = () => {
      if (settingsData && Object.keys(settingsData).length > 0) {
        const settings = settingsData?.settingvalue;
        if (settings.length === 0) {
          setMandatoryPassengersFields([]);
          setOptionalPassengerFields([]);
        }
        const settingsJson = JSON.parse(settings);
        const mandatoryFieldsList = [];
        const optionalFieldsList = settingsJson.filter((field) => {
          if (
            field.field === 'firstname' ||
            field.field === 'lastname' ||
            field.field === 'email'
          ) {
            mandatoryFieldsList.push(field);
          } else {
            return field;
          }
        });
        setMandatoryPassengersFields(mandatoryFieldsList);
        setOptionalPassengerFields(optionalFieldsList);
      }
    };
    setupPassengerFields();
  }, [settingsData, isLoading, error]);

  return (
    <Box className='passengersfields_box'>
      <table className='passengersfields_table'>
        <thead>
          <tr>
            <th>{t('settings:field')}</th>
            <th>{t('settings:display')}</th>
          </tr>
        </thead>
        <tbody>
          {mandatoryPassengersFields.map((field, index) => (
            <tr key={index}>
              <td>{field.label}</td>
              <td>{field.display}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <DragDropContext onDragEnd={onDragEnd}>
        {isMounted && travelerFields && (
          <Droppable droppableId='passengers-field-droppable'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='passengers-fields-drag-and-drop'
                //className={`${snapshot.isDraggingOver ? 'dragging' : 'not-dragging'}`}
              >
                <DraggableList items={travelerFields} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </Box>
  );
};

export default PassengerFieldsTable;
