//@ts-nocheck
import Box from '@mui/material/Box';
import PaymentSummary from '../PaymentTotals/PaymentSummary/PaymentSummary';
import PaymentForm from '../PaymentForm/PaymentForm';
import { useTranslation } from 'react-i18next';
import { stripeAppearance } from '../../../constants/stripe';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState, useEffect } from 'react';
import { createPaymentIntent } from '../../../services/stripe/stripe';
import { getFeesBreakdown } from '../../../utils/feesHelper';
import { ErrorToast } from '../../../utils/alerts';
import './PaymentPanel.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom payment panel.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the payment panel.
 * @param {string} [props.bookingData] - The ID for the payment panel.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the payment panel component.
 */
const PaymentPanel = ({ id, bookingData }) => {
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const {
    balancedue,
    totalprice,
    totalreceived,
    bookingid,
    fees,
    tripData,
    dataConfig
  } = bookingData;
  const { tripname } = tripData;
  const { publishableKey, stripeAccount, paymentMethods } = dataConfig;
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMethodOrder, setPaymentMethodOrder] = useState([]);
  const [totalsBreakdown, setTotalsBreakdown] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentIntentCreated, setIsPaymentIntentCreated] = useState(false);
  const [elementsKey, setElementsKey] = useState(new Date().getTime());

  const initialItems = [
    {
      id: 1,
      key: 'totalPrice',
      title: t('totalPrice'),
      amount: totalprice,
      show: true
    },
    {
      id: 2,
      key: 'totalReceived',
      title: t('totalReceived'),
      amount: totalreceived,
      show: true
    },

    {
      id: 3,
      key: 'balanceDue',
      title: t('balanceDue'),
      amount: balancedue,
      show: true
    },
    {
      id: 4,
      key: 'totalFees',
      title: t('trip:processingFee'),
      amount: totalsBreakdown?.totalFees ?? 0,
      show: totalsBreakdown?.totalFees > 0
    },
    {
      id: 5,
      key: 'amountToCharge',
      title: t('amountToCharge'),
      amount: totalsBreakdown?.totalWithFees ?? 0,
      hasDivider: true,
      show: true
    }
  ];

  useEffect(() => {
    setTotalsBreakdown(
      getFeesBreakdown(balancedue ?? 0, fees ?? [], paymentMethod)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  useEffect(() => {
    const createPayment = async () => {
      try {
        if (balancedue > 0) {
          if (dataConfig && tripData) {
            const paymentMethodCodes = paymentMethods?.map(
              (method) => method?.paymentcode
            );

            if (!publishableKey || !stripeAccount) {
              ErrorToast(t('errors:missingPaymentMethods'));
              return;
            }

            if (!paymentMethodCodes || !paymentMethodCodes?.length) {
              ErrorToast(t('errors:missingPaymentMethods'));
            }

            const breakdown = getFeesBreakdown(
              balancedue ?? 0,
              fees ?? [],
              paymentMethodCodes[0]
            );

            setPaymentMethodOrder(paymentMethodCodes);
            setStripePromise(
              loadStripe(publishableKey, {
                stripeAccount
              })
            );

            if (breakdown?.totalWithFees > 0) {
              const {
                totalWithFees,
                applicationFeeNotIncluded,
                applicationFeeIncluded
              } = breakdown;

              if (totalWithFees > 0) {
                setTotalsBreakdown(breakdown);

                const { data: dataIntent } = await createPaymentIntent({
                  amount: totalWithFees,
                  applicationFees:
                    applicationFeeNotIncluded + applicationFeeIncluded,
                  description: `${bookingid} - ${tripname}`,
                  paymentMethods: paymentMethodCodes
                });

                if (dataIntent) {
                  const { clientSecret, paymentIntentId } = dataIntent;

                  setClientSecret(clientSecret);
                  setPaymentIntent(paymentIntentId);
                  setIsPaymentIntentCreated(true);
                  setElementsKey(new Date().getTime());
                }
              }
            }
          }
        }
      } catch (error) {
        setClientSecret(null);
        setPaymentIntent(null);
        setPaymentMethodOrder(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isPaymentIntentCreated) {
      createPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentIntentCreated]);

  return (
    <Box className='payment-panel' id={id} data-testid='payment-panel'>
      <>
        {stripePromise && clientSecret && !isLoading && (
          <Box
            id={id}
            data-testid='booking-payment-panel'
            className='payment-panel__payment-options'
          >
            <Elements
              key={elementsKey}
              stripe={stripePromise}
              options={{
                clientSecret,
                appearance: stripeAppearance,
                fields: {
                  billingDetails: {
                    address: {
                      country: 'never'
                    }
                  }
                }
              }}
            >
              <PaymentForm
                id='booking-payment-form'
                data={{
                  baseTotal: balancedue,
                  amount: totalsBreakdown?.totalWithFees ?? 0,
                  fees: fees ?? [],
                  applicationFee:
                    totalsBreakdown?.applicationFeeNotIncluded ?? 0,
                  bookingid,
                  tenant: tripData?.tenant,
                  tripname: tripData?.tripname,
                  startdate: tripData?.startdate,
                  duration: tripData?.duration
                }}
                setPaymentMethod={setPaymentMethod}
                paymentIntentId={paymentIntent}
                paymentMethodOrder={paymentMethodOrder}
              />
            </Elements>
          </Box>
        )}
      </>
      {totalsBreakdown && !isLoading && (
        <PaymentSummary id='payment-summary' items={initialItems} />
      )}
    </Box>
  );
};

/**
 * Prop type definitions for PaymentPanel.
 */
PaymentPanel.propTypes = {
  id: PropTypes.string.isRequired,
  bookingData: PropTypes.object
};

export default PaymentPanel;
