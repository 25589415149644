// @ts-nocheck
import BookingTotalItem from '../BookingTotalItem/BookingTotalItem';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hasMixedPlans } from '../../../../utils/booking';
import * as PropTypes from 'prop-types';
import './BookingTotals.css';

/**
 * Functional React component for rendering a custom booking totals section.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the booking totals component.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the booking totals component.
 */

const BookingTotals = ({ id, ...rest }) => {
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const booking = useSelector((state) => state.booking);
  const bookingplans = booking?.bookingplans || [];
  const bookingTotals = booking?.bookingTotals || [];
  const sortedBookingTotals =
    bookingTotals && bookingTotals.length
      ? [...bookingTotals].sort((a, b) => a?.order - b?.order)
      : [];
  const includeMixedPlans = hasMixedPlans(bookingplans);

  return (
    <Box
      className='booking-totals'
      data-testid='booking-totals'
      id={id}
      {...rest}
    >
      <div className='booking-totals__header'>
        <span className='h4-semibold '>{t('yourBooking')}</span>
      </div>
      <div className='booking-totals__items'>
        {bookingplans &&
          bookingplans.map((tripPlan) => {
            const { depositamount: deposit = 0, priceperperson = 0 } = tripPlan;
            const depositamount =
              deposit > 0 ? deposit : includeMixedPlans ? priceperperson : 0;

            if (
              !isNaN(tripPlan.selectedQuantity) &&
              Number(tripPlan.selectedQuantity) > 0
            ) {
              return (
                <BookingTotalItem
                  key={tripPlan.tripplanid}
                  id={`${tripPlan.tripplanid}`}
                  data={{ ...tripPlan, depositamount, hideDeposit: false }}
                />
              );
            }
          })}
      </div>
      {sortedBookingTotals && sortedBookingTotals.length > 0 && (
        <span className='booking-totals__divider' />
      )}
      <div style={{ width: '100%' }}>
        {sortedBookingTotals?.map((item, index) => {
          if (item?.visible) {
            return (
              <BookingTotalItem key={index} id='great-total-item' data={item} />
            );
          }
        })}
      </div>
    </Box>
  );
};

BookingTotals.propTypes = {
  id: PropTypes.string.isRequired
};

export default BookingTotals;
